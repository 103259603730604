import styled from 'styled-components';
import { fontWeights, white } from '@salutejs/plasma-tokens-b2c';
import React, { ReactElement } from 'react';
import { applySpacing } from '@salutejs/plasma-core';
import { Icon, IconsSetKey } from 'components/Icon/Icon';

const Wrap = styled.span<{ color: string }>`
    color: ${white};
    background-color: ${({ color }) => color};
    border-radius: 0.85rem;
    height: 1.75rem;
    line-height: 1.7rem;
    text-align: center;
    padding: 0 0.75rem;
    display: flex;
`;

const StyledIcon = styled(Icon)`
    position: absolute;
    margin-left: -7px;
    margin-top: -5px;
    ${applySpacing}
`;

const StyledText = styled.span<{ icon?: string | null }>`
    font-size: 14px;
    font-weight: ${fontWeights.Semibold};
    padding-left: ${({ icon }) => (icon ? '26px' : '0')};
`;

interface BadgeProps {
    text?: ReactElement | string;
    color: string;
    icon?: IconsSetKey | null;
}

export const Badge: React.FC<BadgeProps> = ({ text, color, icon, ...props }) => {
    return (
        <Wrap color={color} {...props}>
            {icon && <StyledIcon mr={4} icon={icon} height={32} width={32} />}
            <StyledText icon={icon}>{text}</StyledText>
        </Wrap>
    );
};
