import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://9152d3e7b32c46c2a2d42ab134f56e61@visper-sentry.likemath.ru/3',
    attachStacktrace: true,
    environment: process.env.WORKFLOW_NAME,
    release: process.env.RELEASE,
    sampleRate: 1,
    tracesSampleRate: 0.2,
    integrations: [
        new BrowserTracing({
            beforeNavigate: (context) => ({
                ...context,
                name: window.location.pathname,
            }),
        }),
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
    // https://github.com/WICG/ResizeObserver/issues/38
    /* ResizeObserver loop completed with undelivered notifications- мягкий warning, который не ломает ничего.Он значит,
     что любые изменения, которые относятся к чему-то более глубокому в дереве, доставляются сразу, в то время как любые
    другие ставятся в очередь(то есть как раз они не доставлены) и доставляются в следующем кадре. */
    /* Open api access error - ошибка пикселя таргетинга вк(актуальна для юзеров, у которых включен блокировщик рекламы)
       Он блочит просто соответствующие запросы и таким образом мы не можем получить доступ до open api.
       Примеры блокировщиков: расширение хром adGuard
     */
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'Open api access error',
    ],
});
