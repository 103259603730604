import React, { SVGProps } from 'react';

export const VkSharing: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g>
            <path
                d="M21.6394 7.26004C21.7729 6.81039 21.6394 6.47998 21.0042 6.47998H18.9037C18.3696 6.47998 18.1234 6.7654 17.9899 7.08014C17.9899 7.08014 16.9217 9.71057 15.4085 11.4192C14.919 11.9138 14.6964 12.0712 14.4294 12.0712C14.2959 12.0712 14.1026 11.9138 14.1026 11.4642V7.26004C14.1026 6.72046 13.9476 6.47998 13.5025 6.47998H10.2017C9.86795 6.47998 9.66723 6.73041 9.66723 6.96776C9.66723 7.47927 10.4238 7.59723 10.5018 9.03611V12.1611C10.5018 12.8463 10.3793 12.9705 10.1123 12.9705C9.40019 12.9705 7.66806 10.3283 6.64075 7.30499C6.43942 6.71735 6.23749 6.47998 5.70067 6.47998H3.60018C3.00004 6.47998 2.88 6.7654 2.88 7.08014C2.88 7.64221 3.59212 10.43 6.19572 14.1171C7.93144 16.6351 10.377 18 12.6023 18C13.9374 18 14.1026 17.6968 14.1026 17.1746V15.2716C14.1026 14.6652 14.2291 14.5442 14.6519 14.5442C14.9634 14.5442 15.4975 14.7016 16.7437 15.9156C18.1679 17.3545 18.4027 18 19.2038 18H21.3043C21.9044 18 22.2045 17.6968 22.0314 17.0986C21.842 16.5023 21.162 15.6372 20.2597 14.6117C19.7701 14.0272 19.0358 13.3977 18.8132 13.0829C18.5017 12.6782 18.5907 12.4983 18.8132 12.1387C18.8132 12.1387 21.3724 8.49653 21.6394 7.26004Z"
                fill="currentColor"
            />
        </g>
    </svg>
);
