import React, { SVGProps } from 'react';

export const Unreal: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <path
            d="M15.3975 1.77777C15.3975 1.77777 10.1088 3.2879 5.35565 8.38175C0.602511 13.4756 0 17.1044 0 19.8091C1.04881 18.006 7.52022 8.0662 9.03766 12.7994V24.1141C9.03766 24.1141 8.9484 25.6468 6.62761 25.0382C7.31939 26.3455 10.8898 29.5686 17.3389 30.2222C18.8117 28.7346 20.7308 26.5934 20.7308 26.5934L23.9442 29.3432C23.9442 29.3432 29.7238 25.5566 32 20.057C29.8801 21.4545 27.3138 24.7001 25.9749 22.4237V8.65222C25.9749 8.65222 29.4114 3.44567 29.947 3.19774C28.5858 3.44567 23.788 5.04595 21.2664 8.33667C20.5523 7.5478 18.5662 7.52526 18.5662 7.52526C18.5662 7.52526 20.1283 8.83253 20.1506 10.0271C20.1729 11.2217 20.1506 21.184 20.1506 22.3335C19.0795 23.4379 17.9414 24.0239 17.205 24.0239C15.4868 24.0239 14.9958 23.4154 14.5272 22.8068V8.33667C14.5272 8.33667 13.6792 9.05793 13.0098 7.88589C12.3403 6.71385 12.318 4.43739 15.3975 1.77777Z"
            fill="currentColor"
        />
    </svg>
);
