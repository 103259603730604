import { IconsSetKey } from '../components/Icon/Icon';

import { Product } from './product';
import { Currency } from './currency';
import { TradeProductTemplate } from './tradeProductTemplate';

export interface SubscriptionVideoIcon {
    id: number;
    title: string;
    file: string;
    image: string | null;
}

export interface SubscriptionLabel {
    id: number;
    title: string;
    icon: IconsSetKey;
    hex_color: string;
}

export enum SubscriptionType {
    Minutes = 'MINUTES',
    Subscription = 'SUBSCRIPTION',
    Corporate = 'CORPORATE',
    Free = 'FREE',
}

export const ratesDesc: Record<SubscriptionType, string> = {
    [SubscriptionType.Minutes]: 'Поминутный',
    [SubscriptionType.Subscription]: 'Подписка',
    [SubscriptionType.Corporate]: 'Корпорат',
    [SubscriptionType.Free]: 'Бесплатный',
};

export interface Subscription {
    id: string;
    title: string;
    video_icon: SubscriptionVideoIcon | null;
    for_personal: boolean;
    description: string;
    price: string;
    price_usd: string;
    old_price: string;
    currency: Currency;
    product_variants: Product[];
    default_products: Product[];
    product_templates: TradeProductTemplate[];
    label: SubscriptionLabel | null;
    priority: number;
    is_default: boolean;
    subscription_type: SubscriptionType;
    parent: string | null;
    promoPrice: string | null;
    promoOptions: Product[] | null;
}

export interface SubscriptionCurrent {
    id: number;
    create_date: string;
    subscription?: Subscription;
    product_variant: Product | null;
    expiration_date: string | null;
    prolong_date: string | null;
    subscription_type: SubscriptionType;
    is_need_pay: boolean;
    will_be_prolongated: boolean;
}
