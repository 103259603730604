import React, { CSSProperties, forwardRef, ReactNode, useCallback, useMemo } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { mediaQuery, spacing } from '@salutejs/plasma-b2c';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';
import * as zIndex from 'const/zIndex';

import { Portal } from '../Portal/Portal';
import { Icon } from '../Icon/Icon';
import { DeviceType, useDeviceType } from '../../hooks/useDeviceType';

export enum ModalOverlayType {
    Blur,
    Shading,
    LightBlur,
}

const StyledContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: ${zIndex.MODAL};
    transition: all 0.3s linear;
    /* Убирает увеличение текста в Safari на iOS */
    text-size-adjust: none;
`;

const StyledOverlay = styled.div<{ overlayType: ModalOverlayType }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    ${({ overlayType }) =>
        overlayType === ModalOverlayType.Blur &&
        css`
            backdrop-filter: blur(60px);
            background: rgba(8, 8, 8, 0.8);
        `}

    ${({ overlayType }) =>
        overlayType === ModalOverlayType.LightBlur &&
        css`
            backdrop-filter: blur(60px);
            background: rgba(8, 8, 8, 0.4);
        `}

    ${({ overlayType }) =>
        overlayType === ModalOverlayType.Shading &&
        css`
            background: rgba(8, 8, 8, 0.3);
        `}
`;

export const ModalCloseButton = styled.div<{ $closePadding: boolean } & ModalFullScreenProps>`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 2;
    mix-blend-mode: difference;

    ${({ inlineCloseButton }) => {
        if (inlineCloseButton) {
            return css`
                padding-top: ${spacing['4']};
            `;
        }
    }}

    ${({ fullScreen }) => {
        if (fullScreen) {
            return mobileMediaQueries(css`
                top: ${spacing[24]};
                right: ${spacing[24]};
            `);
        }
    }};

    ${({ $closePadding }) =>
        css`
            ${mediaQuery('XL')(css`
                top: ${$closePadding ? spacing['12x'] : spacing['0x']};
                right: ${$closePadding ? spacing['12x'] : `-${spacing['12x']}`};
            `)}
            ${mediaQuery('XXL')(css`
                top: ${$closePadding ? spacing['12x'] : spacing['0x']};
                right: ${$closePadding ? spacing['12x'] : `-${spacing['12x']}`};
            `)}
        `}
`;

const StyledCloseIcon = styled(Icon)`
    display: block;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 1;
        transform: scale(1.08);
    }
`;

type ModalFullScreenProps = {
    fullScreen: boolean;
    inlineCloseButton?: boolean;
};

export const ModalContentContainer = styled.div<ModalFullScreenProps>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ fullScreen }) => {
        if (fullScreen) {
            return css`
                width: 100%;
                height: 100%;
            `;
        }

        return css`
            padding: ${spacing['8x']};
            padding-right: ${spacing['8x']};
            margin-right: -${spacing['4x']};
        `;
    }}

    ${mobileMediaQueries(css`
        width: 100%;
        height: 100%;
    `)}
`;

export const ModalContent = styled.div<{ width: string } & ModalFullScreenProps>`
    position: relative;
    width: 100%;
    height: 100%;

    ${({ width, fullScreen, inlineCloseButton }) => () => {
        if (fullScreen) {
            return css`
                width: 100%;
                height: 100%;
            `;
        }

        return css`
            padding-top: ${inlineCloseButton ? 0 : spacing['16x']};

            ${mediaQuery('XL')(css`
                align-items: flex-start;
                max-width: ${width};
                width: ${width};
                height: auto;
            `)}

            ${mediaQuery('XXL')(css`
                align-items: flex-start;
                max-width: ${width};
                width: ${width};
                height: auto;
            `)}
        `;
    }}
`;

export const GlobalMobilePopupStyle = createGlobalStyle<{ open: boolean }>`
    ${({ open }) =>
        open &&
        css`
            body {
                overflow: hidden;
                -webkit-user-select: none;
                user-select: none;
            }

            #__next {
                overflow: hidden;
                height: 100%;
                max-height: 100vh;
            }
        `}
`;

interface ModalProps {
    open: boolean;
    width?: string;
    overlayType?: ModalOverlayType;
    className?: string;
    withCloseButton?: boolean;
    canClose?: boolean;
    onClose?(): void;
    closePadding?: boolean;
    closeOnClickOutside?: boolean;
    children: ReactNode;
    e2e?: string;
    fullScreen?: boolean;
    inlineCloseButton?: boolean;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
    (
        {
            open,
            width = '359px',
            onClose,
            children,
            overlayType = ModalOverlayType.LightBlur,
            className,
            withCloseButton = true,
            canClose = true,
            closePadding = false,
            closeOnClickOutside = true,
            e2e,
            fullScreen = false,
            inlineCloseButton = false,
        },
        ref,
    ) => {
        const device = useDeviceType();
        const containerStyle = useMemo<CSSProperties>(
            () => ({ opacity: open ? 1 : 0, pointerEvents: open ? 'all' : 'none' }),
            [open],
        );

        const closeHandler = useCallback(() => canClose && onClose?.(), [canClose, onClose]);

        const overlayClickHandle = useCallback(() => closeOnClickOutside && closeHandler(), [
            closeHandler,
            closeOnClickOutside,
        ]);

        return open ? (
            <Portal>
                <StyledContainer
                    ref={ref}
                    style={containerStyle}
                    data-e2e={e2e || 'modal'}
                    data-e2e-open={open}
                    className={className}
                >
                    <StyledOverlay onClick={overlayClickHandle} overlayType={overlayType} />
                    <ModalContentContainer fullScreen={fullScreen}>
                        <ModalContent width={width} fullScreen={fullScreen} inlineCloseButton={inlineCloseButton}>
                            {open && (
                                <>
                                    {withCloseButton && canClose && (
                                        <ModalCloseButton
                                            $closePadding={closePadding}
                                            onClick={closeHandler}
                                            data-e2e="modalCloseButton"
                                            fullScreen={fullScreen}
                                            inlineCloseButton={inlineCloseButton}
                                        >
                                            <StyledCloseIcon icon="close" color="white" height={24} width={24} />
                                        </ModalCloseButton>
                                    )}
                                    {children}
                                </>
                            )}
                        </ModalContent>
                    </ModalContentContainer>
                    <GlobalMobilePopupStyle open={open && device === DeviceType.Mobile} />
                </StyledContainer>
            </Portal>
        ) : null;
    },
);
