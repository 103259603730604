import { useCallback, useLayoutEffect, useState } from 'react';
import { gridBreakpoints } from '@salutejs/plasma-b2c';

import { useWindowResize } from './useWindowResize';

export enum DeviceType {
    Desktop,
    Mobile,
}

export const useDeviceType = (): DeviceType | undefined => {
    const [deviceType, setDeviceType] = useState<DeviceType>();

    const resizeHandle = useCallback(
        () => setDeviceType(window.innerWidth < gridBreakpoints.XL ? DeviceType.Mobile : DeviceType.Desktop),
        [],
    );

    useWindowResize(resizeHandle);

    useLayoutEffect(() => {
        resizeHandle();
    }, [resizeHandle]);

    return deviceType;
};
