import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { getSubscriptions, MappedSubscription } from 'api/tariffs';
import { getCurrentSubscription } from 'api/subscription';
import { SubscriptionCurrent } from 'typings/subscription';
import { UserContext } from 'store/UserContext';

import { useLocaleUpdateEvent } from '../../hooks/useLocale';

import { Entity, Fetcher, useFetcher } from './useFetcher';

export { Status } from './useFetcher';

const initialState = {
    data: {
        tariffs: {} as Entity<MappedSubscription[]>,
        currentSubscription: {} as Entity<SubscriptionCurrent>,
    },
    fetchers: {
        fetchTariffs: (() => Promise.resolve(undefined)) as Fetcher<MappedSubscription[]>,
        fetchCurrentSubscription: (() => Promise.resolve(undefined)) as Fetcher<SubscriptionCurrent>,
    },
};

export const ApiDataContext = createContext(initialState);

export const ApiDataContextProvider: React.FC = ({ children }) => {
    const { user } = useContext(UserContext);
    const { data: tariffs, fetchData: fetchTariffs } = useFetcher<MappedSubscription[]>(getSubscriptions);
    const { data: currentSubscription, fetchData: fetchCurrentSubscription } = useFetcher<SubscriptionCurrent>(
        getCurrentSubscription,
    );

    const localeUpdateHandle = useCallback(() => {
        if (user?.id) {
            fetchTariffs();
            fetchCurrentSubscription();
        }
    }, [fetchCurrentSubscription, fetchTariffs, user?.id]);

    useLocaleUpdateEvent(localeUpdateHandle);

    useEffect(() => {
        if (user?.id) {
            fetchCurrentSubscription();
        }
    }, [user?.id, fetchCurrentSubscription]);

    return (
        <ApiDataContext.Provider
            value={{
                data: {
                    tariffs,
                    currentSubscription,
                },
                fetchers: {
                    fetchTariffs,
                    fetchCurrentSubscription,
                },
            }}
        >
            {children}
        </ApiDataContext.Provider>
    );
};
