import React, { SVGProps } from 'react';

export const TextMarker: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M8 7H11V17H10.5C9.67157 17 9 17.6716 9 18.5C9 19.3284 9.67157 20 10.5 20H12.5H14.5C15.3284 20 16 19.3284 16 18.5C16 17.6716 15.3284 17 14.5 17H14V7H17V7.5C17 8.32843 17.6716 9 18.5 9C19.3284 9 20 8.32843 20 7.5V5.5C20 4.67157 19.3284 4 18.5 4H12.5H6.5C5.67157 4 5 4.67157 5 5.5V7.5C5 8.32843 5.67157 9 6.5 9C7.32843 9 8 8.32843 8 7.5V7Z"
            fill="currentColor"
        />
    </svg>
);
