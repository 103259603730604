import React, { SVGProps } from 'react';

export const LeftArrow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.726 6.31225C14.3461 5.91132 13.7132 5.89421 13.3123 6.27404L8.31225 11.0109C8.11954 11.1935 8.00733 11.4452 8.00034 11.7105C7.99336 11.9759 8.09216 12.2331 8.275 12.4256L13.275 17.6887C13.6554 18.0892 14.2883 18.1054 14.6887 17.725C15.0892 17.3446 15.1054 16.7117 14.725 16.3112L10.4147 11.7741L14.6877 7.72595C15.0887 7.34612 15.1058 6.71319 14.726 6.31225Z"
            fill="currentColor"
        />
    </svg>
);
