import React, { SVGProps } from 'react';

export const TemplateContentInWindow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 68 52" {...props}>
        <path
            d="M18.914 13C16.2769 13 14.1391 15.1378 14.1391 17.7748C14.1391 20.4119 16.2769 22.5497 18.914 22.5497H19.0841C21.7212 22.5497 23.859 20.4119 23.859 17.7748C23.859 15.1378 21.7212 13 19.0841 13H18.914ZM13.7276 24.7328C12.5076 24.7328 11.4091 25.4717 10.949 26.6016L8.22309 33.2972C7.51145 35.0451 8.57133 37.0082 10.4232 37.3721L18.4213 38.9437C18.8033 39.0188 19.1962 39.0188 19.5782 38.9437L27.5763 37.3721C29.4281 37.0082 30.488 35.0451 29.7764 33.2972L27.0504 26.6016C26.5904 25.4717 25.4919 24.7328 24.2719 24.7328H13.7276Z"
            fill="currentColor"
        />
        <path
            d="M39 8C36.7909 8 35 9.79086 35 12V20C35 22.2091 36.7909 24 39 24H56C58.2091 24 60 22.2091 60 20V12C60 9.79086 58.2091 8 56 8H39ZM44.2217 13.2632L40.7042 16.8313L39.5454 15.6559L43.3526 11.7939C43.8326 11.307 44.6108 11.307 45.0907 11.7939L48.3194 15.069L49.9092 13.4563C50.3892 12.9694 51.1674 12.9694 51.6473 13.4563L55.4545 17.3182L54.2958 18.4937L50.7783 14.9256L49.4781 16.2444L52.1754 18.9805L51.0166 20.156L44.2217 13.2632Z"
            fill="currentColor"
        />
    </svg>
);
