import React, { SVGProps } from 'react';

export const Maya: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <path
            d="M22.3064 3.46973H31.1114L19.3435 24.9409C19.3435 24.9409 17.3609 19.2527 16.5088 17.2227"
            fill="currentColor"
        />
        <path d="M24.478 30.0186H31.111V3.46973C31.1054 17.3438 25.202 30.0186 24.478 30.0186Z" fill="currentColor" />
        <path
            d="M19.3433 24.9409H13.8687L1.77783 3.46974C3.922 3.20018 8.20477 2.8371 10.3267 2.66656L19.3433 24.9409Z"
            fill="currentColor"
        />
        <path
            d="M8.78954 16.4636C6.97396 23.5491 1.77227 30.2276 1.77784 30.2221C7.80378 30.2221 9.10142 29.3584 9.10142 28.9018C9.10142 28.6487 9.10142 20.7435 9.10142 17.0027C8.9956 16.8266 8.89536 16.6451 8.78954 16.4636Z"
            fill="currentColor"
        />
        <path
            d="M1.77783 3.46973V30.2221C5.07484 24.8419 6.99623 21.3211 8.69486 16.304C4.50677 8.98744 1.77783 3.46973 1.77783 3.46973Z"
            fill="currentColor"
        />
        <path d="M24.0215 28.9679L24.4782 30.0186V16.0786L24.0215 16.8322" fill="currentColor" />
        <path
            d="M24.4558 16.106V29.9745L24.4725 30.0186C24.9459 30.0186 31.1055 15.0113 31.1055 3.46973C31.1055 3.46973 27.3463 11.2154 24.4558 16.106Z"
            fill="currentColor"
        />
    </svg>
);
