import React, { useContext } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { BodyM, H2, spacing } from '@salutejs/plasma-b2c';
import { Icon } from 'components/Icon/Icon';
import { Tariffs } from 'components/Tariffs/Tariffs';
import { Portal } from 'components/Portal/Portal';
import { LayoutContext } from 'store/LayoutContext';
import { useTranslation } from 'next-i18next';
import * as zIndex from 'const/zIndex';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';

export const GlobalMobilePopupStyle = createGlobalStyle<{ open: boolean }>`
    ${({ open }) =>
        open &&
        css`
            body {
                overflow: hidden;
                -webkit-user-select: none;
                user-select: none;
            }

            #__next {
                overflow: hidden;
                height: 100%;
                max-height: 100vh;
            }
        `}
`;

const TariffsModalContainer = styled.div`
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    transition: all 0.3s linear;
    z-index: ${zIndex.MODAL};
    height: 100%;
    width: 100%;
    backdrop-filter: blur(60px);
    background: rgba(8, 8, 8, 0.4);
    /* Убирает увеличение текста в Safari на iOS */
    text-size-adjust: none;
`;

const StyledWrap = styled.div`
    position: relative;
    padding: calc(4 * ${spacing['16x']}) 0 calc(4 * ${spacing['16x']}) calc(2 * ${spacing['16x']});
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: auto;

    ${mobileMediaQueries(css`
        padding: calc(2 * ${spacing['12x']}) ${spacing['8x']};
        padding-right: ${spacing['10x']};
    `)}
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`;

const StyledHeader = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    width: fit-content;
    margin-bottom: calc(2 * ${spacing['16x']});
    align-items: center;

    ${mobileMediaQueries(css`
        margin-bottom: ${spacing['12x']};
        align-self: flex-start;
        position: unset;
    `)}
`;

const StyledSubHeader = styled(BodyM)`
    margin-top: ${spacing['8x']};
    ${mobileMediaQueries(css`
        display: none;
    `)}
`;

const StyledClose = styled.div`
    mix-blend-mode: difference;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    cursor: pointer;
    z-index: 2;

    ${mobileMediaQueries(css`
        top: ${spacing['12x']};
        right: ${spacing['12x']};
    `)}

    &:hover > span {
        opacity: 1;
        transform: scale(1.08);

        ${mobileMediaQueries(css`
            opacity: 1;
            transform: scale(2);
        `)}
    }
`;

const StyledCloseIcon = styled(Icon)`
    opacity: 0.28;
    transition: all 0.2s ease-in-out;

    ${mobileMediaQueries(css`
        opacity: 1;
        transform: scale(2);
    `)}
`;

const analyticsFrom = 'TariffsModal';

export const TariffsModal: React.FC = () => {
    const { t } = useTranslation('payment');

    const {
        tariffsModal: { isOpen, close, options },
    } = useContext(LayoutContext);

    return (
        <>
            <GlobalMobilePopupStyle open={isOpen} />
            {isOpen && (
                <Portal>
                    <TariffsModalContainer data-e2e="ratesModal" data-e2e-compare-mode={options.isCompare}>
                        <StyledWrap data-e2e="ratesModalScrollContainer">
                            <StyledContent>
                                <StyledHeader>
                                    <StyledClose onClick={close}>
                                        <StyledCloseIcon icon="close" color="white" height={20} width={20} />
                                    </StyledClose>

                                    <H2 data-e2e="ratesModalHeading">{t('Все тарифы')}</H2>
                                    <StyledSubHeader data-e2e="ratesModalSubHeading">
                                        {t('Больше возможностей для творчества. Попробуйте!')}
                                    </StyledSubHeader>
                                </StyledHeader>

                                <Tariffs
                                    data-e2e="ratesModalTariffsList"
                                    analyticsFrom={analyticsFrom}
                                    isCompareMode={options.isCompare}
                                    suggestion={options.suggestion}
                                    isModalMode
                                />
                            </StyledContent>
                        </StyledWrap>
                    </TariffsModalContainer>
                </Portal>
            )}
        </>
    );
};
