import React, { SVGProps } from 'react';

export const TextAccent: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            d="M7.95891 1.23829C8.28035 0.488998 9.04093 0 9.88493 0H10.9569C11.7624 0 12.263 0.840298 11.8547 1.50693L9.09416 6.01387C8.71993 6.62484 8.03686 7 7.29864 7H7.04257C6.30119 7 5.7972 6.27733 6.07956 5.61914L7.95891 1.23829Z"
            fill="currentColor"
        />
    </svg>
);
