import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { useModal } from 'hooks/useModal';
import { WebpushContext } from 'store/WebpushContext';

import { UserContext } from '../../store/UserContext';
import { Modal } from '../Modal/Modal';
import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';

export const WebpushApproveModal: React.FC = () => {
    const { user } = useContext(UserContext);
    const { subscribeToWebpush, unsubscribeFromWebpush, isWebpushSupported } = useContext(WebpushContext);

    const { isModalOpen, closeModal } = useModal(true);
    const { t } = useTranslation('webpush');

    const handleSubscribe = useCallback(() => {
        subscribeToWebpush();
        closeModal();
    }, [closeModal, subscribeToWebpush]);

    const handleUnsubscribe = useCallback(() => {
        unsubscribeFromWebpush();
        closeModal();
    }, [closeModal, unsubscribeFromWebpush]);

    if (!user || !isWebpushSupported || !isModalOpen) {
        return null;
    }

    const shouldBeOpened =
        user?.webpush_on === null || Boolean(user?.webpush_on && Notification?.permission === 'default');

    return (
        <Modal open={shouldBeOpened} width="604px" onClose={closeModal}>
            <ModalHeader>
                {t(
                    'Хотите получать уведомления о готовности вашего видео? Подпишитесь и не забудьте разрешить отправку push в настройках браузера',
                )}
            </ModalHeader>

            <ModalBody />

            <ModalFooter>
                <ModalBasicButton pin="square-square" size="m" view="primary" onClick={handleSubscribe}>
                    {t('Поехали!')}
                </ModalBasicButton>
                <ModalBasicButton pin="square-square" size="m" onClick={handleUnsubscribe} view="secondary">
                    {t('Нет, спасибо')}
                </ModalBasicButton>
            </ModalFooter>
        </Modal>
    );
};
