import React, { Children, useCallback, useRef, useState } from 'react';
import { Carousel, CarouselProps } from '@salutejs/plasma-b2c';

type Props = Omit<CarouselProps, 'axis' | 'onIndexChange' | 'index' | 'detectActive' | 'detectThreshold'>;

export const CarouselWithMouseScroll: React.FC<Props> = ({ children, ...props }) => {
    const [scrollIndex, setScrollIndex] = useState(0);
    const carouselRef = useRef<HTMLDivElement | null>(null);

    const setCarouselRef = useCallback(
        (node) => {
            let isDragging = false;

            const handleMouseDown = (): void => {
                isDragging = true;
            };

            const handleMouseUp = (): void => {
                isDragging = false;
            };

            const handleMouseMove = (e: MouseEvent): void => {
                if (isDragging && Math.abs(e.movementX) > 10) {
                    setScrollIndex(e.movementX > 0 ? 0 : Children.count(children) - 1);
                }
            };

            if (carouselRef.current) {
                carouselRef.current.removeEventListener('mousedown', handleMouseDown);
                carouselRef.current.removeEventListener('mouseup', handleMouseUp);
                document.body.removeEventListener('mousemove', handleMouseMove);
            }

            carouselRef.current = node;

            if (!carouselRef.current) {
                return;
            }

            carouselRef.current.addEventListener('mousedown', handleMouseDown);
            carouselRef.current.addEventListener('mouseup', handleMouseUp);
            document.body.addEventListener('mousemove', handleMouseMove);
        },
        [children],
    );

    return (
        <Carousel
            ref={setCarouselRef}
            index={scrollIndex}
            detectActive
            detectThreshold={0}
            onIndexChange={setScrollIndex}
            {...props}
        >
            {children}
        </Carousel>
    );
};
