import React, { SVGProps } from 'react';

export const Telegram: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 34 28" {...props}>
        <path
            d="M12.7882 26.3043C11.7752 26.3043 11.9474 25.9288 11.598 24.9817L8.61938 15.3568L31.5479 2.00098"
            fill="#C8DAEA"
        />
        <path
            d="M12.7883 26.304C13.57 26.304 13.9152 25.953 14.3516 25.5365L18.5205 21.5564L13.3204 18.4775"
            fill="#A9C9DD"
        />
        <path
            d="M13.3197 18.4782L25.92 27.6186C27.3579 28.3976 28.3955 27.9942 28.7537 26.308L33.8827 2.57693C34.4077 0.509864 33.0802 -0.42799 31.7044 0.185222L1.58736 11.5876C-0.468383 12.3973 -0.456137 13.5234 1.21269 14.0251L8.94141 16.3938L26.8342 5.31016C27.6789 4.80721 28.4543 5.07736 27.8181 5.63199"
            fill="url(#paint0_linear)"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="22.44"
                y1="12.236"
                x2="27.1715"
                y2="23.2155"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EFF7FC" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
        </defs>
    </svg>
);
