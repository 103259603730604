import React, { SVGProps } from 'react';

export const File: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 19 24" {...props}>
        <path
            d="M15 24H4C1.79086 24 0 22.2091 0 20V4C0 1.79086 1.79086 0 4 0H10.125V7.50012V8.50013L11.125 8.50012L19 8.5V20C19 22.2091 17.2091 24 15 24ZM18.0483 6.50001L12.125 0.276267V6.5001L18.0483 6.50001Z"
            fill="currentColor"
        />
    </svg>
);
