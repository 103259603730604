import { UsersApi, UserProfileRequest, UserProfile } from '@sberdevices/vc-contracts';

import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';
import { basePath, configuration } from '../configs/base';

export const axiosInstance = new UsersApi(configuration, basePath, baseAxiosInstance);

export function getUser(): Promise<UserProfile> {
    return axiosInstance.usersCurrentRetrieve().then((response) => response.data as any);
}

export function putUser(userPutData: UserProfileRequest): Promise<UserProfile> {
    return baseAxiosInstance.put(apiConfig.userProfile, userPutData).then((response) => response.data);
}

export async function setUsePayments(idtag?: string): Promise<void> {
    const params: { idtag?: string } = {};

    if (idtag) {
        params.idtag = idtag;
    }

    await baseAxiosInstance.get(apiConfig.userUsePayments, {
        params,
    });
}

export function approveTerms(): Promise<void> {
    return baseAxiosInstance.post(apiConfig.approveTerms);
}

export function getFeatures(): Promise<string[]> {
    return baseAxiosInstance.get<{ names: string[] }>(apiConfig.userFeatures).then(({ data }) => data.names);
}
