import React, { SVGProps } from 'react';

export const Gesture: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            d="M11.7931 3.05965C11.2407 3.42615 11.2407 5.32845 11.3098 6.13563C11.3788 7.03006 11.7931 8.6226 10.8264 10.0624C9.93062 11.3968 8.13362 12.4185 6.4765 11.8295C5.1508 11.3583 1.82736 8.40445 0.331343 6.98643C0.331343 6.98643 -0.0371 6.39741 0.331343 6.07018C0.847166 5.61205 1.50514 6.07018 1.50514 6.07018C1.50514 6.07018 2.96901 7.33004 3.86662 8.1154C3.92927 8.17022 4.03331 8.10107 4.07376 8.04995C4.1142 7.99883 4.12895 7.90966 4.07376 7.85361C3.1071 6.87191 0.331343 4.30312 0.331343 4.30312C0.331343 4.30312 -0.311186 3.51776 0.19325 3.05966C0.841888 2.47061 1.50514 3.25597 1.50514 3.25597C1.50514 3.25597 3.17615 4.97395 4.61224 6.33197C4.81938 6.52785 5.02129 6.24199 4.90232 6.08654C4.36383 5.38299 1.31189 2.29064 1.31189 2.29064C1.31189 2.29064 0.41428 1.37439 1.10475 0.785362C1.79521 0.196331 2.54084 1.22713 2.54084 1.22713C3.48448 2.29609 5.30279 4.47328 5.52374 4.63035C5.74468 4.78743 5.87809 4.56491 5.85508 4.43401L3.3694 1.35802C3.3694 1.35802 2.78859 0.635358 3.3694 0.179979C4.03718 -0.343596 4.61224 0.441765 4.61224 0.441765C5.53286 1.66344 7.52601 4.2115 8.13362 4.63035C8.74123 5.04921 9.03123 4.89214 9.37645 4.43401C9.5836 3.97589 9.44551 3.15126 10.2741 2.47061C11.3098 1.61981 12.4836 2.60152 11.7931 3.05965Z"
            fill="currentColor"
        />
    </svg>
);
