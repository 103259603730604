import React from 'react';
import styled from 'styled-components';
import { white, secondary, fontWeights } from '@salutejs/plasma-tokens-b2c';
import { Body2, BodyM } from '@salutejs/plasma-b2c';
import { spacing } from '@salutejs/plasma-core';
import { useTranslation } from 'next-i18next';
import { useLanguage } from 'hooks/useLocale';

import { Product } from '../../typings/product';
import { Currency, CurrencyChar } from '../../typings/currency';
import { PriceRule } from '../../typings/tradeProductTemplate';

const StyledPrice = styled.div`
    overflow-wrap: break-word;
    color: ${white};
    font-weight: ${fontWeights.Bold};
    font-size: 3rem;
    line-height: 3.25rem;
`;

const StyledCurrency = styled.span`
    margin-left: ${spacing['4x']};
    font-size: 2rem;
    line-height: 2rem;
`;

const StyledText = styled(Body2)`
    margin-bottom: ${spacing['12x']};
    color: ${white};
`;

const StyledOldPrice = styled.span`
    margin-top: auto;
    color: ${secondary};
    text-decoration: line-through;
    font-weight: ${fontWeights.Semibold};
`;

const StyledIndividualPrice = styled(StyledText)`
    margin-top: auto;
`;

const StyledFromText = styled(BodyM)<{ $hidden: boolean }>`
    visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
`;

export interface TariffCardPriceBlockProps {
    oldPrice: string | null;
    price?: string;
    usdPrice?: string;
    options: Product[];
    isPersonal: boolean;
    currency: Currency;
    pricePerMinute: { price: number; usdPrice: number } | null;
    templatePriceRule: PriceRule | null;
    templatePriceRuleCount: number | null;
}

interface TemplateRulePrice {
    price: number;
    priceUsd: string;
}

const getTemplateRulePrice = (price: PriceRule | null, count: number | null): TemplateRulePrice | null => {
    if (!price || !count) {
        return null;
    }

    return {
        price: Number(price.price) * count,
        priceUsd: (Number(price.price_usd) * count).toFixed(2),
    };
};

export const TariffCardPriceBlock: React.FC<TariffCardPriceBlockProps> = ({
    currency,
    price,
    usdPrice,
    oldPrice,
    options,
    isPersonal,
    pricePerMinute,
    templatePriceRule,
    templatePriceRuleCount,
}) => {
    const { t } = useTranslation('payment');
    const language = useLanguage();
    const templateRulePrice = getTemplateRulePrice(templatePriceRule, templatePriceRuleCount);
    const durationText = pricePerMinute ? t('в минуту') : t('за месяц');

    if (isPersonal) {
        return <StyledIndividualPrice>{t('Индивидуальная цена')}</StyledIndividualPrice>;
    }

    if (options.length === 0 && price === '0') {
        return <StyledText style={{ marginTop: 'auto' }}>{t('Бесплатно')}</StyledText>;
    }

    return (
        <>
            <StyledOldPrice>
                {!templatePriceRule &&
                    oldPrice &&
                    `${oldPrice} ${
                        CurrencyChar[options[0]?.currency] !== undefined ? CurrencyChar[options[0]?.currency] : ''
                    }`}
            </StyledOldPrice>
            <StyledPrice data-e2e="ratePrice">
                <StyledFromText $hidden={!!templateRulePrice} bold>
                    {pricePerMinute && t('от')}
                </StyledFromText>
                {templateRulePrice?.price ?? pricePerMinute?.price ?? price}
                <StyledCurrency>{CurrencyChar[options[0]?.currency ?? currency]}</StyledCurrency>
            </StyledPrice>

            <StyledText data-e2e="ratePriceText">
                {language === 'en' ? `≈ $${templateRulePrice?.priceUsd ?? pricePerMinute?.usdPrice ?? usdPrice} ` : ''}
                {!templateRulePrice && durationText}
            </StyledText>
        </>
    );
};
