import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { white, secondary } from '@salutejs/plasma-tokens-b2c';
import { Radiobox } from '@salutejs/plasma-b2c';
import { spacing } from '@salutejs/plasma-core';
import { useLanguage } from 'hooks/useLocale';

import { Product } from '../../typings/product';
import { CurrencyChar } from '../../typings/currency';
import { mobileMediaQueries } from '../../utils/mobileMediaQueries';

const OptionsContainer = styled.div`
    color: ${white};
    margin-bottom: ${spacing['12x']};
`;

const StyledUsdPrice = styled.span`
    color: ${secondary};
    margin-right: 14px;
    display: block;

    ${mobileMediaQueries(css`
        display: inline;
    `)}
`;

const StyledLabel = styled.span`
    white-space: break-spaces;
    hyphens: none;
`;

export interface TariffCardOptionsProps {
    options: Product[];
    currentOptionId: string | null;
    onChange(value: string): void;
}

export const TariffCardOptions: React.FC<TariffCardOptionsProps> = ({ options, currentOptionId, onChange }) => {
    const language = useLanguage();
    const handleOptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [
        onChange,
    ]);

    return (
        <OptionsContainer>
            {options.map((o, index) => (
                <Radiobox
                    onChange={handleOptionChange}
                    data-e2e={`price${index}`}
                    key={o.id}
                    value={o.id}
                    name="options"
                    label={
                        <StyledLabel>
                            {`${o.title},\xa0${o.price}\xa0${CurrencyChar[o.currency]}\n`}
                            {language === 'en' && <StyledUsdPrice>{`≈\xa0$${o.price_usd}`}</StyledUsdPrice>}
                        </StyledLabel>
                    }
                    checked={o.id === currentOptionId}
                />
            ))}
        </OptionsContainer>
    );
};
