import React, { SVGProps } from 'react';

export const Mail: React.FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.27539 6.53851C4.33356 6.55524 4.39111 6.57751 4.4472 6.60559L13.9778 11.3709L23.5181 6.12378C23.5221 6.12158 23.5261 6.11938 23.5301 6.11725C22.8567 4.85724 21.5285 4 20 4H8C6.30664 4 4.85901 5.05225 4.27539 6.53851ZM24 8.1413L14.4819 13.3762C14.194 13.5345 13.8467 13.5414 13.5528 13.3944L4 8.61804V16C4 18.2092 5.79083 20 8 20H20C22.2092 20 24 18.2092 24 16V8.1413Z"
                fill="currentColor"
            />
        </svg>
    );
};
