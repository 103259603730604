import React, { SVGProps } from 'react';

export const Unity: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <path
            d="M24.5402 24.57L19.4328 15.9869L24.5402 7.40374L27.0024 15.9869L24.5402 24.57ZM13.173 23.7324L6.75581 17.3737H16.9707L22.0781 25.9568L13.173 23.7324ZM13.173 8.21504L22.0521 5.99068L16.9447 14.5738H6.72981L13.173 8.21504ZM26.2166 1.77777L14.6134 4.78716L12.8847 7.74402L9.40139 7.71776L0.888916 15.9869L9.40139 24.256L12.8847 24.2297L14.6134 27.1866L26.2166 30.2222L29.3334 18.9437L27.5784 16.0131L29.3334 13.0822L26.2166 1.77777Z"
            fill="currentColor"
        />
    </svg>
);
