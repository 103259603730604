import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { buttonSecondary } from '@salutejs/plasma-tokens-b2c';
import { ApiDataContext } from 'store/ApiDataContext/ApiDataContext';
import { useTranslation } from 'next-i18next';
import { spacing } from '@salutejs/plasma-core';
import { useToast } from 'hooks/useToast';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';
import { Textarea } from '../Textarea/Textarea';
import { cancelCurrentSubscription, switchToFreeRate } from '../../api/subscription';
import { getUser } from '../../api/user';
import { UserContext } from '../../store/UserContext';
import { SubscriptionType } from '../../typings/subscription';
import { mobileMediaQueries } from '../../utils/mobileMediaQueries';

const StyledTextarea = styled(Textarea)`
    height: 156px;
    margin-bottom: 24px;
    background-color: ${buttonSecondary};
    border-radius: 12px;
`;

const StyledContainer = styled.div`
    width: calc(100% + ${spacing['24']});

    ${mobileMediaQueries(css`
        width: 100%;
    `)}
`;

const StyledModalHeader = styled(ModalHeader)`
    padding-right: ${spacing['24']};

    ${mobileMediaQueries(css`
        padding-right: 0;
    `)}
`;

interface FeedbackBlockProps {
    onSuccess(): void;
}

export const FeedbackBlock: React.FC<FeedbackBlockProps> = ({ onSuccess }) => {
    const { t } = useTranslation('payment');
    const { setUser } = useContext(UserContext);
    const { showToast } = useToast();
    const {
        data: { currentSubscription },
        fetchers: { fetchCurrentSubscription },
    } = useContext(ApiDataContext);
    const [text, setText] = useState<string>('');

    const submit = useCallback(async () => {
        try {
            if (currentSubscription.data?.subscription_type === SubscriptionType.Subscription) {
                await cancelCurrentSubscription();
            } else {
                await switchToFreeRate();
            }
        } catch (error) {
            showToast.error(t('Ошибка перехода на бесплатный тариф'));

            return;
        }

        try {
            const [user] = await Promise.all([getUser(), fetchCurrentSubscription()]);
            setUser(user);
            onSuccess();
        } catch (e) {
            showToast.error(t('Ошибка обновления данных пользователя'));
        }
    }, [currentSubscription.data?.subscription_type, showToast, t, fetchCurrentSubscription, setUser, onSuccess]);

    return (
        <StyledContainer>
            <StyledModalHeader>
                {t(
                    'Вы уверены, что хотите бесплатный тариф? На всех видео появится логотип. В месяц будет доступно всего 2 минуты видео',
                )}
            </StyledModalHeader>
            <ModalBody>
                <StyledTextarea
                    data-e2e="freeRateTextArea"
                    placeholder={t('Расскажите, почему вы решили перейти на бесплатный тариф')}
                    value={text}
                    onChange={setText}
                />
            </ModalBody>
            <ModalFooter>
                <ModalBasicButton pin="square-square" size="m" view="primary" onClick={submit}>
                    {t('Отправить')}
                </ModalBasicButton>
            </ModalFooter>
        </StyledContainer>
    );
};
