import React, { FC, SVGProps } from 'react';

export const Watermark: FC<SVGProps<SVGSVGElement>> = (props) => {
    const paint0_linear = Math.random();
    const paint1_linear = Math.random();
    const paint2_linear = Math.random();

    return (
        <svg viewBox="0 0 112 16" {...props}>
            <g clipPath="url(#clip0)">
                <path
                    d="M8.59487 14.2453C9.74524 13.5462 10.1082 12.053 9.40524 10.9097L3.16524 2.15024C2.67339 1.35024 1.62228 1.09757 0.817832 1.58671C0.0126448 2.07584 -0.240689 3.12114 0.251163 3.92188L5.2408 13.4401C5.94302 14.5834 7.4445 14.9436 8.59487 14.2453Z"
                    fill={`url(#${paint0_linear})`}
                />
                <path
                    d="M6.12151 14.285L6.12374 14.2865C7.29707 14.9458 8.78523 14.5347 9.44819 13.3679L14.2949 5.31408C15.0245 4.03083 14.5697 2.40284 13.2793 1.67724C11.9963 0.956062 10.3682 1.40542 9.643 2.68129L5.19855 10.9782C4.53485 12.145 4.94818 13.6257 6.12151 14.285Z"
                    fill={`url(#${paint1_linear})`}
                />
                <path
                    d="M11.9586 1.33625H7.31637C5.56452 1.33625 4.14453 2.74841 4.14453 4.49058C4.14453 6.23276 5.56452 7.64491 7.31637 7.64491C7.53712 7.64491 7.75268 7.62208 7.96083 7.57935L12.5519 6.60919L12.5512 6.60698C13.7475 6.3381 14.6423 5.27659 14.6423 4.00587C14.6423 2.53184 13.4408 1.33625 11.9586 1.33625Z"
                    fill={`url(#${paint2_linear})`}
                />
            </g>
            <path
                d="M20 1.33333H22.401L25.4146 10.4667L28.3289 1.33333H30.5974L26.6399 13H23.9906L20 1.33333Z"
                fill="currentColor"
            />
            <path
                d="M32.0347 4H34.1542V13H32.0347V4ZM31.836 1.1C31.836 0.788889 31.9243 0.527779 32.101 0.316668C32.2886 0.105557 32.6143 0 33.0779 0C33.5526 0 33.8837 0.105557 34.0714 0.316668C34.2591 0.516668 34.3529 0.777778 34.3529 1.1C34.3529 1.41111 34.2591 1.67778 34.0714 1.9C33.8837 2.12222 33.5526 2.23333 33.0779 2.23333C32.6143 2.23333 32.2886 2.12222 32.101 1.9C31.9243 1.67778 31.836 1.41111 31.836 1.1Z"
                fill="currentColor"
            />
            <path
                d="M42.7389 6.35C42.3857 6.09444 42.0049 5.88889 41.5964 5.73333C41.199 5.56667 40.7078 5.48333 40.1227 5.48333C39.5266 5.48333 39.1127 5.56111 38.8808 5.71667C38.6601 5.87222 38.5497 6.11111 38.5497 6.43333C38.5497 6.71111 38.6656 6.92222 38.8974 7.06667C39.1292 7.2 39.4162 7.31667 39.7584 7.41667C40.1117 7.51667 40.487 7.61667 40.8844 7.71667C41.2928 7.80555 41.6682 7.95 42.0104 8.15C42.3636 8.33889 42.6562 8.6 42.888 8.93333C43.1198 9.25556 43.2357 9.7 43.2357 10.2667C43.2357 11.2222 42.9487 11.95 42.3747 12.45C41.8117 12.95 40.8568 13.2 39.5101 13.2C38.936 13.2 38.3841 13.15 37.8542 13.05C37.3243 12.95 36.8276 12.7944 36.364 12.5833V10.4167C36.7172 10.7278 37.1698 10.9889 37.7218 11.2C38.2737 11.4111 38.8588 11.5167 39.4769 11.5167C40.073 11.5167 40.487 11.4222 40.7188 11.2333C40.9617 11.0444 41.0831 10.7611 41.0831 10.3833C41.0831 10.1167 40.9672 9.91111 40.7354 9.76667C40.5036 9.62222 40.2165 9.5 39.8743 9.4C39.5321 9.3 39.1568 9.2 38.7484 9.1C38.3399 8.98889 37.9646 8.83889 37.6224 8.65C37.2802 8.45 36.9932 8.18889 36.7614 7.86667C36.5295 7.53333 36.4136 7.08889 36.4136 6.53333C36.4136 6.08889 36.4744 5.7 36.5958 5.36667C36.7172 5.02222 36.9214 4.73333 37.2084 4.5C37.4955 4.26667 37.8653 4.09444 38.3179 3.98333C38.7815 3.86111 39.3555 3.8 40.0399 3.8C40.5698 3.8 41.061 3.85 41.5136 3.95C41.9773 4.03889 42.3857 4.14445 42.7389 4.26667V6.35Z"
                fill="currentColor"
            />
            <path
                d="M45.1094 4H47.0964L47.1792 5.48333C47.4331 4.96111 47.8084 4.55 48.3052 4.25C48.8019 3.95 49.4642 3.8 50.2922 3.8C51.5396 3.8 52.4503 4.16111 53.0243 4.88333C53.6094 5.59445 53.9019 6.75556 53.9019 8.36667C53.9019 10.0444 53.5928 11.2722 52.9746 12.05C52.3564 12.8167 51.385 13.2 50.0603 13.2C49.6629 13.2 49.3097 13.1611 49.0006 13.0833C48.7026 13.0056 48.4376 12.9056 48.2058 12.7833C47.985 12.65 47.7918 12.5056 47.6263 12.35C47.4717 12.1833 47.3392 12.0167 47.2289 11.85V16H45.1094V4ZM47.2289 9.08333C47.2289 9.81667 47.411 10.3944 47.7753 10.8167C48.1506 11.2278 48.7191 11.4333 49.4808 11.4333C50.3198 11.4333 50.9048 11.2 51.236 10.7333C51.5672 10.2667 51.7327 9.49445 51.7327 8.41667C51.7327 7.38333 51.5451 6.65 51.1698 6.21667C50.8055 5.78333 50.2425 5.56667 49.4808 5.56667C48.697 5.56667 48.123 5.78889 47.7587 6.23333C47.4055 6.67778 47.2289 7.24444 47.2289 7.93333V9.08333Z"
                fill="currentColor"
            />
            <path
                d="M57.3625 9.1C57.3845 9.91111 57.6164 10.5111 58.0579 10.9C58.5105 11.2778 59.1839 11.4667 60.078 11.4667C60.6962 11.4667 61.2702 11.3722 61.8001 11.1833C62.341 10.9833 62.7771 10.75 63.1082 10.4833V12.4C62.8875 12.5778 62.49 12.7556 61.916 12.9333C61.353 13.1111 60.6631 13.2 59.8462 13.2C58.2345 13.2 57.0589 12.8167 56.3193 12.05C55.5907 11.2722 55.2264 10.0444 55.2264 8.36667C55.2264 6.85556 55.6018 5.71667 56.3524 4.95C57.1141 4.18333 58.2014 3.8 59.6144 3.8C60.3761 3.8 61.0219 3.90556 61.5517 4.11667C62.0816 4.31667 62.5066 4.60556 62.8267 4.98333C63.1469 5.36111 63.3787 5.81667 63.5222 6.35C63.6657 6.87222 63.7374 7.45556 63.7374 8.1V9.1H57.3625ZM59.5813 5.48333C58.1352 5.48333 57.3956 6.22778 57.3625 7.71667H61.6345C61.6345 6.98333 61.4745 6.42778 61.1543 6.05C60.8452 5.67222 60.3209 5.48333 59.5813 5.48333Z"
                fill="currentColor"
            />
            <path
                d="M65.5458 4H67.4831L67.5825 5.33333C67.8033 4.83333 68.1289 4.45556 68.5594 4.2C68.99 3.93333 69.4757 3.8 70.0166 3.8C70.1932 3.8 70.3533 3.81111 70.4968 3.83333C70.6403 3.84444 70.7783 3.86111 70.9107 3.88333V6.1C70.7451 5.96667 70.5464 5.86111 70.3146 5.78333C70.0938 5.70556 69.8289 5.66667 69.5198 5.66667C68.8575 5.66667 68.3828 5.86667 68.0958 6.26667C67.8088 6.65556 67.6653 7.21667 67.6653 7.95V13H65.5458V4Z"
                fill="currentColor"
            />
            <path
                d="M72.1864 11.8167C72.1864 11.3833 72.2912 11.0444 72.501 10.8C72.7107 10.5444 73.064 10.4167 73.5607 10.4167C74.0795 10.4167 74.4438 10.5389 74.6536 10.7833C74.8743 11.0167 74.9847 11.3611 74.9847 11.8167C74.9847 12.2278 74.8799 12.5611 74.6701 12.8167C74.4604 13.0722 74.0906 13.2 73.5607 13.2C73.064 13.2 72.7107 13.0722 72.501 12.8167C72.2912 12.5611 72.1864 12.2278 72.1864 11.8167Z"
                fill="currentColor"
            />
            <path
                d="M78.3523 5.8H76.5971V4H78.3523V2.41667L80.4718 1.68333V4H83.4026V5.8H80.4718V9.81667C80.4718 10.3611 80.5711 10.75 80.7698 10.9833C80.9796 11.2167 81.3163 11.3333 81.7799 11.3333C82.1663 11.3333 82.5085 11.2889 82.8065 11.2C83.1156 11.1111 83.3805 10.9944 83.6013 10.85V12.7333C83.4137 12.8444 83.1156 12.95 82.7072 13.05C82.2987 13.15 81.8406 13.2 81.3328 13.2C80.2841 13.2 79.5224 12.9667 79.0478 12.5C78.5841 12.0222 78.3523 11.2278 78.3523 10.1167V5.8Z"
                fill="currentColor"
            />
            <path
                d="M86.8668 9.1C86.8889 9.91111 87.1207 10.5111 87.5623 10.9C88.0149 11.2778 88.6882 11.4667 89.5824 11.4667C90.2006 11.4667 90.7746 11.3722 91.3045 11.1833C91.8454 10.9833 92.2814 10.75 92.6126 10.4833V12.4C92.3918 12.5778 91.9944 12.7556 91.4204 12.9333C90.8574 13.1111 90.1674 13.2 89.3506 13.2C87.7389 13.2 86.5632 12.8167 85.8236 12.05C85.0951 11.2722 84.7308 10.0444 84.7308 8.36667C84.7308 6.85556 85.1061 5.71667 85.8567 4.95C86.6184 4.18333 87.7058 3.8 89.1188 3.8C89.8804 3.8 90.5262 3.90556 91.0561 4.11667C91.5859 4.31667 92.0109 4.60556 92.3311 4.98333C92.6512 5.36111 92.883 5.81667 93.0265 6.35C93.17 6.87222 93.2418 7.45556 93.2418 8.1V9.1H86.8668ZM89.0856 5.48333C87.6395 5.48333 86.8999 6.22778 86.8668 7.71667H91.1389C91.1389 6.98333 90.9788 6.42778 90.6587 6.05C90.3496 5.67222 89.8252 5.48333 89.0856 5.48333Z"
                fill="currentColor"
            />
            <path
                d="M101.789 6.41667C101.502 6.20556 101.149 6.02778 100.73 5.88333C100.321 5.73889 99.8355 5.66667 99.2725 5.66667C98.8641 5.66667 98.4998 5.71667 98.1797 5.81667C97.8596 5.90556 97.5891 6.06111 97.3683 6.28333C97.1586 6.49444 96.993 6.77778 96.8716 7.13333C96.7612 7.48889 96.706 7.92778 96.706 8.45C96.706 9.49444 96.9323 10.2389 97.3849 10.6833C97.8485 11.1167 98.5716 11.3333 99.554 11.3333C100.04 11.3333 100.498 11.2667 100.928 11.1333C101.37 10.9889 101.729 10.8056 102.005 10.5833V12.5833C101.751 12.7389 101.392 12.8778 100.928 13C100.465 13.1333 99.8852 13.2 99.1897 13.2C98.406 13.2 97.7216 13.1167 97.1365 12.95C96.5514 12.7722 96.0657 12.4944 95.6794 12.1167C95.293 11.7278 95.0005 11.2278 94.8018 10.6167C94.6141 10.0056 94.5203 9.26111 94.5203 8.38334C94.5203 6.83889 94.8956 5.68889 95.6462 4.93333C96.4079 4.17778 97.5836 3.8 99.1732 3.8C99.8466 3.8 100.382 3.85556 100.779 3.96667C101.188 4.06667 101.524 4.17778 101.789 4.3V6.41667Z"
                fill="currentColor"
            />
            <path
                d="M103.853 0.333333H105.973V5.36667C106.194 4.87778 106.547 4.49444 107.032 4.21667C107.529 3.93889 108.158 3.8 108.92 3.8C110.013 3.8 110.797 4.06111 111.271 4.58333C111.757 5.10556 112 5.93333 112 7.06667V13H109.897V7.5C109.897 6.83333 109.754 6.35 109.467 6.05C109.191 5.75 108.705 5.6 108.009 5.6C107.292 5.6 106.773 5.78333 106.453 6.15C106.133 6.50556 105.973 7.06667 105.973 7.83333V13H103.853V0.333333Z"
                fill="currentColor"
            />
            <defs>
                <linearGradient
                    id={String(paint0_linear)}
                    x1="0.888943"
                    y1="2.10752"
                    x2="6.47038"
                    y2="12.5201"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00F2FF" />
                    <stop offset="0.555517" stopColor="#0084FF" />
                    <stop offset="0.874101" stopColor="#016AF5" />
                </linearGradient>
                <linearGradient
                    id={String(paint1_linear)}
                    x1="13"
                    y1="3.46951"
                    x2="7.12285"
                    y2="13.5532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.129932" stopColor="#015AC8" />
                    <stop offset="0.531265" stopColor="#025ACA" />
                    <stop offset="1" stopColor="#006DFF" />
                </linearGradient>
                <linearGradient
                    id={String(paint2_linear)}
                    x1="4.77786"
                    y1="4.5009"
                    x2="14.4807"
                    y2="3.64439"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0037A2" />
                    <stop offset="0.84744" stopColor="#025ECE" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="14.6667" height="13.3333" fill="currentColor" transform="translate(0 1.33333)" />
                </clipPath>
            </defs>
        </svg>
    );
};
