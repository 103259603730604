import { DocumentStatusesEnum } from '@sberdevices/vc-contracts';

import { analyticsLs } from '../../../utils/lsStorages';
import { routesConfig } from '../../../configs/routes';
import { LocalStorageKeys } from '../../../const/localStorage';
import { fetchAnalytics } from '../../../utils/fetchAnalytics';

export function trackCreateScratchDocument(value: 'start' | 'nameSet'): void {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Document:CreateScratchFunnel',
            eventCategory: 'document',
            value,
        }),
    );
}

export function trackDeleteVideo(status: DocumentStatusesEnum, documentId: number): void {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Video:Delete',
            eventCategory: 'video',
            status,
            documentId,
        }),
    );
}

export function trackSberIdLogin(): void {
    const sberIdAuthPath: string | null = analyticsLs.get(LocalStorageKeys.SberIdPath);
    const sberIdAllowedPathMap = {
        [routesConfig.login]: 'login_page',
        [routesConfig.landing]: 'landing',
        [routesConfig.gameDevLanding]: 'landing_dev',
    };

    analyticsLs.clear();

    if (!sberIdAuthPath || !(sberIdAuthPath in sberIdAllowedPathMap)) {
        return;
    }

    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.submit({
            eventAction: 'User:Login',
            eventCategory: 'account',
            value: 'sberID',
            from: sberIdAllowedPathMap[sberIdAuthPath],
        }),
    );
}

function trackPaginationClick(value: string, offset: number): void {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Document:ShowPaginationPage',
            eventCategory: 'document',
            value,
            offset,
        }),
    );
}

export function trackDraftsPaginationClick(offset: number): void {
    trackPaginationClick('drafts', offset);
}

export function trackVideosPaginationClick(offset: number): void {
    trackPaginationClick('videos', offset);
}

export function trackFailedVideosPaginationClick(offset: number): void {
    trackPaginationClick('failedVideos', offset);
}
