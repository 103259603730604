import React, { ChangeEventHandler, CSSProperties, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { ParagraphText1 } from '@salutejs/plasma-b2c';
import throttle from 'lodash.throttle';
import { spacing } from '@salutejs/plasma-core';

import { useBodyEvent } from '../../hooks/useBodyEvent';

const StyledContainer = styled.label`
    display: block;
    position: relative;
    box-sizing: border-box;
    height: 56px;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 16px;
    cursor: text;
    transition: background 0.15s linear;
`;

const StyledLabel = styled.span`
    display: block;
    position: absolute;
    top: ${spacing[16]};
    left: ${spacing[16]};
    right: ${spacing[16]};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.019em;
    color: rgba(255, 255, 255, 0.56);
    transition: all 0.15s linear;
`;

const StyledTextarea = styled(ParagraphText1)`
    font-family: 'SB Sans Text', sans-serif;
    width: 100%;
    height: 100%;
    padding: ${spacing[16]};
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    box-sizing: border-box;
    transition: color 0.15s linear;
    resize: none;
`;

interface TextareaProps {
    value?: string;
    placeholder?: string;
    name?: string;
    className?: string;
    style?: CSSProperties;
    onChange: (value: string) => void;
    onSelectionChange?: (value: string, start: number, end: number) => void;
    onScroll?: (scrollTop: number) => void;
}

export const Textarea: React.FC<TextareaProps> = ({
    value,
    placeholder,
    name,
    className,
    style,
    onChange,
    onSelectionChange,
    onScroll,
    ...props
}) => {
    const textareaElement = useRef<HTMLTextAreaElement>(null);
    const [focus, setFocus] = useState(false);
    const [selectionStarted, setSelectionState] = useState(false);
    const changeHandler: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        onChange(event.target.value);
    };
    const labelFocused = Boolean(focus || !!value);

    const checkSelection = useCallback(() => {
        const textarea = textareaElement.current;

        if (!textarea || !onSelectionChange) {
            return;
        }

        const start = textarea.selectionStart;
        const finish = textarea.selectionEnd;
        const selection = textarea.value.substring(start, finish);

        onSelectionChange(selection, start, finish);
    }, [onSelectionChange]);

    const scrollListener = useCallback((event) => onScroll && throttle(() => onScroll(event.target.scrollTop))(), [
        onScroll,
    ]);

    const mouseUp = useCallback(() => {
        if (!textareaElement.current) {
            return;
        }

        const textarea = textareaElement.current;

        if (!selectionStarted) {
            if (Math.abs(textarea.selectionStart - textarea.selectionEnd) > 0) {
                textarea.focus();
                textarea.setSelectionRange(textarea.selectionStart, textarea.selectionEnd);
            }

            return;
        }

        checkSelection();
        setSelectionState(false);
    }, [checkSelection, selectionStarted]);

    useBodyEvent('mouseup', mouseUp);

    return (
        <StyledContainer className={className} style={style}>
            {!labelFocused && <StyledLabel>{placeholder}</StyledLabel>}
            <StyledTextarea
                as="textarea"
                ref={textareaElement}
                value={value}
                name={name}
                onChange={changeHandler}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onMouseDown={() => setSelectionState(true)}
                onKeyUp={checkSelection}
                onScroll={scrollListener}
                {...props}
            />
        </StyledContainer>
    );
};
