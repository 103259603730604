import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '@salutejs/plasma-b2c';

import { Modal, ModalCloseButton } from '../Modal/Modal';
import { fetchAnalytics } from '../../utils/fetchAnalytics';

import { FeedBackForm } from './FeedBackForm';
import { FeedbackSuccess } from './FeedBackSuccess';

const StyledModal = styled(Modal)`
    ${ModalCloseButton} {
        ${mediaQuery('XXL')(css`
            right: auto;
            left: 100%;
            margin-left: 36px;
        `)}

        ${mediaQuery('XL')(css`
            right: auto;
            left: 100%;
            margin-left: 36px;
        `)}
    }
`;

interface FeedbackModalProps {
    closeModal: () => void;
}

export const FeedbackModal: FC<FeedbackModalProps> = ({ closeModal }) => {
    useEffect(() => {
        fetchAnalytics().then((analyticsInstance) => {
            analyticsInstance.view({
                eventAction: 'Billing:BusinessFormView',
                eventCategory: 'billing',
            });
        });
    }, []);

    const [success, setSuccess] = useState<boolean>(false);

    return (
        <StyledModal
            closeOnClickOutside={false}
            width={success ? '450px' : '380px'}
            onClose={closeModal}
            open
            closePadding={false}
            e2e="feedbackModal"
        >
            {!success ? (
                <FeedBackForm closeModal={closeModal} setSuccess={setSuccess} />
            ) : (
                <FeedbackSuccess closeModal={closeModal} />
            )}
        </StyledModal>
    );
};
