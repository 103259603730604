import { background } from '@salutejs/plasma-tokens-b2c';

import { withCdnUrl } from '../utils/static';

const DEFAULT_DISPLAY_REFRESH_RATE_HZ = 60;
export const PLAYER_UPDATE_INTERVAL_MS = 1 / DEFAULT_DISPLAY_REFRESH_RATE_HZ;

// https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
export const GOOGLE_RECAPTCHA_SITE_KEY: string =
    typeof window !== 'undefined' && window.location.host !== 'visper.tech' && (window as any).Cypress
        ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' // Отключает капчу при тестировании в cypress не на домене visper.tech
        : '6LemIaQdAAAAAHabaG6LC0s_nfKsrwbAI0H9SIay';

// Примерное время воспроизведения одного символа (в мс.) для TTS аудиозаписей
export const VOICE_PLAYBACK_SPEED_PER_SYMBOL_MS = 66;

// Продолжительность заставки перехода между секциями (в мс.)
export const SLIDE_TRANSITION_MS = 1640;

// Лимит продолжительности аудио для воспроизведения в слайде вместо текста (в сек.)
export const MAX_SPEECH_AUDIO_DURATION = 60;

// Ссылки на пользовательское соглашение
export const LEGAL_TERMS_RU = 'https://visper.tech/constant/service_terms.pdf';
export const LEGAL_TERMS_EN = 'https://visper.tech/constant/service_terms_en.pdf';

export const bodyGradientBg = `${background} url(${withCdnUrl('images/background.png')}) no-repeat center -150px`;

export const containerWidth = 1872;
