import axios, { AxiosInstance } from 'axios';
import { authStorage, sceneLS } from 'utils/lsStorages';
import { getHost, routesConfig } from 'configs/routes';
import { TokenSlidingApi } from '@sberdevices/vc-contracts';
import * as queryString from 'querystring';

import { appConfig } from '../configs/appConfig';
import { HttpStatus } from '../const/httpStatus';
import { apiConfig } from '../configs/api';
import { configuration } from '../configs/base';

import { canUseDOM } from './canUseDom';
import { getToken, resetToken, setToken } from './token';
import { hasTokenExpired } from './hasTokenExpired';

const host = getHost();
const basePath = `${host}${appConfig.apiPath}`;
const unauthorizedUrls = [
    apiConfig.slidingToken,
    apiConfig.slidingTokenRefresh,
    apiConfig.activate,
    apiConfig.reactivate,
    apiConfig.signUp,
    apiConfig.resetPassword,
    apiConfig.publicVideo,
];

export const baseAxiosInstance: AxiosInstance = axios.create({
    baseURL: basePath,
    // Дефолтный формат axios для массивов в query параметрах a[]=val1&a[]=val2,
    // он не совпадает с ДРФ где не должно быть квадратных скобок
    paramsSerializer: (params) => queryString.stringify(params),
});

// Если будете переносить убедитесь что нет циркулярной зависимости
export function refreshSlidingToken(rfToken: string): Promise<string> {
    const axiosInstance = new TokenSlidingApi(configuration, basePath, baseAxiosInstance);

    return axiosInstance.tokenSlidingRefreshCreate({ token: rfToken }).then((response) => response.data.token);
}

async function refreshToken(token: string): Promise<string> {
    if (hasTokenExpired(token)) {
        try {
            const refreshedToken = await refreshSlidingToken(token);

            setToken(refreshedToken);

            return refreshedToken;
        } catch (refreshError) {
            resetToken();
            authStorage.clear();
            sceneLS.clear();
            window.location.href = routesConfig.login;
            throw refreshError;
        }
    }

    return token;
}

baseAxiosInstance.interceptors.request.use(async (config) => {
    if (!canUseDOM()) return config;

    const url = config.url || '';
    const isUnauthorizedReqUrl = unauthorizedUrls.some((item) => basePath + item === url);
    const token = getToken();

    if (!token || isUnauthorizedReqUrl) {
        return config;
    }

    const refreshedToken = await refreshToken(token);

    // @ts-ignore
    config.headers.common.Authorization = `Bearer ${refreshedToken}`;

    return config;
});

baseAxiosInstance.interceptors.response.use(undefined, (error) => {
    if (
        !unauthorizedUrls.some((item) => basePath + item === error.config.url) &&
        HttpStatus.Unauthorized === error.response?.status
    ) {
        if (canUseDOM()) {
            authStorage.clear();
            sceneLS.clear();
            window.location.href = routesConfig.login;
        }
    }

    return Promise.reject(error);
});
