import React, { FC } from 'react';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles: FC = () => (
    <style global jsx>{`
        html {
            height: 100%;
            height: -webkit-fill-available;
        }

        body {
            margin: 0;
            font-family: 'SB Sans Text', sans-serif;
            height: 100%;
            height: -webkit-fill-available;
            overflow: auto;
        }

        #__next {
            height: 100%;
            height: -webkit-fill-available;
        }

        /* stylelint-disable */
        * {
            box-sizing: border-box;
            outline: none;
            text-decoration: none;
            -webkit-tap-highlight-color: transparent;
        }
        /* stylelint-enable */

        ::-webkit-scrollbar {
            display: none;
        }

        div {
            scrollbar-width: none;
        }

        .grecaptcha-badge {
            display: none !important;
        }

        .mobileSlidesModalDNDHelper {
            z-index: 1;
        }
    `}</style>
);

export const BodyBGStyle = createGlobalStyle<{ background: string }>`
    body {
        background: ${({ background }) => background};
    }
`;
