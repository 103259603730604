import React, { SVGProps } from 'react';

export const PositionAtTable: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            d="M5.99993 5C5.99993 3.89543 6.89536 3 7.99993 3C9.10449 3 9.99992 3.89543 9.99992 5C9.99992 6.10457 9.10449 7 7.99993 7C6.89536 7 5.99993 6.10457 5.99993 5ZM2.87689e-08 14.0625C-0.000137699 13.4984 0.494261 13.0618 1.05404 13.1318L7.00746 13.876C7.66642 13.9583 8.33306 13.9583 8.99202 13.876L14.9459 13.1317C15.5055 13.0618 15.9999 13.498 16 14.062C16.0001 14.58 15.5802 15 15.0622 15H0.937727C0.419924 15 0.000126445 14.5803 2.87689e-08 14.0625ZM6.23595 8C5.4784 8 4.78588 8.428 4.44709 9.10557L3.58539 10.829C3.29051 11.4187 3.63714 12.1275 4.28371 12.2568L7.21541 12.8431C7.73326 12.9467 8.2665 12.9467 8.78434 12.8431L11.7161 12.2568C12.3626 12.1275 12.7092 11.4187 12.4144 10.829L11.5527 9.10557C11.2139 8.428 10.5214 8 9.76381 8H6.23595Z"
            fill="currentColor"
        />
    </svg>
);
