import React, { SVGProps } from 'react';

export const Audio: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M8.16666 6.38313C8.16666 5.8934 8.52315 5.47654 9.00696 5.40054L17.8448 4.01234C18.4515 3.91703 19 4.38606 19 5.00022V8.085C19 8.085 19 8.08501 19 8.08501C19 8.08501 19 8.08501 19 8.08501V17.0184C19 17.0184 19 17.0184 19 17.0184C18.9999 18.1931 17.6941 19.1454 16.0833 19.1454C14.4725 19.1454 13.1667 18.1931 13.1667 17.0184C13.1667 15.8436 14.4725 14.8913 16.0833 14.8913C16.7061 14.8913 17.3333 14.4463 17.3333 13.8235V9.51614C17.3333 8.90198 16.7849 8.43295 16.1781 8.52825L10.6782 9.39216C10.1917 9.46856 9.83333 9.88767 9.83333 10.38V17.8675L9.83333 17.8692C9.83333 19.0439 8.52749 19.9962 6.91667 19.9962C5.30584 19.9962 4 19.0439 4 17.8692C4 16.6944 5.30584 15.7421 6.91667 15.7421C7.53945 15.7421 8.16666 15.2971 8.16666 14.6743V9.78665V6.38313Z"
            fill="currentColor"
        />
    </svg>
);
