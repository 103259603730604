import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@salutejs/plasma-b2c';
import { Trans, useTranslation } from 'next-i18next';
import { link } from '@salutejs/plasma-tokens-b2c';
import { applySpacing } from '@salutejs/plasma-core';
import { useToast } from 'hooks/useToast';

import { Modal } from '../Modal/Modal';
import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';
import { approveTerms, getUser } from '../../api/user';
import { UserContext } from '../../store/UserContext';
import { LEGAL_TERMS_RU, LEGAL_TERMS_EN } from '../../const/global';
import { useLocale } from '../../hooks/useLocale';

const StyledLink = styled.a`
    color: ${link};
`;

const StyledCheckbox = styled(Checkbox)`
    align-items: center;

    ${applySpacing}

    span {
        white-space: normal;
    }
`;

export const TermApproveModal: React.FC = () => {
    const { user, setUser } = useContext(UserContext);
    const { showToast } = useToast();
    const { t } = useTranslation('termApproveModal');
    const [approve, setApprove] = useState<boolean>(false);
    const { code } = useLocale();

    const approveChangeHandle = useCallback((event) => setApprove(event.target.checked), []);

    const submit = useCallback(() => {
        approveTerms()
            .then(getUser)
            .then(setUser)
            .catch(() => showToast.error(t('Ошибка сохранения данных пользователя')));
    }, [showToast, setUser, t]);

    return user ? (
        <Modal open={!user.term_approve && user.term_approve != null} width="604px" canClose={false}>
            <ModalHeader>
                <Trans t={t} i18nKey="title">
                    Мы обновили{' '}
                    <StyledLink
                        href={code === 'ru' ? LEGAL_TERMS_RU : LEGAL_TERMS_EN}
                        target="_blank"
                        rel="nofollow noopener"
                    >
                        пользовательское соглашение
                    </StyledLink>
                    . Чтобы продолжить пользоваться сервисом, ознакомьтесь с новыми условиями и примите их.
                </Trans>
            </ModalHeader>

            <ModalBody>
                <StyledCheckbox
                    onChange={approveChangeHandle}
                    label={t('Соглашаюсь с условиями пользовательского соглашения') as string}
                />
            </ModalBody>

            <ModalFooter>
                <ModalBasicButton pin="square-square" size="m" view="primary" onClick={submit} disabled={!approve}>
                    {t('Продолжить')}
                </ModalBasicButton>
            </ModalFooter>
        </Modal>
    ) : null;
};
