import React, { SVGProps } from 'react';

export const Forward: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            d="M6.11475 7.13259V7.99478C6.11475 8.79194 7.00186 9.26864 7.66663 8.8287L10.5523 6.91898C11.1353 6.53316 11.1525 5.6835 10.5857 5.27428L7.7001 3.19096C7.03875 2.71349 6.11475 3.18604 6.11475 4.00173V5.01709L3.58535 3.19096C2.92401 2.71349 2 3.18604 2 4.00173V7.99478C2 8.79194 2.88711 9.26864 3.55189 8.8287L6.11475 7.13259Z"
            fill="currentColor"
        />
    </svg>
);
