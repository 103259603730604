import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';
import { FeedbackFormData } from '../components/FeedbackModal/utils';

interface AnswerData {
    name: string | null;
    id: number;
    cd: string | null;
    email: string | null;
    phone: string | null;
    company_name: string | null;
    position: string | null;
    comment: string | null;
    how_know_about_us: string | null;
}

export enum ResponseStates {
    Success = 201,
    NetworkError = 400,
    TooManyRequests = 429,
}

interface Answer {
    data: AnswerData;
    status: number;
}

export async function sendForm(data: FeedbackFormData): Promise<Answer> {
    try {
        return await baseAxiosInstance.post(apiConfig.feedbackFormSend, data);
    } catch (e) {
        return { data: e as AnswerData, status: ResponseStates.TooManyRequests };
    }
}
