import React, { useContext, useEffect, useMemo } from 'react';
import { ApiDataContext } from 'store/ApiDataContext/ApiDataContext';
import { useTranslation } from 'next-i18next';
import { canUseDOM } from 'utils/canUseDom';

import { Modal, ModalOverlayType } from '../Modal/Modal';
import { InvoiceContext, InvoiceContextStatus } from '../../store/InvoiceContext';
import { Invoice, InvoiceStatus } from '../../typings/invoice';
import { ModalBasicText } from '../Modal/ModalBasicText';

import { PaymentStatusModalSuccessBlock } from './PaymentStatusModalSuccessBlock';
import { PaymentStatusModalErrorBlock } from './PaymentStatusModalErrorBlock';

enum PaymentStatus {
    InProgress = 'inProgress',
    UpdateStatus = 'updateStatus',
    Success = 'success',
    Error = 'error',
}

type Processor = (invoiceStatus: InvoiceContextStatus, invoice: Invoice | null) => boolean;

const statusProcessors: Record<PaymentStatus, Processor> = {
    [PaymentStatus.InProgress]: (invoiceStatus) => invoiceStatus === InvoiceContextStatus.pooling,
    [PaymentStatus.UpdateStatus]: (invoiceStatus) =>
        invoiceStatus === InvoiceContextStatus.finalInvoiceRequest ||
        invoiceStatus === InvoiceContextStatus.initialInvoiceRequest,
    [PaymentStatus.Success]: (invoiceStatus, invoice) =>
        invoiceStatus === InvoiceContextStatus.ready && invoice?.status === InvoiceStatus.Confirmed,
    [PaymentStatus.Error]: (invoiceStatus, invoice) =>
        (invoiceStatus === InvoiceContextStatus.ready && invoice?.status !== InvoiceStatus.Confirmed) ||
        invoiceStatus === InvoiceContextStatus.invoiceRequestError,
};

export const PaymentStatusModal: React.FC = () => {
    const { t } = useTranslation('payment');
    const { status: invoiceStatus, invoice, modal } = useContext(InvoiceContext);
    const {
        data: { currentSubscription },
        fetchers: { fetchCurrentSubscription },
    } = useContext(ApiDataContext);
    const status = useMemo(
        () =>
            (Object.keys(statusProcessors) as PaymentStatus[]).find((key) =>
                statusProcessors[key](invoiceStatus, invoice),
            ),
        [invoice, invoiceStatus],
    );

    useEffect(() => {
        if (modal.isModalOpen && !currentSubscription.status) {
            fetchCurrentSubscription();
        }
    }, [fetchCurrentSubscription, modal.isModalOpen, currentSubscription.status, invoiceStatus]);

    return canUseDOM() ? (
        <Modal
            open={modal.isModalOpen}
            width="541px"
            onClose={modal.closeModal}
            overlayType={ModalOverlayType.LightBlur}
        >
            {status === PaymentStatus.UpdateStatus && <ModalBasicText>{t('Обновляем статус.')}</ModalBasicText>}
            {status === PaymentStatus.InProgress && <ModalBasicText>{t('Ожидаем оплату.')}</ModalBasicText>}
            {status === PaymentStatus.Success && (
                <PaymentStatusModalSuccessBlock
                    currentSubscription={currentSubscription.data}
                    onClose={modal.closeModal}
                />
            )}
            {status === PaymentStatus.Error && <PaymentStatusModalErrorBlock />}
        </Modal>
    ) : null;
};
