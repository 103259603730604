import React, { SVGProps } from 'react';

export const HorizontalOrientationFilled: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 21 15" {...props}>
        <path d="M1 4.37037V1H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 4.37037V1H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 4.37037V1H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 4.37037V1H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 4.37037V1H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 4.37037V1H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 10.6296V14H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 10.6296V14H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 10.6296V14H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 10.6296V14H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 10.6296V14H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 10.6296V14H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path
            d="M5.7473 10H6.4493V5.758H5.9273L4.8473 6.454V7.192L5.7473 6.634V10ZM10.1998 6.976V6.178C10.0318 5.998 9.59981 5.734 8.99381 5.752C8.00981 5.794 7.29581 6.454 7.29581 8.008C7.29581 9.34 7.96781 10.048 8.95781 10.048C9.75581 10.048 10.4698 9.526 10.4698 8.596C10.4698 7.846 9.95981 7.264 9.11981 7.264C8.54381 7.264 8.12981 7.57 7.97981 8.026C7.96781 7.954 7.96781 7.876 7.96781 7.786C7.97981 6.946 8.38781 6.496 9.01181 6.46C9.52181 6.424 9.94781 6.67 10.1998 6.976ZM8.94581 7.9C9.46781 7.9 9.74981 8.2 9.74981 8.614C9.74981 9.034 9.44381 9.352 8.93381 9.352C8.45381 9.352 8.18381 9.094 8.05781 8.692C8.09981 8.206 8.44781 7.9 8.94581 7.9ZM11.2685 9.598C11.2685 9.85 11.3765 10.06 11.7365 10.06C12.1025 10.06 12.2105 9.85 12.2105 9.598C12.2105 9.34 12.1025 9.136 11.7365 9.136C11.3765 9.136 11.2685 9.34 11.2685 9.598ZM11.2685 7.312C11.2685 7.564 11.3765 7.774 11.7365 7.774C12.1025 7.774 12.2105 7.564 12.2105 7.312C12.2105 7.054 12.1025 6.85 11.7365 6.85C11.3765 6.85 11.2685 7.054 11.2685 7.312ZM13.272 8.824V9.622C13.44 9.802 13.872 10.066 14.478 10.048C15.462 10.006 16.176 9.346 16.176 7.792C16.176 6.46 15.51 5.752 14.52 5.752C13.716 5.752 13.008 6.274 13.008 7.204C13.008 7.954 13.512 8.536 14.352 8.536C14.928 8.536 15.348 8.23 15.492 7.774C15.504 7.846 15.51 7.924 15.51 8.002C15.498 8.848 15.084 9.304 14.466 9.34C13.95 9.376 13.53 9.13 13.272 8.824ZM14.532 7.9C14.004 7.9 13.722 7.6 13.722 7.186C13.722 6.76 14.034 6.448 14.544 6.448C15.024 6.448 15.294 6.706 15.414 7.114C15.372 7.594 15.024 7.9 14.532 7.9Z"
            fill="currentColor"
        />
    </svg>
);
