import React, { SVGProps } from 'react';

export const TemplateOnlyCharacter: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 68 52" {...props}>
        <path
            d="M33.914 13C31.2769 13 29.1391 15.1378 29.1391 17.7748C29.1391 20.4119 31.2769 22.5497 33.914 22.5497H34.0841C36.7212 22.5497 38.859 20.4119 38.859 17.7748C38.859 15.1378 36.7212 13 34.0841 13H33.914ZM28.7276 24.7328C27.5076 24.7328 26.4091 25.4717 25.949 26.6016L23.2231 33.2972C22.5114 35.0451 23.5713 37.0082 25.4232 37.3721L33.4213 38.9437C33.8033 39.0188 34.1962 39.0188 34.5782 38.9437L42.5763 37.3721C44.4281 37.0082 45.488 35.0451 44.7764 33.2972L42.0504 26.6016C41.5904 25.4717 40.4919 24.7328 39.2719 24.7328H28.7276Z"
            fill="currentColor"
        />
    </svg>
);
