import React, { SVGProps } from 'react';

export const VK: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 26 16" {...props}>
        <path
            d="M25.4034 1.08341C25.5841 0.458898 25.4034 0 24.5432 0H21.6988C20.9756 0 20.6421 0.39642 20.4613 0.833557C20.4613 0.833557 19.0148 4.48693 16.9657 6.86004C16.3027 7.54698 16.0014 7.76555 15.6397 7.76555C15.459 7.76555 15.1972 7.54698 15.1972 6.92252V1.08342C15.1972 0.333996 14.9873 0 14.3846 0H9.91481C9.46285 0 9.19103 0.347824 9.19103 0.677468C9.19103 1.3879 10.2155 1.55174 10.3212 3.55019V7.8905C10.3212 8.8421 10.1553 9.01463 9.79368 9.01463C8.82941 9.01463 6.48383 5.34494 5.09268 1.14584C4.82004 0.329685 4.5466 0 3.81964 0H0.975235C0.162546 0 0 0.39642 0 0.833557C0 1.61421 0.964318 5.48616 4.49003 10.6071C6.84048 14.1043 10.1521 16 13.1656 16C14.9736 16 15.1973 15.5789 15.1973 14.8537V12.2105C15.1973 11.3684 15.3686 11.2004 15.9411 11.2004C16.363 11.2004 17.0862 11.4189 18.7738 13.1051C20.7024 15.1035 21.0203 16 22.1051 16H24.9496C25.7622 16 26.1686 15.5789 25.9342 14.748C25.6777 13.9199 24.7569 12.7183 23.535 11.294C22.872 10.4822 21.8776 9.60791 21.5763 9.17067C21.1544 8.60869 21.2749 8.35883 21.5763 7.85927C21.5763 7.85927 25.0418 2.80077 25.4034 1.08341Z"
            fill="currentColor"
        />
    </svg>
);
