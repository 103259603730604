// TODO убрать когда бэк обновит api

import { Resources } from 'next-i18next';
import { SubscriptionType } from 'typings/subscription';

export type FeatureValue = keyof Resources['payment'] | boolean | number;

type ProductFeature = {
    show?: (deepfakeEnabled: boolean) => boolean;
    title: keyof Resources['payment'];
    values: Record<SubscriptionType, FeatureValue>;
};

export const productFeatures: ProductFeature[] = [
    {
        title: 'Количество минут',
        values: {
            [SubscriptionType.Free]: 2,
            [SubscriptionType.Minutes]: 'до 5',
            [SubscriptionType.Corporate]: 'под запрос',
            [SubscriptionType.Subscription]: 20,
        },
    },
    {
        title: 'Одновременно генерируемых видео',
        values: {
            [SubscriptionType.Free]: 1,
            [SubscriptionType.Minutes]: 3,
            [SubscriptionType.Corporate]: 'под запрос',
            [SubscriptionType.Subscription]: 3,
        },
    },
    {
        title: 'Символов на слайд',
        values: {
            [SubscriptionType.Free]: 200,
            [SubscriptionType.Minutes]: 1000,
            [SubscriptionType.Corporate]: 'под запрос',
            [SubscriptionType.Subscription]: 1000,
        },
    },
    {
        title: 'Количество лиц для FaceSwap',
        values: {
            [SubscriptionType.Free]: false,
            [SubscriptionType.Minutes]: 3,
            [SubscriptionType.Corporate]: 'под запрос',
            [SubscriptionType.Subscription]: 3,
        },
    },
    {
        title: 'Разные аватары в одном видео',
        values: {
            [SubscriptionType.Free]: false,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Кастомные переходы между слайдами',
        values: {
            [SubscriptionType.Free]: false,
            [SubscriptionType.Minutes]: false,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: false,
        },
    },
    {
        title: 'Персонализированные аватары',
        values: {
            [SubscriptionType.Free]: false,
            [SubscriptionType.Minutes]: false,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: false,
        },
    },
    {
        title: 'Отключение логотипа',
        values: {
            [SubscriptionType.Free]: false,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Скачивание видео',
        values: {
            [SubscriptionType.Free]: false,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Все голоса',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Поддержка в Telegram',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Создание видео на основе презентаций',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Доступ к API',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Все жесты',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Реалистичные аватары',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Аватары из своей фотографии',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
    {
        title: 'Анимационные аватары',
        values: {
            [SubscriptionType.Free]: true,
            [SubscriptionType.Minutes]: true,
            [SubscriptionType.Corporate]: true,
            [SubscriptionType.Subscription]: true,
        },
    },
];
