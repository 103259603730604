export const desktopSize = 1440;
export const tabletSize = 768;
export const MIN_SUPPORTED_SCREEN_WIDTH = 960;
export const FULL_HD_ASPECT = 16 / 9;
export const FULL_HD_ASPECT_VERTICAL = 9 / 16;

export const media = {
    tablet: `@media screen and (min-width: ${tabletSize}px) and (max-width: ${desktopSize - 1}px)`,
    mobile: `@media screen and (max-width: ${tabletSize - 1}px)`,
};

export function isDesktop(): boolean {
    return window.innerWidth >= desktopSize;
}

export const buttonPrimaryBrand = '#3F81FD';

export const buttonGray = '#1f1f1f';
