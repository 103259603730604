import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createSubscription, getWebpushSubscriptions, removeSubscriptions } from 'api/webpush';
import { useTranslation } from 'next-i18next';
import {
    APPLICATION_KEY,
    checkNotificationSupport,
    formatSubscribtionData,
    getBrowserName,
    notificationPermission,
    urlBase64ToUint8Array,
} from 'utils/webpush';
import { useToast } from 'hooks/useToast';
import { putUser } from 'api/user';
import { detect } from 'detect-browser';

import { UserContext } from './UserContext';

interface WebpushContextInterface {
    isWebpushSupported: boolean;
    subscribeToWebpush: () => Promise<void>;
    unsubscribeFromWebpush: () => Promise<void>;
    subscriptionOn: boolean;
    toggleSubscription: () => Promise<void>;
}

const initialContext: WebpushContextInterface = {
    isWebpushSupported: false,
    subscriptionOn: false,
    unsubscribeFromWebpush: async () => {},
    subscribeToWebpush: async () => {},
    toggleSubscription: async () => {},
};
export const WebpushContext = createContext<WebpushContextInterface>(initialContext);

export const WebpushContextProvider: React.FC = ({ children }) => {
    const { t } = useTranslation('webpush');
    const [subscriptionOn, setSubscriptionOn] = useState(false);
    const [isWebpushSupported, setIsWebpushSupported] = useState(false);
    const { showToast } = useToast();
    const { user, setUser } = useContext(UserContext);

    const unsubscribeFromWebpush = useCallback(async () => {
        try {
            setSubscriptionOn(false);

            const userSubscriptions = await getWebpushSubscriptions();
            const subscriptionIds = userSubscriptions.map((el) => el.registration_id);

            await removeSubscriptions(subscriptionIds);
            const updatedUser = await putUser({ webpush_on: false });

            setUser(updatedUser);
        } catch (e) {
            showToast.error(t('Что-то пошло не так'));
            setSubscriptionOn(false);
        }
    }, [setUser, showToast, t]);

    const subscribeToWebpush = useCallback(async () => {
        try {
            setSubscriptionOn(true);

            await checkNotificationSupport();
            const serviceWorkerRegistration = await navigator.serviceWorker.register('/sw.js');
            await notificationPermission();

            const browser = detect();
            const browserName = getBrowserName(browser?.name);
            const data = await serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(APPLICATION_KEY),
            });

            await createSubscription({
                ...formatSubscribtionData(data),
                browser: browserName,
            });
            const updatedUser = await putUser({ webpush_on: true });

            setUser(updatedUser);
        } catch (e) {
            showToast.error(
                t(
                    'К сожалению, нам не удалось включить пуш-уведомления для вас. Попробуйте позже или обратитесь в чат поддержки',
                ),
            );
            setSubscriptionOn(false);
        }
    }, [setUser, showToast, t]);

    const toggleSubscription = useCallback(() => {
        if (subscriptionOn) {
            return unsubscribeFromWebpush();
        }

        return subscribeToWebpush();
    }, [subscribeToWebpush, subscriptionOn, unsubscribeFromWebpush]);

    useEffect(() => {
        setSubscriptionOn(Boolean(user?.webpush_on));
    }, [setSubscriptionOn, user?.webpush_on]);

    useEffect(() => {
        checkNotificationSupport()
            .then(() => setIsWebpushSupported(true))
            .catch(() => setIsWebpushSupported(false));
    }, []);

    const value = useMemo(() => {
        return {
            isWebpushSupported,
            subscribeToWebpush,
            unsubscribeFromWebpush,
            subscriptionOn,
            toggleSubscription,
        };
    }, [isWebpushSupported, subscribeToWebpush, subscriptionOn, toggleSubscription, unsubscribeFromWebpush]);

    return <WebpushContext.Provider value={value}>{children}</WebpushContext.Provider>;
};
