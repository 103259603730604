import React, { SVGProps } from 'react';

export const SceneContentIcon: React.FC<SVGProps<SVGSVGElement>> = () => (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.89543 20 2 20H22C23.1046 20 24 19.1046 24 18V2C24 0.895431 23.1046 0 22 0H2ZM19.7645 4.85278C19.7645 6.00144 18.8164 6.93261 17.6468 6.93261C16.4773 6.93261 15.5292 6.00144 15.5292 4.85278C15.5292 3.70412 16.4773 2.77294 17.6468 2.77294C18.8164 2.77294 19.7645 3.70412 19.7645 4.85278ZM9.17164 7.60538C8.79286 7.23336 8.18938 7.2222 7.79709 7.57996L2.85591 12.0863C2.44785 12.4584 2.41873 13.0909 2.79089 13.499C3.16304 13.907 3.79553 13.9362 4.2036 13.564L8.44547 9.69547L11.9975 13.1841L12.0143 13.2006L14.4761 15.6184C14.8701 16.0054 15.5033 15.9997 15.8903 15.6057C16.2773 15.2116 16.2716 14.5785 15.8775 14.1915L14.177 12.5213L15.8823 11.0325L18.4011 13.2315C18.8172 13.5947 19.4489 13.5519 19.8121 13.1358C20.1753 12.7198 20.1325 12.0881 19.7165 11.7248L16.54 8.95173C16.1632 8.62282 15.6014 8.62282 15.2247 8.95173L12.7459 11.1158L9.17164 7.60538Z"
            fill="white"
        />
    </svg>
);
