import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { tertiary, white } from '@salutejs/plasma-tokens-b2c/colors';
import { fontWeights } from '@salutejs/plasma-tokens-b2c/typographyValues';
import { Icon } from 'components/Icon/Icon';
import { FeatureValue } from 'const/productFeatures';

const Container = styled.div`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StatusText = styled.span`
    text-align: center;
    font-weight: ${fontWeights.Semibold};
    color: ${white};
    height: 32px;
    margin-bottom: 34px;
`;

const Status = styled.span<{ isEnabled: boolean }>`
    display: flex;
    color: ${white};
    height: 32px;
    width: 32px;
    margin-bottom: 34px;
    border-radius: 50%;
    background-color: ${({ isEnabled }) => (isEnabled ? tertiary : 'rgba(255, 87, 104, 0.2)')};

    > span {
        margin: auto;
    }
`;

interface TariffBenefitsProps {
    benefits: FeatureValue[];
}

export const TariffBenefits: FC<TariffBenefitsProps> = ({ benefits }) => {
    const { t } = useTranslation('payment');

    return benefits ? (
        <Container>
            {benefits.map((f, k) =>
                typeof f === 'boolean' ? (
                    <Status data-e2e="tariffStatus" key={k} isEnabled={f}>
                        {f ? <Icon icon="done" width={18} /> : <Icon icon="close" width={12} height={12} />}
                    </Status>
                ) : (
                    <StatusText key={k}>{typeof f === 'number' ? f : t(f)}</StatusText>
                ),
            )}
        </Container>
    ) : null;
};
