import React, { SVGProps } from 'react';

export const Snowflake: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            d="M5.5542 1H6.38232V2.6737L7.70101 1.39978L8.28658 1.96547L6.38232 3.80507V5.36223L7.74833 4.60034L8.41331 2.06667L9.21603 2.26329L8.74916 4.04214L10.3778 3.13379L10.7918 3.82661L9.19282 4.71845L10.9999 5.16106L10.7963 5.93652L8.16463 5.29192L6.84546 6.02768L8.22336 6.7962L10.7038 6.06658L10.945 6.83189L9.20501 7.34371L10.6575 8.15386L10.2435 8.84668L8.73318 8.00431L9.30118 9.80639L8.50897 10.0394L7.68289 7.41851L6.38232 6.69313V8.30102L8.28658 10.1406L7.70101 10.7063L6.38232 9.43239V11H5.5542V9.40512L4.20728 10.7063L3.62172 10.1406L5.5542 8.27375V6.74788L4.23186 7.48541L3.52872 9.97683L2.72977 9.76639L3.21317 8.05358L1.82719 8.82661L1.41313 8.13379L2.85562 7.32924L1.00012 6.83862L1.21715 6.06658L3.86444 6.76657L5.18922 6.02768L3.85268 5.28222L1.20894 5.95339L1 5.17928L2.83353 4.7138L1.27885 3.84668L1.69291 3.15386L3.23236 4.01248L2.76 2.26926L3.56113 2.06667L4.24078 4.57493L5.5542 5.30748V3.83234L3.62172 1.96547L4.20728 1.39978L5.5542 2.70097V1Z"
            fill="currentColor"
        />
    </svg>
);
