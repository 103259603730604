import { iconSet as globalIconSet } from '@salutejs/plasma-icons/Icon';

import { Alert } from './Icon.assets/Alert';
import { Collapse } from './Icon.assets/Collapse';
import { Expand } from './Icon.assets/Expand';
import { Loading } from './Icon.assets/Loading';
import { TtsVoice } from './Icon.assets/Voice';
import { TemplateOnlyCharacter } from './Icon.assets/TemplateOnlyCharacter';
import { TemplateContentInWindow } from './Icon.assets/TemplateContentInWindow';
import { TemplateSpeakerInWindow } from './Icon.assets/TemplateSpeakerInWindow';
import { TemplateContentOnBackground } from './Icon.assets/TemplateContentOnBackground';
import { TemplateOnlyContent } from './Icon.assets/TemplateOnlyContent';
import { AlignLeft } from './Icon.assets/AlignLeft';
import { AlignCenter } from './Icon.assets/AlignCenter';
import { AlignRight } from './Icon.assets/AlignRight';
import { Mountains } from './Icon.assets/Mountains';
import { PositionUpToChest } from './Icon.assets/PositionUpToChest';
import { PositionStanding } from './Icon.assets/PositionStanding';
import { PositionAtTable } from './Icon.assets/PositionAtTable';
import { FaceSwap } from './Icon.assets/FaceSwap';
import { Eye } from './Icon.assets/Eye';
import { EyeCrossed } from './Icon.assets/EyeCrossed';
import { Duplicate } from './Icon.assets/Duplicate';
import { Gesture } from './Icon.assets/Gesture';
import { Telegram } from './Icon.assets/Telegram';
import { SberLogo } from './Icon.assets/SberLogo';
import { AudioInverted } from './Icon.assets/AudioInverted';
import { File } from './Icon.assets/File';
import { Unreal } from './Icon.assets/Unreal';
import { Unity } from './Icon.assets/Unity';
import { Maya } from './Icon.assets/Maya';
import { Blender } from './Icon.assets/Blender';
import { Link } from './Icon.assets/Link';
import { TextMarker } from './Icon.assets/TextMarker';
import { Audio } from './Icon.assets/Audio';
import { Watermark } from './Icon.assets/Watermark';
import { Profile } from './Icon.assets/Profile';
import { PauseBold } from './Icon.assets/PauseBold';
import { Snowflake } from './Icon.assets/Snowflake';
import { Forward } from './Icon.assets/Forward';
import { TextAccent } from './Icon.assets/TextAccent';
import { Music } from './Icon.assets/Music';
import { VK } from './Icon.assets/VK';
import { Facebook } from './Icon.assets/Facebook';
import { InfoCircle } from './Icon.assets/InfoCircle';
import { Fire } from './Icon.assets/Fire';
import { RoubleCircleFill } from './Icon.assets/RoubleCircleFill';
import { CommonIcon } from './Icon.assets/CommonIcon';
import { SceneBackgroundIcon } from './Icon.assets/SceneBackgroundIcon';
import { SceneContentIcon } from './Icon.assets/SceneContentIcon';
import { SceneAvatarIcon } from './Icon.assets/SceneAvatarIcon';
import { SceneResultIcon } from './Icon.assets/SceneResultIcon';
import { Save } from './Icon.assets/Save';
import { InfoFill } from './Icon.assets/InfoFill';
import { Apple } from './Icon.assets/Apple';
import { Gmail } from './Icon.assets/Gmail';
import { Grid } from './Icon.assets/Grid';
import { Female } from './Icon.assets/Female';
import { Male } from './Icon.assets/Male';
import { Union } from './Icon.assets/Union';
import { LeftArrow } from './Icon.assets/LeftArrow';
import { RightArrow } from './Icon.assets/RightArrow';
import { HorizontalOrientationFilled } from './Icon.assets/HorizontalOrientationFilled';
import { VerticalOrientationFilled } from './Icon.assets/VerticalOrientationFilled';
import { HorizontalOrientation } from './Icon.assets/HorizontalOrientation';
import { VerticalOrientation } from './Icon.assets/VerticalOrientation';
import { BillingHistory } from './Icon.assets/BillingHistory';
import { Tariffs } from './Icon.assets/Tariffs';
import { Mail } from './Icon.assets/Mail';
import { VkSharing } from './Icon.assets/VkSharing';
import { FacebookSharing } from './Icon.assets/FacebookSharing';
import { AspectAlbum } from './Icon.assets/AspectAlbum';
import { AspectPortrait } from './Icon.assets/AspectPortrait';
import { AspectSquare } from './Icon.assets/AspectSquare';

export const iconSet = {
    ...globalIconSet,
    alert: Alert, // attention circle
    alignCenter: AlignCenter,
    alignLeft: AlignLeft,
    alignRight: AlignRight,
    collapse: Collapse, // у Иры
    expand: Expand, // у Иры
    eye: Eye, // eye
    eyeCrossed: EyeCrossed, // у Иры
    loading: Loading, // заменить на spinner?
    mountains: Mountains, // заменить на picture?
    templateContentInWindow: TemplateContentInWindow,
    templateContentOnBackground: TemplateContentOnBackground,
    templateOnlyCharacter: TemplateOnlyCharacter,
    templateOnlyContent: TemplateOnlyContent,
    templateSpeakerInWindow: TemplateSpeakerInWindow,
    voice: TtsVoice,
    positionUpToChest: PositionUpToChest,
    positionStanding: PositionStanding,
    positionAtTable: PositionAtTable,
    faceSwap: FaceSwap,
    duplicate: Duplicate,
    gesture: Gesture, // у Иры hello
    telegram: Telegram, // у Иры
    sberLogo: SberLogo, // sber
    file: File, // у Иры
    unreal: Unreal,
    unity: Unity,
    maya: Maya,
    blender: Blender,
    link: Link,
    textMarker: TextMarker,
    audioInverted: AudioInverted,
    audio: Audio,
    watermark: Watermark,
    profile: Profile,
    pauseBold: PauseBold,
    snowflake: Snowflake,
    forward: Forward,
    textAccent: TextAccent,
    music: Music,
    vk: VK, // у Иры
    facebook: Facebook, // у Иры
    infoCircle: InfoCircle,
    infoFill: InfoFill,
    fire: Fire, // fire
    roubleCircleFill: RoubleCircleFill,
    commonIcon: CommonIcon,
    sceneBackgroundIcon: SceneBackgroundIcon,
    sceneAvatarIcon: SceneAvatarIcon,
    sceneContentIcon: SceneContentIcon,
    sceneResultIcon: SceneResultIcon,
    save: Save,
    apple: Apple,
    gmail: Gmail,
    grid: Grid,
    female: Female,
    male: Male,
    union: Union,
    leftArrow: LeftArrow,
    rightArrow: RightArrow,
    horizontalOrientationFilled: HorizontalOrientationFilled,
    verticalOrientationFilled: VerticalOrientationFilled,
    horizontalOrientation: HorizontalOrientation,
    verticalOrientation: VerticalOrientation,
    billingHistory: BillingHistory,
    tariffs: Tariffs,
    mail: Mail,
    vkSharing: VkSharing,
    facebookSharing: FacebookSharing,
    aspectAlbum: AspectAlbum,
    aspectPortrait: AspectPortrait,
    aspectSquare: AspectSquare,
};
