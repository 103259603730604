import React, { SVGProps } from 'react';

export const Fire: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 21 28" {...props}>
        <path
            d="M18.4291 23.0663C18.4291 23.0663 16.8265 26.3615 12.8202 27.1853C10.7335 27.6144 9.94947 27.6818 5.776 26.3615C1.60253 25.0413 0.801263 22.2425 0.801263 22.2425L4.58972 23.7381C4.19441 23.4173 3.39328 22.5981 1.76966 20.5949C-1.43539 16.6407 0.434254 12.9062 1.76971 11.5332C1.50262 12.0824 1.28895 13.3455 2.57097 14.0046C4.1735 14.8284 4.97476 14.8284 4.97476 12.357C4.97476 9.88558 6.57729 5.76659 8.98108 5.76659C10.9041 5.76659 9.24816 1.6476 8.98108 0C18.5962 4.94279 14.5899 8.23798 14.5899 12.357C14.5899 15.6522 16.7266 11.8078 17.7949 9.06178C19.3975 10.7094 21 14.0046 21 14.8284C21 18.9965 16.7266 23.4136 14.5899 24.2374L18.4291 23.0663Z"
            fill="currentColor"
        />
    </svg>
);
