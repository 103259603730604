import React, { SVGProps } from 'react';

export const Expand: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <path
            d="M1 8a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 100-2H2.5a.5.5 0 01-.5-.5V9a1 1 0 00-1-1zM13 6a1 1 0 001-1V1a1 1 0 00-1-1H9a1 1 0 000 2h2.5a.5.5 0 01.5.5V5a1 1 0 001 1z"
            fill="currentColor"
        />
    </svg>
);
