import React from 'react';
import { useTranslation } from 'next-i18next';

import { TELEGRAM_BOT_LINK } from '../../const/externalResources';
import { ModalHeader } from '../Modal/ModalHeader';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';

export const PaymentStatusModalErrorBlock: React.FC = () => {
    const { t } = useTranslation('payment');

    return (
        <>
            <ModalHeader>
                {t('Что-то пошло не так. Нам очень жаль. Попробуйте оплатить позже или напишите нам о проблеме.')}
            </ModalHeader>
            <ModalFooter>
                <ModalBasicButton
                    pin="square-square"
                    size="m"
                    view="primary"
                    as="a"
                    href={TELEGRAM_BOT_LINK}
                    target="_blank"
                >
                    {t('Написать в чат')}
                </ModalBasicButton>
            </ModalFooter>
        </>
    );
};
