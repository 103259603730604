import styled, { css } from 'styled-components';
import { white, button1, button2, buttonSecondary } from '@salutejs/plasma-tokens-b2c';
import { buttonGray, buttonPrimaryBrand, media } from 'const/tokens';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';

import { Link } from '../Link/Link';

import { LocalButton } from './LocalButton';

export const AdaptiveButton = styled(LocalButton)`
    height: 80px;
    border-radius: 16px;
    margin: 0;
    padding: 0 32px;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${white};
    text-decoration: none;

    ${media.tablet} {
        height: 64px;
        padding: 0 24px;
        font-size: 20px;
        line-height: 40px;
    }

    ${media.mobile} {
        height: 56px;
        padding: 0 12px;
        font-size: 16px;
        line-height: 18px;
        border-radius: 8px;
    }
`;

export const BlackAdaptiveButton = styled(AdaptiveButton)`
    background: #000;
`;

export const GrayAdaptiveButton = styled(AdaptiveButton)`
    background: ${buttonGray};
`;

export const ButtonCss = css`
    color: ${white};
    text-decoration: none;
    text-align: center;
    font-family: 'SB Sans Text', sans-serif;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.15s linear;
    box-sizing: border-box;
    margin: 0;

    &:hover {
        filter: brightness(120%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
`;

export const SmallButtonCss = css`
    ${ButtonCss};
    height: 2.5rem;
    padding: 0.5rem 1.125rem;
    border-radius: 0.75rem;
    font-size: ${button2.fontSize};
    font-style: ${button2.fontStyle};
    line-height: 1.5rem;
    font-weight: ${button2.fontWeight};
    letter-spacing: ${button2.letterSpacing};
    background: none;
`;

export const BlueSmallLink = styled(Link)`
    ${SmallButtonCss};
    background: ${buttonPrimaryBrand};
`;

export const MediumButtonCss = css`
    ${ButtonCss};
    height: 3rem;
    padding: 0.75rem 1.25rem;
    border-radius: 0.75rem;
    font-size: ${button1.fontSize};
    font-style: ${button1.fontStyle};
    line-height: ${button1.lineHeight};
    font-weight: ${button1.fontWeight};
    letter-spacing: ${button1.letterSpacing};
    background: none;
`;

export const MediumButton = styled.button`
    ${MediumButtonCss}

    ${mobileMediaQueries(css`
        &:hover {
            background-color: ${buttonSecondary};
            filter: initial;
        }

        &:active {
            background-color: ${buttonPrimaryBrand};
        }
    `)}
`;

export const GrayMediumButton = styled(MediumButton)`
    background-color: ${buttonSecondary};

    &:hover {
        background-color: ${buttonPrimaryBrand};
    }
`;

export const MediumButtonPlaceholder = styled(MediumButton)`
    visibility: hidden;
`;

export const TransparentButton = styled(MediumButton)`
    background: none;
    border: 0;
    color: ${buttonPrimaryBrand};
    height: ${button1.lineHeight};
    transition: color 0.14s linear;
    padding: 0;

    &:hover {
        color: #129dfa;
    }
`;
