import React, { SVGProps } from 'react';

export const VerticalOrientationFilled: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 15 21" {...props}>
        <path d="M4.37037 19.8206H1L1 16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 19.8206H1L1 16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 19.8206H1L1 16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 1L1 1L1 4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 1L1 1L1 4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 1L1 1L1 4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 19.8206H13.7412V16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 19.8206H13.7412V16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 19.8206H13.7412V16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 1L13.7412 1V4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 1L13.7412 1V4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 1L13.7412 1V4.41026" stroke="currentColor" strokeLinecap="round" />
        <path
            d="M2.0513 12.2343V13.0323C2.2193 13.2123 2.6513 13.4763 3.2573 13.4583C4.2413 13.4163 4.9553 12.7563 4.9553 11.2023C4.9553 9.87028 4.2893 9.16228 3.2993 9.16228C2.4953 9.16228 1.7873 9.68428 1.7873 10.6143C1.7873 11.3643 2.2913 11.9463 3.1313 11.9463C3.7073 11.9463 4.1273 11.6403 4.2713 11.1843C4.2833 11.2563 4.2893 11.3343 4.2893 11.4123C4.2773 12.2583 3.8633 12.7143 3.2453 12.7503C2.7293 12.7863 2.3093 12.5403 2.0513 12.2343ZM3.3113 11.3103C2.7833 11.3103 2.5013 11.0103 2.5013 10.5963C2.5013 10.1703 2.8133 9.85828 3.3233 9.85828C3.8033 9.85828 4.0733 10.1163 4.1933 10.5243C4.1513 11.0043 3.8033 11.3103 3.3113 11.3103ZM5.79 13.0083C5.79 13.2603 5.898 13.4703 6.258 13.4703C6.624 13.4703 6.732 13.2603 6.732 13.0083C6.732 12.7503 6.624 12.5463 6.258 12.5463C5.898 12.5463 5.79 12.7503 5.79 13.0083ZM5.79 10.7223C5.79 10.9743 5.898 11.1843 6.258 11.1843C6.624 11.1843 6.732 10.9743 6.732 10.7223C6.732 10.4643 6.624 10.2603 6.258 10.2603C5.898 10.2603 5.79 10.4643 5.79 10.7223ZM8.48948 13.4103H9.19148V9.16828H8.66948L7.58948 9.86428V10.6023L8.48948 10.0443V13.4103ZM12.942 10.3863V9.58828C12.774 9.40828 12.342 9.14428 11.736 9.16228C10.752 9.20428 10.038 9.86428 10.038 11.4183C10.038 12.7503 10.71 13.4583 11.7 13.4583C12.498 13.4583 13.212 12.9363 13.212 12.0063C13.212 11.2563 12.702 10.6743 11.862 10.6743C11.286 10.6743 10.872 10.9803 10.722 11.4363C10.71 11.3643 10.71 11.2863 10.71 11.1963C10.722 10.3563 11.13 9.90628 11.754 9.87028C12.264 9.83428 12.69 10.0803 12.942 10.3863ZM11.688 11.3103C12.21 11.3103 12.492 11.6103 12.492 12.0243C12.492 12.4443 12.186 12.7623 11.676 12.7623C11.196 12.7623 10.926 12.5043 10.8 12.1023C10.842 11.6163 11.19 11.3103 11.688 11.3103Z"
            fill="currentColor"
        />
    </svg>
);
