import { TokenObtainSliding } from '@sberdevices/vc-contracts';
import { v4 as getUuidV4 } from 'uuid';

import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';
import { analyticsLs, authStorage } from '../utils/lsStorages';
import { SberIdAuthState } from '../typings/auth';
import { routesConfig } from '../configs/routes';
import { LocalStorageKeys } from '../const/localStorage';

interface TokenObtainSliding1 extends TokenObtainSliding {
    token: string;
}

interface SberIdAuthParams {
    state: string;
    code: string;
}

// export function createSberIdAuthState(redirectUrl: string): Promise<void> {
//     const query = new URLSearchParams();
//
//     query.append('redirect_url', routesConfig.main);
//
//     return baseAxiosInstance
//         .post<SberIdAuthState>(apiConfig.sberIdState, { redirect_url: redirectUrl })
//         .then((response) => {
//             const { pathname } = window.location;
//
//             analyticsLs.set(LocalStorageKeys.SberIdPath, pathname);
//             authStorage.set(LocalStorageKeys.SberIdAuthState, response.data);
//             window.location.replace(response.data.state);
//         });
// }

export function createSberIdAuthState2(redirectUrl: string): Promise<void> {
    return baseAxiosInstance
        .get<SberIdAuthState>(apiConfig.sberIdRedirect)
        .then((response) => {
            const sberAuthUrl = response.data.url
            window.location.replace(sberAuthUrl);
        });
}

export function signInBySberIdAuthState(authState: SberIdAuthState, code: string) {
    const data: SberIdAuthParams = {
        state: authState.id,
        code,
    };

    return baseAxiosInstance.post<TokenObtainSliding1>(apiConfig.sberIdAuth, data).then((response) => response.data);
}
