import React, { SVGProps } from 'react';

export const SceneResultIcon: React.FC<SVGProps<SVGSVGElement>> = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.6453 12.9551C17.57 12.0406 17.5708 9.88562 18.1762 9.47065C18.933 8.95193 17.6471 7.83935 16.5119 8.80278C15.8707 9.34695 15.7575 9.97261 15.6663 10.4761C15.6284 10.6857 15.5943 10.8741 15.5275 11.0267C15.149 11.5455 14.8312 11.7233 14.1656 11.2486C13.4999 10.7739 11.3169 7.88654 10.3085 6.50223C10.3085 6.50223 9.67872 5.61231 8.94676 6.20518C8.31014 6.72083 8.94629 7.53972 8.94629 7.53972L11.6688 11.0253C11.694 11.1736 11.5477 11.4256 11.3057 11.2476C11.1506 11.1335 10.2773 10.0797 9.39789 9.01863C8.90478 8.42362 8.40977 7.82632 8.03842 7.39111C8.03842 7.39111 7.22178 6.2231 6.46494 6.89011C5.7081 7.55712 6.69132 8.59543 6.69132 8.59543C6.69132 8.59543 10.0344 12.0998 10.6242 12.897C10.7545 13.0731 10.5331 13.3969 10.3062 13.1749C8.73309 11.6359 6.9027 9.68908 6.9027 9.68908C6.9027 9.68908 6.17623 8.79912 5.46523 9.46618C4.91229 9.98494 5.61605 10.8749 5.61605 10.8749C5.61605 10.8749 8.65667 13.786 9.71552 14.8985C9.77598 14.962 9.75979 15.063 9.71545 15.1209C9.6711 15.1788 9.55707 15.2571 9.48844 15.1949C8.50517 14.3049 6.90158 12.8771 6.90158 12.8771C6.90158 12.8771 6.18076 12.3579 5.61535 12.8767C5.21148 13.2472 5.61498 13.9146 5.61498 13.9146C7.25373 15.5216 10.8943 18.8691 12.3468 19.4034C14.1625 20.0713 16.132 18.9145 17.1142 17.4033C18.0046 16.0332 17.8269 14.5405 17.7027 13.4974C17.6791 13.2991 17.6574 13.117 17.6453 12.9551Z"
            fill="white"
        />
    </svg>
);
