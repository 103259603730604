import React, { SVGProps } from 'react';

export const AspectSquare: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <rect
            x="3.5"
            y="20.5"
            width="17"
            height="17"
            transform="rotate(-90 3.5 20.5)"
            stroke="currentColor"
            fill="transparent"
        />
        <circle cx="3.5" cy="3.5" r="1.5" fill="currentColor" />
        <circle cx="20.5" cy="3.5" r="1.5" fill="currentColor" />
        <circle cx="20.5" cy="20.5" r="1.5" fill="currentColor" />
        <circle cx="3.5" cy="20.5" r="1.5" fill="currentColor" />
    </svg>
);
