import { Resources } from 'next-i18next';

const ServerErrorBase = {
    QueueOverflow: 'QUEUE_OVERFLOW',
    TooManyVideos: 'TOO_MANY_VIDEOS',
    TooManySlides: 'TOO_MANY_SLIDES',
    SectionTooLong: 'SECTION_TOO_LONG',
    BannedContent: 'BANNED_CONTENT',
    SpeakerNotFound: 'SPEAKER_NOT_FOUND',
    EmptySectionText: 'EMPTY_SECTION_TEXT',
    EmptySectionAudioSource: 'EMPTY_SECTION_AUDIO_SOURCE',
    FaceNotFound: 'IMAGE_CONTAINS_NO_FACE',
    ImageTooSmall: 'IMAGE_TOO_SMALL',
    WrongPdfResolution: 'WRONG_PDF_RESOLUTION',
    UnknownMimeType: 'UNKNOWN_MIME_TYPE',
    FileTooLarge: 'FILE_TOO_LARGE',
    BadFileData: 'BAD_FILE_DATA',
    FileIsEmpty: 'FILE_IS_EMPTY',
    UnsupportedFile: 'UNSUPPORTED_FILE',
    HexNotValid: 'HEX_NOT_VALID',
    ValidationError: 'VALIDATION_ERROR',
    InstanceNotFound: 'INSTANCE_NOT_FOUND',
    DurationError: 'DURATION_ERROR',
    S3LinkError: 'S3_LINK_ERROR',
    PermissionDenied: 'PERMISSION_DENIED',
    MediaOffsetError: 'MEDIA_OFFSET_ERROR',
    NotEnoughMinutesLeft: 'NOT_ENOUGH_MINUTES_LEFT',
    TooManyTtsRequests: 'TOO_MANY_TTS_REQUESTS',
    PreviewLimitExceeded: 'PREVIEW_LIMIT_EXCEEDED',
    EmptyTtsText: 'EMPTY_TTS_TEXT',
    TtsTextTooLong: 'TTS_TEXT_TOO_LONG',
    UserAlreadyHaveThisSubscription: 'USER_ALREADY_HAVE_THIS_SUBSCRIPTION',
    UnsupportedGestures: 'UNSUPPORTED_GESTURES',
};

export const ServerError = ServerErrorBase as Record<
    keyof typeof ServerErrorBase,
    keyof Resources['serverErrorMessages']
>;

export type ServerError = typeof ServerError[keyof typeof ServerError];

export interface ServerErrorResponse {
    message: string;
    error_type: string;
    status_code: number;
    details: string;
}
