import React from 'react';
import { useTranslation } from 'next-i18next';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';

interface AlertBlockProps {
    onConfirm(): void;
    onCancel(): void;
}

export const AlertBlock: React.FC<AlertBlockProps> = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation('payment');

    return (
        <>
            <ModalHeader>
                {t('Вы уверены? На всех видео появится логотип. В месяц будет доступно всего 2 минуты видео')}
            </ModalHeader>
            <ModalFooter>
                <ModalBasicButton pin="square-square" size="m" view="primary" onClick={onCancel}>
                    {t('Я передумал')}
                </ModalBasicButton>
                <ModalBasicButton pin="square-square" size="m" view="secondary" onClick={onConfirm}>
                    {t('Перейти')}
                </ModalBasicButton>
            </ModalFooter>
        </>
    );
};
