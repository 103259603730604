/* istanbul ignore file */
import { WORKFLOW_NAME } from '../const/environment';

import { appConfig } from './appConfig';

export const routesConfig = {
    landing: '/',
    login: '/login',
    main: '/main',
    assemblyTable: '/create-video/[sceneId]/assembly-table',
    gameDevLanding: '/developers',
    gameDevGeneration: '/developers/generation',
    gameDevPlugins: '/developers/plugins',
    gameDevRigTutorial: '/developers/rig-tutorial',
    gameDevBlendshapes: '/developers/blendshapes',
    gameDevTutorial: '/developers/game-dev-tutorial',
    scenePDFUpload: '/scene-pdf-upload',
    activateAccount: '/activation/[uid]/[token]',
    resetPasswordRequest: '/reset-password-request',
    resetPassword: '/reset_password/[uid]/[token]',
    landingRegistration: '/#landingSignup',
    checkEmail: '/check-email',
    profile: '/profile',
    sharedVideo: '/public-video/[id]',
    sharedVideoSalute: '/public-video/salute/[id]',
    rates: '/tariffs',
    ordersHistory: '/orders-history',
    registration: '/registration',
    unsubscribe: '/unsubscribe/[token]',
};

export enum RouteAuthRequirement {
    Require,
    Unauthorized,
    Free,
}

interface RouteInfo {
    auth: RouteAuthRequirement;
}

export const routesInfo: Record<string, RouteInfo> = {
    [routesConfig.landing]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.login]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.main]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.assemblyTable]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.gameDevLanding]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.gameDevGeneration]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.gameDevPlugins]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.gameDevRigTutorial]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.gameDevBlendshapes]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.gameDevTutorial]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.scenePDFUpload]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.activateAccount]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.resetPasswordRequest]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.resetPassword]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.landingRegistration]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.checkEmail]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.rates]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.sharedVideo]: {
        auth: RouteAuthRequirement.Free,
    },
    [routesConfig.sharedVideoSalute]: {
        auth: RouteAuthRequirement.Free,
    },
    [routesConfig.registration]: {
        auth: RouteAuthRequirement.Unauthorized,
    },
    [routesConfig.profile]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.ordersHistory]: {
        auth: RouteAuthRequirement.Require,
    },
    [routesConfig.unsubscribe]: {
        auth: RouteAuthRequirement.Free,
    },
};

export function getHost(): string {
    const env = process.env.WORKFLOW_NAME as WORKFLOW_NAME;

    return (
        {
            [WORKFLOW_NAME.Pr]: appConfig.stageHost,
            [WORKFLOW_NAME.Dev]: appConfig.stageHost,
            [WORKFLOW_NAME.Release]: appConfig.prodHost,
            [WORKFLOW_NAME.Local]: appConfig.devHost,
        }[env] || appConfig.devHost
    );
}

export function getShareLink(documentId: number | string): string {
    return `${getHost()}${routesConfig.sharedVideo.replace('[id]', documentId.toString())}`;
}
