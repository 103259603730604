import { apiConfig } from 'configs/api';
import { PaginatedData } from 'typings/paginatedData';
import { Subscription, SubscriptionLabel, SubscriptionType } from 'typings/subscription';
import { Currency } from 'typings/currency';
import { Product } from 'typings/product';

import { baseAxiosInstance } from '../utils/interceptor';
import { Locale } from '../typings/locale';

interface MappedSubscriptionColor extends SubscriptionLabel {
    color: string;
}

export interface PricePerMinuteType {
    price: number;
    usdPrice: number;
}

export interface MappedSubscription extends Subscription {
    description: string;
    currency: Currency;
    priority: number;
    usdPrice: string;
    oldPrice: string;
    options: Product[];
    label: MappedSubscriptionColor | null;
    isPersonal: boolean;
    video?: string | null;
    videoFallback?: string | null;
    is_default: boolean;
    parent: string | null;
    promoPrice: string | null;
    promoOptions: Product[] | null;
    pricePerMinute: PricePerMinuteType | null;
    oldPricePerMinute: PricePerMinuteType | null;
}

function getPricePerMinute(subscription: Subscription): PricePerMinuteType | null {
    return subscription.product_variants.reduce((prev: PricePerMinuteType | null, curr) => {
        const minutesCount = curr.product.find((p) => p.name === 'VIDEO_DURATION_QUOTA')?.count;

        if (!minutesCount) {
            return prev;
        }
        const price = Number(curr.price) / minutesCount;

        if (prev && prev.price < price) {
            return prev;
        }

        return {
            price,
            usdPrice: +(Number(curr.price_usd) / minutesCount).toFixed(2),
        };
    }, null);
}

export function mapSubscriptionData(subscription: Subscription): MappedSubscription {
    return {
        ...subscription,
        price: Number(subscription.price).toString(),
        usdPrice: subscription.price_usd && Number(subscription.price_usd).toString(),
        oldPrice: subscription.old_price && Number(subscription.old_price).toString(),
        options: subscription.product_variants
            .sort((a, b) => (Number(a.price) > Number(b.price) ? 1 : -1))
            .map((i) => ({ ...i, price: Number(i.price).toString() })),
        label: subscription.label
            ? {
                  ...subscription.label,
                  color: subscription.label.hex_color ? `#${subscription.label.hex_color}` : '',
              }
            : null,
        isPersonal: subscription.for_personal,
        video: subscription.video_icon?.file,
        videoFallback: subscription.video_icon?.image,
        parent: subscription.parent ?? null,
        promoPrice: subscription.promoPrice,
        promoOptions: subscription.promoOptions ?? null,
        pricePerMinute:
            subscription.subscription_type === SubscriptionType.Minutes ? getPricePerMinute(subscription) : null,
        oldPricePerMinute: null,
    };
}

function mapSubscriptionsData(data: PaginatedData<Subscription>): MappedSubscription[] | undefined {
    if (!data.results) return;

    return data.results.map(mapSubscriptionData);
}

export type SubscriptionWithPromocode = MappedSubscription & { promocode?: string };

export async function getSubscriptions(locale?: Locale): Promise<MappedSubscription[] | undefined> {
    const response = await baseAxiosInstance.get(`${apiConfig.subscriptions}${locale ? `?language=${locale}` : ''}`);

    return mapSubscriptionsData(response.data);
}

export function getSubscriptionWithPromocode(subscriptionId: string, promocode: string): Promise<MappedSubscription> {
    return baseAxiosInstance
        .get(apiConfig.subscriptionWithPromocode.replace('{id}', subscriptionId).replace('{promocode}', promocode))
        .then(({ data }) => mapSubscriptionData(data));
}
