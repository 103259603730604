import React, { SVGProps } from 'react';

export const InfoFill: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 16" fill="transparent" {...props}>
        <circle cx="8" cy="8" r="8" fill="currentColor" fillOpacity="0.28" />
        <path
            d="M8.51648 6.0408L8.50916 10.5304L9 10.6414V11H7V10.6414L7.49084 10.5304L7.49817 6.62998L7 6.51898V6.17742L8.44322 5.92125L8.51648 6.0408ZM7.38095 4.63188C7.38095 4.44972 7.43223 4.29886 7.5348 4.17932C7.64225 4.05977 7.78877 4 7.97436 4C8.16483 4 8.31136 4.05977 8.41392 4.17932C8.52137 4.29886 8.57509 4.44972 8.57509 4.63188C8.57509 4.81973 8.52137 4.97343 8.41392 5.09298C8.31136 5.21252 8.16483 5.2723 7.97436 5.2723C7.78877 5.2723 7.64225 5.21252 7.5348 5.09298C7.43223 4.97343 7.38095 4.81973 7.38095 4.63188Z"
            fill="currentColor"
            fillOpacity="0.56"
        />
    </svg>
);
