import React, { SVGProps } from 'react';

export const Profile: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 18 18" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM11.7865 7.34521C11.7865 8.83638 10.5777 10.0452 9.0865 10.0452C7.59533 10.0452 6.3865 8.83638 6.3865 7.34521C6.3865 5.85405 7.59533 4.64522 9.0865 4.64522C10.5777 4.64522 11.7865 5.85405 11.7865 7.34521ZM8.99971 15.6776C13.2747 15.6776 14.3997 13.0502 14.3997 13.0502C14.3997 13.0502 12.8247 10.8002 8.99971 10.8002C4.72471 10.8002 3.59971 13.0502 3.59971 13.0502C3.59971 13.0502 4.72471 15.6776 8.99971 15.6776Z"
            fill="white"
        />
    </svg>
);
