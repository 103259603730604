import { canUseDOM } from './canUseDom';

// TODO: сжечь
export class LocalStorageService {
    private storageKey: string;

    constructor(storageKey: string) {
        this.storageKey = storageKey;
    }

    getAllData<T = unknown>(): Record<string, T> {
        if (!canUseDOM()) return {};

        const strokedData = window.localStorage[this.storageKey];

        try {
            return JSON.parse(strokedData);
        } catch (_) {
            return {};
        }
    }

    get<Type>(name: string): Type {
        return this.getAllData<Type>()[name];
    }

    set(name: string, value: any): void {
        if (!canUseDOM()) return;

        const data = this.getAllData();
        data[name] = value;
        window.localStorage.setItem(this.storageKey, JSON.stringify(data));
    }

    delete(name: string): void {
        if (!canUseDOM()) return;
        const data = this.getAllData();
        delete data[name];
        window.localStorage.setItem(this.storageKey, JSON.stringify(data));
    }

    clear(): void {
        if (!canUseDOM()) return;
        delete window.localStorage[this.storageKey];
    }
}
