import React, { SVGProps } from 'react';

export const TemplateSpeakerInWindow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 68 52" {...props}>
        <path
            d="M12.5413 24.5993L17.2663 19.9577C17.6553 19.5755 18.2788 19.5755 18.6679 19.9577L29.4241 30.5243C30.0055 31.0954 30.9372 31.0954 31.5186 30.5243C32.1147 29.9386 32.1147 28.9779 31.5186 28.3922L28.3664 25.2957C27.9675 24.9037 27.9675 24.2608 28.3664 23.8689L29.3319 22.9205C29.7209 22.5383 30.3444 22.5383 30.7335 22.9205L35.4584 27.5621C36.0398 28.1332 36.9715 28.1332 37.5529 27.5621C38.149 26.9764 38.149 26.0157 37.5529 25.43L31.632 19.6135C30.7487 18.7458 29.3167 18.7458 28.4334 19.6135L26.2086 21.7991C25.8196 22.1812 25.1961 22.1812 24.8071 21.7991L19.5664 16.6508C18.6831 15.7831 17.251 15.7831 16.3678 16.6508L10.4469 22.4672C9.85071 23.0529 9.85071 24.0136 10.4469 24.5993C11.0282 25.1704 11.96 25.1704 12.5413 24.5993Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            d="M60 36C60 40.4183 56.4183 44 52 44C47.5817 44 44 40.4183 44 36C44 31.5817 47.5817 28 52 28C56.4183 28 60 31.5817 60 36ZM54.3595 33.7144C54.3595 34.9768 53.3362 36.0001 52.0738 36.0001C50.8114 36.0001 49.7881 34.9768 49.7881 33.7144C49.7881 32.4521 50.8114 31.4287 52.0738 31.4287C53.3362 31.4287 54.3595 32.4521 54.3595 33.7144ZM52.0001 41.272C55.6192 41.272 56.5716 39.0478 56.5716 39.0478C56.5716 39.0478 55.2382 37.143 52.0001 37.143C48.3811 37.143 47.4287 39.0478 47.4287 39.0478C47.4287 39.0478 48.3811 41.272 52.0001 41.272Z"
            fill="currentColor"
        />
    </svg>
);
