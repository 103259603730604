import React, { SVGProps } from 'react';

export const TemplateContentOnBackground: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 68 52" {...props}>
        <path
            d="M48.914 13C46.2769 13 44.1391 15.1378 44.1391 17.7748C44.1391 20.4119 46.2769 22.5497 48.914 22.5497H49.0841C51.7212 22.5497 53.859 20.4119 53.859 17.7748C53.859 15.1378 51.7212 13 49.0841 13H48.914ZM43.7276 24.7328C42.5076 24.7328 41.4091 25.4717 40.949 26.6016L38.2231 33.2972C37.5114 35.0451 38.5713 37.0082 40.4232 37.3721L48.4213 38.9437C48.8033 39.0188 49.1962 39.0188 49.5782 38.9437L57.5763 37.3721C59.4281 37.0082 60.488 35.0451 59.7764 33.2972L57.0504 26.6016C56.5904 25.4717 55.4919 24.7328 54.2719 24.7328H43.7276Z"
            fill="currentColor"
        />
        <path
            d="M12.5413 24.5993L17.2663 19.9577C17.6553 19.5755 18.2788 19.5755 18.6679 19.9577L29.4241 30.5243C30.0055 31.0954 30.9372 31.0954 31.5186 30.5243C32.1147 29.9386 32.1147 28.9779 31.5186 28.3922L28.3664 25.2957C27.9675 24.9037 27.9675 24.2608 28.3664 23.8689L29.3319 22.9205C29.7209 22.5383 30.3444 22.5383 30.7335 22.9205L35.4584 27.5621C36.0398 28.1332 36.9715 28.1332 37.5529 27.5621C38.149 26.9764 38.149 26.0157 37.5529 25.43L31.632 19.6135C30.7487 18.7458 29.3167 18.7458 28.4334 19.6135L26.2086 21.7991C25.8196 22.1812 25.1961 22.1812 24.8071 21.7991L19.5664 16.6508C18.6831 15.7831 17.251 15.7831 16.3678 16.6508L10.4469 22.4672C9.85071 23.0529 9.85071 24.0136 10.4469 24.5993C11.0282 25.1704 11.96 25.1704 12.5413 24.5993Z"
            fill="currentColor"
        />
    </svg>
);
