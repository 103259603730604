import React, { SVGProps } from 'react';

export const Link: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50225 1.7367e-08L6.50244 1.68004e-08C6.77858 1.68004e-08 7.00244 0.223858 7.00244 0.5V5.62127C7.00244 5.89741 6.77858 6.12127 6.50244 6.12127C6.2263 6.12127 6.00244 5.89741 6.00244 5.62127V1.70646L1.35349 6.35362C1.15819 6.54885 0.841603 6.54879 0.646378 6.35349C0.451154 6.15819 0.451215 5.8416 0.646515 5.64638L5.29469 1H1.3786C1.10246 1 0.878603 0.776142 0.878603 0.5C0.878603 0.223858 1.10246 1.68004e-08 1.3786 1.68004e-08L6.50199 1.7367e-08C6.50208 -5.78899e-09 6.50216 -5.78899e-09 6.50225 1.7367e-08Z"
            fill="white"
        />
    </svg>
);
