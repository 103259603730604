import React, { SVGProps, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { iconSet } from './iconSet';

export type IconsSetKey = keyof typeof iconSet;

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
    icon: IconsSetKey;
    height?: number | string;
    width?: number | string;
    color?: string;
    gradient?: React.FC<{ id: string }>;
}

interface StyledRootProps {
    gradientId?: string;
}

const StyledRoot = styled.span<StyledRootProps>`
    display: inline-block;
    box-sizing: border-box;

    ${({ gradientId }) =>
        gradientId &&
        css`
            svg {
                path,
                rect {
                    fill: url(#${gradientId}) !important;
                }
            }
        `}

    & svg {
        display: block;
    }
`;

export const Icon: React.FC<IconProps> = ({ icon, height = 24, width = 24, color, gradient, ...props }) => {
    const IconComponent: React.FC<SVGProps<SVGSVGElement>> = iconSet[icon];
    const GradientComponent = gradient;
    const gradientId = useMemo(() => Math.random().toString(), []);

    if (!IconComponent) {
        return null;
    }

    return (
        <StyledRoot
            style={{ width, height }}
            gradientId={gradient ? gradientId : undefined}
            data-e2e-icon={icon}
            {...props}
        >
            <IconComponent height={height} width={width} color={color} />
            {GradientComponent && (
                <svg width="0" height="0">
                    <defs>
                        <GradientComponent id={gradientId} />
                    </defs>
                </svg>
            )}
        </StyledRoot>
    );
};
