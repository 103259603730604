import React, {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import { Button, Col, Container, Headline2, mediaQuery, Row, TextArea, TextField } from '@salutejs/plasma-b2c';
import styled, { css } from 'styled-components';
import { spacing } from '@salutejs/plasma-core';
import { surfaceLiquid02 } from '@salutejs/plasma-tokens-b2c';
import { fontWeights } from '@salutejs/plasma-tokens-b2c/typographyValues';
import { useTranslation } from 'next-i18next';
import { useToast } from 'hooks/useToast';
import { ResponseStates, sendForm } from 'api/feedBackForm';
import { UserContext } from 'store/UserContext';
import { Device, LayoutContext } from 'store/LayoutContext';
import { isIOS } from 'utils/platforms';
import { fetchAnalytics } from 'utils/fetchAnalytics';
import { RecaptchaV2 } from 'components/RecaptchaV2/RecaptchaV2';
import { recaptchaExecute, recaptchaInit, RecaptchaRequestData, recaptchaReset } from 'utils/recaptcha';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';

import {
    emailValidation,
    FeedbackFormData,
    howKnowAboutUsValidation,
    phoneValidation,
    submitValidation,
} from './utils';

const RECAPTCHA_ID = 'g-recaptcha-feedback-form';

interface FeedBackFormProps {
    setSuccess: Dispatch<SetStateAction<boolean>>;
    closeModal: () => void;
}

// TODO убрать ошибку когда плазма починит

const StyledTextField = styled(TextField)`
    margin-bottom: ${spacing['8x']};

    input {
        &:hover {
            background: ${surfaceLiquid02};
        }
    }
`;

const StyledContainer = styled(Container)`
    ${mediaQuery('XL')(css`
        padding: 0;
    `)}
    ${mediaQuery('XXL')(css`
        padding: 0;
    `)}

    padding-bottom: ${spacing['32']};
`;

const fieldWithMarginHeight = 64;
const headerHeight = 40;

// TODO убрать шрифты когда плазма починит
const StyledTextArea = styled(TextArea)`
    margin-bottom: ${spacing['8x']};
    resize: vertical;
    overflow: hidden;
    min-height: 100px;
    height: 200px;
    max-height: calc(100vh - (${fieldWithMarginHeight}px * 7) - ${headerHeight}px);

    & > div {
        height: 100%;
        position: initial;
    }

    & textarea {
        font-size: 14px;
        padding: 14px 16px;
        resize: none;
        height: 100%;

        &::placeholder {
            opacity: 0.5;
        }
    }
`;

const StyledHeadLine = styled(Headline2)`
    margin-bottom: ${spacing['12x']};
    font-weight: ${fontWeights.Semibold};
`;

const StyledModalBody = styled(ModalBody)<{ textareaFocused: boolean }>`
    height: 105%;
`;

export const FeedBackForm: FC<FeedBackFormProps> = ({ setSuccess, closeModal }) => {
    const { user } = useContext(UserContext);
    const { showToast } = useToast();
    const { t } = useTranslation('feedbackForm');
    const { device } = useContext(LayoutContext);
    const [textareaFocused, changeFocus] = useState(false);
    const [widgetId, setWidgetId] = useState<number>();
    const [feedBackFields, changeFeedBackFields] = useState<FeedbackFormData>({
        name: null,
        email: user?.email ?? null,
        phone: null,
        company_name: null,
        position: null,
        comment: null,
        how_know_about_us: null,
    });
    const requestData = useRef<RecaptchaRequestData<FeedbackFormData>>({ ...feedBackFields, captcha: '' });
    const [emailBlurred, blurEmail] = useReducer(() => true, false);
    const [phoneBlurred, blurPhone] = useReducer(() => true, false);
    const [howKnowAboutUsBlurred, blurHowKnowAboutUs] = useReducer(() => true, false);

    const submitModal = useCallback(async (): Promise<void> => {
        const res = await sendForm({ ...feedBackFields, ...requestData.current });

        fetchAnalytics().then((analyticsInstance) => {
            analyticsInstance.click({
                eventAction: 'Billing:BusinessFormClick',
                eventCategory: 'billing',
            });
        });

        if (res.status === ResponseStates.Success) {
            setSuccess(true);
        } else {
            if (widgetId !== undefined && !user) {
                recaptchaReset(widgetId);
            }

            closeModal();
            showToast.error(t('Слишком много запросов'));
        }
    }, [feedBackFields, setSuccess, widgetId, user, closeModal, showToast, t]);

    const changeHandle = useCallback((data: Partial<FeedbackFormData>): void => {
        changeFeedBackFields((currentData) => ({ ...currentData, ...data }));
    }, []);

    const focusHandle = useCallback(
        (event: string) => {
            if (device === Device.Desktop || isIOS()) {
                return;
            }

            if (event === 'focus') {
                changeFocus(true);
            }

            if (event === 'blur') {
                changeFocus(false);
            }
        },
        [device],
    );

    const handleRecaptchaVerify = useCallback(
        (token: string) => {
            requestData.current.captcha = token;
            submitModal();
        },
        [submitModal],
    );

    const handleSubmitClick = useCallback(() => {
        requestData.current = {
            ...requestData.current,
            ...feedBackFields,
        };

        if (user) {
            return submitModal();
        }

        if (!widgetId) {
            return;
        }

        recaptchaExecute(widgetId);
    }, [feedBackFields, submitModal, user, widgetId]);

    useEffect(() => {
        if (widgetId === undefined && !user) {
            recaptchaInit(RECAPTCHA_ID, handleRecaptchaVerify).then(setWidgetId);
        }
    }, [handleRecaptchaVerify, user, widgetId]);

    return (
        <StyledModalBody textareaFocused={textareaFocused}>
            <ModalHeader>
                <StyledHeadLine>{t('Напишите нам')}</StyledHeadLine>
            </ModalHeader>
            {!user && <RecaptchaV2 recaptchaId={RECAPTCHA_ID} />}
            <StyledContainer>
                <Row>
                    <Col sizeS={4} sizeM={6} sizeL={4} offsetL={2} sizeXL={12} sizeXXL={16}>
                        <StyledTextField
                            autoFocus
                            onChange={(e) => changeHandle({ name: e.target.value })}
                            placeholder={t('Имя и фамилия')}
                            label={t('Имя и фамилия') as string}
                            data-e2e="nameField"
                        />

                        <StyledTextField
                            onBlur={blurEmail}
                            onChange={(e) => changeHandle({ email: e.target.value })}
                            defaultValue={feedBackFields.email || ''}
                            placeholder={t('Электронная почта (обязательно)')}
                            label={t('Электронная почта (обязательно)') as string}
                            status={emailBlurred && !emailValidation(feedBackFields.email) ? 'error' : undefined}
                            data-e2e="emailField"
                        />
                        <StyledTextField
                            onBlur={blurHowKnowAboutUs}
                            onChange={(e) => changeHandle({ how_know_about_us: e.target.value })}
                            placeholder={t('Как вы про нас узнали? (обязательно)')}
                            label={t('Как вы про нас узнали? (обязательно)') as string}
                            status={
                                howKnowAboutUsBlurred && !howKnowAboutUsValidation(feedBackFields.how_know_about_us)
                                    ? 'error'
                                    : undefined
                            }
                            data-e2e="howKnowAboutUs"
                        />

                        <StyledTextField
                            onBlur={blurPhone}
                            onChange={(e) => changeHandle({ phone: e.target.value })}
                            placeholder={t('Номер телефона')}
                            label={t('Номер телефона') as string}
                            status={phoneBlurred && !phoneValidation(feedBackFields.phone) ? 'error' : undefined}
                            data-e2e="phoneField"
                        />
                    </Col>

                    <Col sizeM={6} sizeL={2} offsetL={2} sizeXXL={8} sizeXL={6} sizeS={4}>
                        <StyledTextField
                            onChange={(e) => changeHandle({ company_name: e.target.value })}
                            placeholder={t('Компания')}
                            label={t('Компания') as string}
                            data-e2e="companyField"
                        />
                    </Col>

                    <Col sizeM={6} sizeL={2} sizeXXL={8} sizeXL={6} sizeS={4}>
                        <StyledTextField
                            onChange={(e) => changeHandle({ position: e.target.value })}
                            placeholder={t('Роль в компании')}
                            label={t('Роль в компании') as string}
                            data-e2e="roleField"
                        />
                    </Col>

                    <Col sizeS={4} sizeM={6} sizeL={4} offsetL={2} sizeXL={12} sizeXXL={16}>
                        <StyledTextArea
                            onChange={(e) => changeHandle({ comment: e.target.value })}
                            placeholder={t('Комментарий')}
                            data-e2e="commentField"
                            onFocus={() => focusHandle('focus')}
                            onBlur={() => focusHandle('blur')}
                        />

                        <Button
                            stretch
                            pin="square-square"
                            size="m"
                            onClick={handleSubmitClick}
                            disabled={
                                !submitValidation(
                                    feedBackFields.email,
                                    feedBackFields.phone,
                                    feedBackFields.how_know_about_us,
                                )
                            }
                            text={t('Написать')}
                            view="primary"
                            data-e2e="feedbackSubmit"
                        />
                    </Col>
                </Row>
            </StyledContainer>
        </StyledModalBody>
    );
};
