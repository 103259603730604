import { appConfig } from 'configs/appConfig';
import { AxiosRequestConfig } from 'axios';

import { baseAxiosInstance } from '../utils/interceptor';

export interface PoolingState {
    isRunning: boolean;
    stop(): void;
}

export function pooling<ResultType, ErrorType extends Error = Error>(
    path: string,
    config: AxiosRequestConfig,
    frame: (progressData: ResultType) => void | false | Promise<void | false>,
    resolve: (result: ResultType) => void = () => {},
    reject: (error: ErrorType) => void = () => {},
    time: number = appConfig.poolingTimeout,
    poolingState?: PoolingState,
): PoolingState {
    const state: PoolingState = poolingState || {
        isRunning: true,
        stop: () => {
            state.isRunning = false;
        },
    };

    baseAxiosInstance
        .get<ResultType>(path, config)
        .then(async (response) => {
            if (!state.isRunning) {
                return;
            }

            const result = response.data;
            const frameResult = await frame(result);

            if (frameResult === false) {
                resolve(result);
            } else {
                setTimeout(() => state.isRunning && pooling(path, config, frame, resolve, reject, time, state), time);
            }
        })
        .catch(reject);

    return state;
}

// @ts-ignore
export const errorOnEmpty = (response) => response;
