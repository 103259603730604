import { apiConfig } from 'configs/api';

import { baseAxiosInstance } from '../utils/interceptor';
import { Invoice } from '../typings/invoice';

export async function paySubscription(subscriptionId: number): Promise<Invoice> {
    const response = await baseAxiosInstance.post<Invoice>(apiConfig.shopPaySberpayPaySubscriptionCreate, {
        user_subscription_id: subscriptionId,
        return_url: window.location.href,
    });

    return response.data;
}
