import React, { SVGProps } from 'react';

export const Facebook: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 10 20" {...props}>
        <path
            d="M6.49139 20V10.8777H9.43855L9.88071 7.32156H6.49139V5.05147C6.49139 4.0222 6.76548 3.32076 8.18828 3.32076L10 3.31999V0.13923C9.68669 0.0969453 8.6112 0 7.35945 0C4.74559 0 2.95609 1.65697 2.95609 4.69927V7.32156H0V10.8777H2.95609V20H6.49139Z"
            fill="currentColor"
        />
    </svg>
);
