import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';

import { SubscriptionCurrent } from '../../typings/subscription';
import { withCdnUrl } from '../../utils/static';
import { mobileMediaQueries } from '../../utils/mobileMediaQueries';
import { ModalHeader } from '../Modal/ModalHeader';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';

const StyledImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 450px;

    ${mobileMediaQueries(css`
        top: 125px;
        left: 50%;
    `)}
`;

interface PaymentStatusModalSuccessBlockProps {
    currentSubscription?: SubscriptionCurrent;
    onClose(): void;
}

export const PaymentStatusModalSuccessBlock: React.FC<PaymentStatusModalSuccessBlockProps> = ({
    currentSubscription,
    onClose,
}) => {
    const { t } = useTranslation('payment');

    return currentSubscription ? (
        <>
            <ModalHeader>
                <StyledImage src={withCdnUrl('images/successPayment.gif')} alt="success payment" />
                {t('Оплата прошла успешно, спасибо!')}
            </ModalHeader>
            <ModalFooter>
                <ModalBasicButton pin="square-square" size="m" view="primary" onClick={onClose}>
                    {t('Понятно')}
                </ModalBasicButton>
            </ModalFooter>
        </>
    ) : null;
};
