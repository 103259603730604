export const TELEGRAM_GROUP_LINK = 'https://t.me/visper_support';
export const TELEGRAM_GROUP_LINK_EN = 'https://t.me/joinchat/QimY2ZVAqIU0ZDMy';
export const BUSINESS_EMAIL = 'mailto:visper@sberdevices.ru';
export const SBERMARKET_LINK =
    'https://developers.sber.ru/portal/tools?utm_campaign=smark_smartmarket_visper-main-page_&utm_source=visper&utm_medium=owned';
export const TELEGRAM_SUPPORT_RU = 'https://t.me/DigitalAvatarsBot';
export const TELEGRAM_SUPPORT_EN = 'https://t.me/vispertech/3';
export const RATES_DESCRIPTION_RU = 'https://visper.tech/constant/rates_desc.pdf';
export const RATES_DESCRIPTION_EN = 'https://visper.tech/constant/rates_desc_en.pdf';
export const TELEGRAM_BOT_LINK = 'https://t.me/visper_support_bot';
