import React, { SVGProps } from 'react';

export const FaceSwap: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 837.393 837.393" {...props}>
        <path
            d="M587.189,42.891c-251.765,0-250.069,49.777-250.069,49.777v138.337c46.761-4.182,78.887-11.315,99.643-17.66c30.896-9.449,43.254-18.961,45.434-20.792c4.639-5.68,11.164-8.57,18.082-8.57c2.881,0,5.816,0.493,8.723,1.499c10.033,3.473,16.145,13.631,16.145,24.238v199.995c19.525-9.69,40.525-14.617,63.166-14.689c6.012,0.287,12.48,0.682,21.482,1.856c8.049,1.553,15.299,3.007,22.793,5.636c14.734,5.142,29.074,12.438,41.898,22.846c23.062,18.236,43.092,46.461,47.125,79.766c0.664,5.482-3.572,8.666-8.912,7.26c-25.621-6.744-45.219-8.074-64.453-9.537l-30.977-1.365l-31.209-0.475c-17.832,0.188-39.225,0.295-59.973,1.65c-0.312,0.018-0.637,0.053-0.941,0.072c-0.09,55.205-12.475,107.135-34.154,152.363c29.631,14.457,62.125,22.469,96.242,22.469c138.158,0,250.158-130.961,250.158-292.441V92.667C837.391,92.667,838.936,42.891,587.189,42.891z M752.863,314.409c-30.062,23.151-73.361,20.952-100.91-6.596c-27.547-27.548-29.746-70.846-6.596-100.909c3.369-4.376,9.762-4.34,13.666-0.434l94.271,94.272C757.203,304.647,757.238,311.04,752.863,314.409z"
            fill="currentColor"
        />
        <path
            d="M500.262,209.719c0,0-45.561,49.786-250.113,49.786C45.613,259.505,0,209.719,0,209.719V502.16c0,161.488,112.009,292.342,250.122,292.342c138.149,0,250.14-130.852,250.14-292.342V209.719L500.262,209.719z M79.226,472.904c-5.523,0-10.069-4.496-9.357-9.971c4.887-37.627,37.059-66.69,76.018-66.69s71.13,29.063,76.018,66.69c0.711,5.477-3.834,9.971-9.357,9.971H79.226z M384.464,571.812c-4.08,33.207-24.146,61.348-47.154,79.66c-26.293,20.334-55.646,30.25-88.373,30.34c-6.03-0.279-12.438-0.574-21.492-1.84c-8.058-1.562-15.309-2.908-22.802-5.646c-14.726-5.15-29.057-12.445-41.853-22.748c-23.04-18.227-43.111-46.533-47.157-79.846c-0.666-5.482,3.567-8.666,8.908-7.258c25.631,6.76,45.273,8.143,64.515,9.518l30.968,1.365l31.165,0.494c17.822-0.09,39.224-0.189,60.024-1.652c19.242-1.391,38.85-2.783,64.364-9.605C380.914,563.168,385.138,566.332,384.464,571.812z M421.035,472.904H287.714c-5.522,0-10.068-4.496-9.356-9.971c4.888-37.627,37.059-66.69,76.018-66.69s71.13,29.063,76.019,66.69C431.105,468.408,426.559,472.904,421.035,472.904z"
            fill="currentColor"
        />
    </svg>
);
