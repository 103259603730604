import React, { SVGProps } from 'react';

export const Duplicate: React.FC<SVGProps<SVGSVGElement>> = () => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 3C4 1.34315 5.34315 0 7 0H17C18.6569 0 20 1.34315 20 3V9C20 10.6569 18.6569 12 17 12H7C5.34315 12 4 10.6569 4 9V3Z"
            fill="white"
        />
        <path
            d="M2 4H1C0.447715 4 0 4.44772 0 5V9.5C0 13.0899 2.91015 16 6.5 16H14C14.5523 16 15 15.5523 15 15V14H6.5C4.01472 14 2 11.9853 2 9.5V4Z"
            fill="white"
        />
    </svg>
);
