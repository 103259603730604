import { appConfig } from 'configs/appConfig';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'typings/auth';

export function hasTokenExpired(token: string): boolean {
    const decodedToken: DecodedToken = jwtDecode(token);
    const numberOfMilliseconds = 1e3;
    const expireDate = decodedToken.exp * numberOfMilliseconds - appConfig.tokenRefreshSafeTime;

    return expireDate < Date.now();
}
