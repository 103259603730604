import React, { SVGProps } from 'react';
import styled, { keyframes } from 'styled-components';

const loadingKeyframe = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const StyledContainer = styled.svg`
    animation: ${loadingKeyframe} 1.8s linear infinite;
`;

// svg элемент SC предусматривает свой ref аттрибут, контракт которого не совпадает с реактовским DetailedHTMLProps
export const Loading: React.FC<SVGProps<SVGSVGElement>> = ({ ref, ...props }) => (
    <StyledContainer viewBox="0 0 497 497" {...props}>
        <circle cx="263" cy="62" fill="currentColor" r="62" />
        <ellipse cx="117.092" cy="114.794" fill="currentColor" rx="58.801" ry="57.397" opacity="0.9" />
        <ellipse cx="56.245" cy="244.754" fill="currentColor" rx="56.245" ry="54.874" opacity="0.8" />
        <circle cx="98" cy="376" r="53" fill="currentColor" opacity="0.7" />
        <ellipse cx="217.821" cy="447.175" fill="currentColor" rx="51.132" ry="49.825" opacity="0.6" />
        <ellipse cx="349.229" cy="427.873" fill="currentColor" rx="48.575" ry="47.297" opacity="0.5" />
        <circle cx="439" cy="336" r="46" fill="currentColor" opacity="0.4" />
        <ellipse cx="453.538" cy="216.477" fill="currentColor" rx="43.462" ry="42.656" opacity="0.3" />
        <circle cx="397" cy="112" r="38" fill="currentColor" opacity="0.2" />
    </StyledContainer>
);
