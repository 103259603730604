import React, { SVGProps } from 'react';

export const Save: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g clipPath="url(#clip0_24566_48)">
            <path
                d="M20.121 5.26346L18.7365 3.87904C18.174 3.31572 17.4113 3 16.6155 3H5.06255C3.92326 3 3 3.92326 3 5.06255V18.9375C3 20.0767 3.92326 21 5.06255 21H18.9375C20.0767 21 21 20.0767 21 18.9375V7.38451C21 6.58868 20.6843 5.82596 20.121 5.26346V5.26346ZM5.99995 7.68745V5.8125C5.99995 5.502 6.25195 5.25 6.56245 5.25H14.4375C14.748 5.25 15 5.502 15 5.8125V7.68745C15 7.99796 14.748 8.24995 14.4375 8.24995H6.56245C6.25195 8.24995 5.99995 7.99796 5.99995 7.68745ZM12 18C10.1363 18 8.625 16.4887 8.625 14.625C8.625 12.7612 10.1363 11.25 12 11.25C13.8637 11.25 15.375 12.7612 15.375 14.625C15.375 16.4887 13.8637 18 12 18Z"
                fill="currentColor"
                fillOpacity="0.96"
            />
        </g>
        <defs>
            <clipPath id="clip0_24566_48">
                <rect width="18" height="18" fill="currentColor" transform="translate(3 3)" />
            </clipPath>
        </defs>
    </svg>
);
