import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '@salutejs/plasma-b2c';
import ReactPlayer from 'react-player';
import { useTranslation } from 'next-i18next';

import { withCdnUrl } from '../../utils/static';
import { mobileMediaQueries } from '../../utils/mobileMediaQueries';
import { ModalHeader } from '../Modal/ModalHeader';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalBasicButton } from '../Modal/ModalBasicButton';

interface FeedbackSuccessProps {
    closeModal: () => void;
}

const StyledVideoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);

    ${mobileMediaQueries(css`
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 30%) scale(1.5);
    `)}
`;

const StyledButton = styled(ModalBasicButton)`
    ${mediaQuery('L')(css`
        display: none;
    `)}

    ${mediaQuery('S')(css`
        display: none;
    `)}

    ${mediaQuery('M')(css`
        display: none;
    `)}
`;

export const FeedbackSuccess: FC<FeedbackSuccessProps> = ({ closeModal }) => {
    const { t } = useTranslation('feedbackForm');

    return (
        <>
            <StyledVideoContainer>
                <ReactPlayer
                    width={450}
                    height={450}
                    url={withCdnUrl('video/successPayment.webm')}
                    playsinline
                    loop
                    muted
                    playing
                />
            </StyledVideoContainer>
            <ModalHeader>{t('Мы вскоре с вами свяжемся!')}</ModalHeader>
            <ModalFooter>
                <StyledButton
                    data-e2e="successCloseModal"
                    pin="square-square"
                    size="m"
                    view="primary"
                    onClick={closeModal}
                    text={t('Буду ждать')}
                />
            </ModalFooter>
        </>
    );
};
