import { fetchAnalytics } from 'utils/fetchAnalytics';

const eventCategory = 'billing';

export const trackMinutesPricesClick = (analyticsFrom: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Billing:MinutesPricesClick',
            eventCategory,
            from: analyticsFrom,
        }),
    );
};

export const trackMinutesBuyClick = (analyticsFrom: string, minutesCount?: string | number, invoiceId?: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Billing:MinutesBuyClick',
            eventCategory,
            from: analyticsFrom,
            minutesCount,
            invoice_id: invoiceId,
        }),
    );
};

export const trackSubscriptionBuyClick = (analyticsFrom: string, invoiceId?: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Billing:SubscriptionBuyClick',
            eventCategory,
            from: analyticsFrom,
            invoice_id: invoiceId,
        }),
    );
};

export const trackBusinessButtonClick = (analyticsFrom: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Billing:BusinessButtonClick',
            eventCategory,
            from: analyticsFrom,
        }),
    );
};

export const trackFreeButtonClick = (analyticsFrom: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Billing:FreeButtonClick',
            eventCategory,
            from: analyticsFrom,
        }),
    );
};

export const trackTariffView = (analyticsFrom: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.view({
            eventAction: 'Billing:TariffView',
            eventCategory,
            from: analyticsFrom,
        }),
    );
};

export const trackTariffCompareClick = (analyticsFrom: string) => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Billing:TariffCompareClick',
            eventCategory,
            from: analyticsFrom,
        }),
    );
};

export const trackOvertimeClick = (action: 'Close' | 'Buy' | 'ViewTariff') => {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.view({
            eventAction: 'Billing:OvertimeClick',
            eventCategory,
            action,
        }),
    );
};
