import React, { SVGProps } from 'react';

export const Female: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 14" {...props}>
        <path
            d="M3.62451 2.5C3.62451 1.11929 4.71627 0 6.06302 0C7.40977 0 8.50152 1.11929 8.50152 2.5C8.50152 3.88071 7.40977 5 6.06302 5C4.71627 5 3.62451 3.88071 3.62451 2.5Z"
            fill="currentColor"
        />
        <path
            d="M3.73266 6.71468C4.10332 6.26165 4.65052 6 5.22727 6H6.62032C7.18176 6 7.71598 6.24799 8.08637 6.68056L11.5114 10.6806C12.6182 11.973 11.7229 14 10.0454 14H6.19411H1.95455C0.297631 14 -0.604902 12.0161 0.45993 10.7147L3.73266 6.71468Z"
            fill="currentColor"
        />
    </svg>
);
