import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { TextField } from '@salutejs/plasma-b2c';
import { FieldPlaceholder, Input, spacing } from '@salutejs/plasma-core';
import { useTranslation } from 'next-i18next';

import { TradeProductTemplate } from '../../typings/tradeProductTemplate';
import { MultipliableProductTemplate } from '../../api/subscriptionSwitchRequest';

const StyledInput = styled(TextField)`
    margin-bottom: ${spacing[8]};

    ${Input} {
        text-align: center;
    }

    ${FieldPlaceholder} {
        left: 0;
        text-align: center;
        transform-origin: 50% 0;
    }
`;

interface TradeProductTemplateInputProps {
    template: TradeProductTemplate;
    value?: number;
    onChange(templateSwitchDate: MultipliableProductTemplate | null): void;
}

const MAX_MINUTES_LIMIT = 1000;

export const TradeProductTemplateInput: React.FC<TradeProductTemplateInputProps> = ({ template, value, onChange }) => {
    const { t } = useTranslation('payment');
    const [error, setError] = useState<boolean>(false);
    const changeHandle = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            const newValue =
                Number(event.target.value) > MAX_MINUTES_LIMIT ? MAX_MINUTES_LIMIT.toString() : event.target.value;
            const hasError = Boolean(newValue && !/^\d+$/.test(newValue));

            setError(hasError);
            onChange(
                !hasError || !newValue
                    ? {
                          product_template_id: template.id,
                          multiplier: Number(newValue),
                      }
                    : null,
            );
        },
        [onChange, template.id],
    );

    return (
        <StyledInput
            placeholder={t('Другое количество')}
            value={typeof value !== 'undefined' ? value : ''}
            onChange={changeHandle}
            status={error ? 'error' : undefined}
            helperText={error ? t('Введите цифру от 1 и выше') : undefined}
        />
    );
};
