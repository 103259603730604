import React, { SVGProps } from 'react';

export const PauseBold: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 12" {...props}>
        <path
            d="M3 3C3 2.44772 3.44772 2 4 2H4.25C4.80228 2 5.25 2.44772 5.25 3V9C5.25 9.55228 4.80228 10 4.25 10H4C3.44772 10 3 9.55228 3 9V3Z"
            fill="currentColor"
        />
        <path
            d="M6.75 3C6.75 2.44772 7.19772 2 7.75 2H8C8.55228 2 9 2.44772 9 3V9C9 9.55228 8.55228 10 8 10H7.75C7.19772 10 6.75 9.55228 6.75 9V3Z"
            fill="currentColor"
        />
    </svg>
);
