import React, { SVGProps } from 'react';

export const EyeCrossed: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 36 36" {...props}>
        <path
            d="M27.3801 6.26771C27.2631 6.31649 27.1536 6.38845 27.0585 6.48358L23.9322 9.60986L23.9298 9.60858C22.058 8.6203 20.0556 8.10489 17.9999 8.10489C12.8863 8.10489 8.20539 11.2953 5.13428 16.6041C4.9551 16.9139 4.95508 17.2958 5.13422 17.6055C6.50223 19.971 8.20926 21.9419 10.1538 23.3882L7.55847 25.9836C7.46786 26.0742 7.39827 26.1779 7.34971 26.2885L7.29287 26.3454C6.90235 26.7359 6.90235 27.3691 7.29287 27.7596C7.6834 28.1501 8.31656 28.1501 8.70708 27.7596L28.7071 7.7596C29.0976 7.36907 29.0976 6.73591 28.7071 6.34538C28.3444 5.98274 27.7726 5.95685 27.3801 6.26771ZM17.9999 10.1049C19.524 10.1049 21.0163 10.4435 22.4401 11.102L20.6673 12.8748C19.8767 12.375 18.9573 12.1055 17.9996 12.1055C15.238 12.1055 12.9996 14.3435 12.9996 17.1055C12.9996 18.062 13.27 18.9803 13.7703 19.7718L11.5855 21.9566C10.0207 20.8448 8.60672 19.3334 7.41794 17.5049L7.16459 17.1049C9.89456 12.6781 13.8195 10.1049 17.9999 10.1049ZM17.9996 14.1055C18.4177 14.1055 18.8213 14.19 19.1917 14.3503L15.2456 18.2965C15.0845 17.9254 14.9996 17.5224 14.9996 17.1055C14.9996 15.4482 16.3425 14.1055 17.9996 14.1055ZM15.0999 23.6874C16.0476 23.9645 17.0173 24.1052 18.0003 24.1052C22.1803 24.1052 26.1043 21.5325 28.8354 17.1053L28.5566 16.6665C27.8018 15.5119 26.9566 14.4832 26.039 13.6028C25.6404 13.2204 25.6273 12.5874 26.0097 12.1889C26.392 11.7904 27.0251 11.7772 27.4236 12.1596C28.7225 13.4058 29.8818 14.9043 30.8657 16.6042C31.0451 16.9141 31.0451 17.2962 30.8658 17.6061C27.7934 22.9152 23.1135 26.1052 18.0003 26.1052C16.8261 26.1052 15.6674 25.9371 14.5386 25.607C14.0085 25.452 13.7045 24.8966 13.8595 24.3665C14.0145 23.8365 14.5698 23.5324 15.0999 23.6874ZM21 17.1052L21.0067 16.9886C21.0645 16.4912 21.4871 16.1052 22 16.1052C22.5523 16.1052 23 16.5529 23 17.1052C23 19.8665 20.7613 22.1052 18 22.1052C17.4477 22.1052 17 21.6575 17 21.1052C17 20.5529 17.4477 20.1052 18 20.1052C19.6567 20.1052 21 18.7619 21 17.1052Z"
            fill="currentColor"
        />
    </svg>
);
