import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery, spacing } from '@salutejs/plasma-b2c';
import { CarouselWithMouseScroll } from 'components/CarouselWithMouseScroll/CarouselWithMouseScroll';
import { DeviceType, useDeviceType } from 'hooks/useDeviceType';

const StyledTariffList = styled(CarouselWithMouseScroll)<{ isCompareMode?: boolean; isModalMode?: boolean }>`
    user-select: none;
    display: none;

    ${({ isCompareMode, isModalMode }) => css`
        ${mediaQuery('XL')(css`
            display: block;
            padding-right: ${isModalMode ? `calc(3 * ${spacing['16x']})` : spacing['0x']};
            margin-left: ${isModalMode && !isCompareMode ? `calc(-2 * ${spacing['16x']})` : spacing['0x']};
        `)}
        ${mediaQuery('XXL')(css`
            display: block;
            padding-right: ${isModalMode ? `calc(3 * ${spacing['16x']})` : spacing['0x']};
            margin-left: ${isModalMode && !isCompareMode ? `calc(-2 * ${spacing['16x']})` : spacing['0x']};
        `)}
    `}

    & > div {
        ${({ isCompareMode, isModalMode }) => css`
            ${mediaQuery('XL')(css`
                padding-right: ${isCompareMode ? `calc(2 * ${spacing['16x']})` : spacing['16x']};
                padding-left: ${isModalMode ? `calc(2 * ${spacing['16x']})` : spacing['0x']};
            `)}
            ${mediaQuery('XXL')(css`
                padding-right: ${isCompareMode ? `calc(2 * ${spacing['16x']})` : spacing['16x']};
                padding-left: ${isModalMode ? `calc(2 * ${spacing['16x']})` : spacing['0x']};
            `)}
        `}
    }
`;

const StyledMobileTariffList = styled.div`
    user-select: none;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    display: block;

    ${mediaQuery('XL')(css`
        display: none;
    `)}
    ${mediaQuery('XXL')(css`
        display: none;
    `)}
`;

interface TariffListProps {
    isCompareMode?: boolean;
    isModalMode?: boolean;
}

export const TariffList: React.FC<TariffListProps> = ({ isCompareMode, isModalMode, children }) => {
    const device = useDeviceType();

    return device === DeviceType.Mobile ? (
        <StyledMobileTariffList data-e2e-platform="mobile">{children}</StyledMobileTariffList>
    ) : (
        <StyledTariffList
            data-e2e-platform="desktop"
            data-e2e="tariffsListContainer"
            isCompareMode={isCompareMode}
            isModalMode={isModalMode}
        >
            {children}
        </StyledTariffList>
    );
};
