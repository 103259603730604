import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useToast } from 'hooks/useToast';

import { Invoice, InvoiceStatus } from '../typings/invoice';
import { PoolingState } from '../api/base';
import { getInvoice, invoicePooling } from '../api/invoice';
import { useQuery } from '../hooks/useQuery';
import { useModal, UseModalResult } from '../hooks/useModal';
import { useParams } from '../hooks/useParams';
import { getUser } from '../api/user';
import { fetchGa } from '../utils/fetchAnalytics';
import { SubscriptionCurrent } from '../typings/subscription';

import { UserContext } from './UserContext';
import { ApiDataContext } from './ApiDataContext/ApiDataContext';

export enum InvoiceContextStatus {
    init,
    initialInvoiceRequest,
    pooling,
    poolingEnd,
    finalInvoiceRequest,
    ready,
    invoiceRequestError,
}

export interface InvoiceContextModel {
    invoice: Invoice | null;
    status: InvoiceContextStatus;
    modal: UseModalResult;
}

export const InvoiceContext = createContext<InvoiceContextModel>({
    invoice: null,
    status: InvoiceContextStatus.init,
    modal: {
        isModalOpen: false,
        closeModal: () => {},
        openModal: () => {},
    },
});

export const InvoiceContextProvider: React.FC = ({ children }) => {
    const { t } = useTranslation('payment');
    const { showToast } = useToast();
    const { user, setUser } = useContext(UserContext);
    const {
        fetchers: { fetchCurrentSubscription },
    } = useContext(ApiDataContext);
    const router = useRouter();
    const [invoice, setInvoice] = useState<Invoice | null>(null);
    const [status, setStatus] = useState<InvoiceContextStatus>(InvoiceContextStatus.init);
    const { invoiceId, ...query } = useQuery();
    const params = useParams();
    const modal = useModal(!!invoiceId);

    const startInvoicePooling = useCallback((): PoolingState | void => {
        if (status === InvoiceContextStatus.pooling) {
            return;
        }

        setStatus(InvoiceContextStatus.pooling);
        const state = invoicePooling(async (newInvoice) => {
            if (newInvoice) {
                setInvoice(newInvoice);
            } else {
                setStatus(InvoiceContextStatus.poolingEnd);

                return false;
            }
        });

        return {
            ...state,
            stop: () => {
                setStatus(InvoiceContextStatus.init);
                state.stop();
            },
        };
    }, [status]);

    const invoiceContext = useMemo<InvoiceContextModel>(() => ({ invoice, status, modal }), [invoice, modal, status]);

    const fetchSuccessInvoiceAnalytics = useCallback((newInvoice: Invoice, subscription: SubscriptionCurrent) => {
        const subscriptionType = subscription.subscription_type.toLowerCase();

        fetchGa().then((analytics) => {
            analytics.pushEcommerceEvent({
                eventAction: 'Order Success',
                eventLabel: subscriptionType,
                eventNonInteraction: true,
                data: {
                    currencyCode: newInvoice.currency,
                    purchase: {
                        actionField: {
                            id: newInvoice.id,
                            revenue: Number(newInvoice.amount) / 100,
                            shipping: 0,
                        },
                        products: [
                            {
                                name: subscriptionType,
                                brand: 'Visper',
                                category: subscriptionType,
                            },
                        ],
                    },
                },
            });
        });
    }, []);

    useEffect(() => {
        if (user && status === InvoiceContextStatus.init) {
            if (invoiceId) {
                setStatus(InvoiceContextStatus.initialInvoiceRequest);
                getInvoice(invoiceId)
                    .then((res) => {
                        setInvoice(res);
                        startInvoicePooling();
                    })
                    .catch(() => setStatus(InvoiceContextStatus.invoiceRequestError));
            } else {
                startInvoicePooling();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, router.asPath]);

    useEffect(() => {
        if (user && invoiceId) {
            modal.openModal();
            router.push({ pathname: router.pathname, query: { ...query, ...params } });
        }
    }, [invoiceId, query, router, modal, user, params]);

    useEffect(() => {
        if (status !== InvoiceContextStatus.poolingEnd) {
            return;
        }

        if (invoice) {
            setStatus(InvoiceContextStatus.finalInvoiceRequest);
            getInvoice(invoice.id)
                .then(async (newInvoice) => {
                    const success = newInvoice.status === InvoiceStatus.Confirmed;

                    if (!modal.isModalOpen) {
                        if (success) {
                            showToast.success(t('Оплата прошла успешно'));
                        } else {
                            showToast.error(t('Оплата не прошла'));
                        }
                    }

                    setInvoice(newInvoice);
                    const subscription = await fetchCurrentSubscription();
                    await getUser().then(setUser);

                    if (success && subscription) {
                        fetchSuccessInvoiceAnalytics(newInvoice, subscription);
                    }
                })
                .catch(() => showToast.error(t('Оплата не прошла')))
                .finally(() => setStatus(InvoiceContextStatus.ready));
        } else {
            setStatus(InvoiceContextStatus.ready);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, invoice, fetchCurrentSubscription, setUser]);

    return <InvoiceContext.Provider value={invoiceContext}>{children}</InvoiceContext.Provider>;
};
