import React from 'react';
import styled, { css } from 'styled-components';
import { Caption, mediaQuery } from '@salutejs/plasma-b2c';

import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { Icon } from '../../Icon/Icon';
import { formatTicsToTime } from '../../../utils/dateFormatters';
import { ProgressState } from '../../TTSPlayerControl/TTSPlayerControl';

const StyledControls = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    display: flex;
    flex-flow: row;
    box-sizing: border-box;
    padding: 18px 20px;
`;

const StyledTime = styled(Caption)`
    color: rgba(255, 255, 255, 0.28);
`;

const StyledTotalTime = styled(Caption)`
    color: rgba(255, 255, 255, 0.28);
    margin-left: auto;
`;

const StyledProgressBar = styled(ProgressBar)`
    position: absolute;
    left: 84px;
    right: 116px;
    top: 25px;
`;

const StyledFullscreenButton = styled.div`
    margin-left: 16px;
    cursor: pointer;
`;

const StyledFullscreenButtonIcon = styled(Icon)`
    opacity: 0.28;
    transition: all 0.2s ease-in-out;

    ${mediaQuery('S')(css`
        display: none;
    `)}

    ${mediaQuery('M')(css`
        display: none;
    `)}

    ${mediaQuery('L')(css`
        display: none;
    `)}

    &:hover {
        opacity: 1;
        transform: scale(1.08);
    }
`;

interface VideoPlayerProps {
    fullscreenMode?: boolean;
    progress: ProgressState;
    duration: number;
    onProgressClick(value: number): void;
    onExpand(): void;
    onCollapse(): void;
    showFullscreen?: boolean;
}

export const ControlsBar: React.FC<VideoPlayerProps> = ({
    fullscreenMode = false,
    progress,
    duration,
    onProgressClick,
    onExpand,
    onCollapse,
    showFullscreen = true,
    ...props
}) => {
    return (
        <StyledControls {...props}>
            <StyledTime data-e2e="videoPlayerTime">{formatTicsToTime(progress.playedSeconds * 1000)}</StyledTime>

            <StyledProgressBar
                progress={progress.played * 100}
                interactive
                animateProgress={false}
                onClick={onProgressClick}
            />

            <StyledTotalTime data-e2e="videoPlayerTotalTime">{formatTicsToTime(duration * 1000)}</StyledTotalTime>

            <StyledFullscreenButton onClick={fullscreenMode ? onCollapse : onExpand}>
                {showFullscreen && (
                    <StyledFullscreenButtonIcon
                        icon={fullscreenMode ? 'collapse' : 'expand'}
                        color="white"
                        width={14}
                        height={14}
                    />
                )}
            </StyledFullscreenButton>
        </StyledControls>
    );
};
