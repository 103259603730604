import React, { SVGProps } from 'react';

export const SceneAvatarIcon: React.FC<SVGProps<SVGSVGElement>> = () => (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H20C22.2091 20 24 18.2091 24 16V4C24 1.79086 22.2091 0 20 0H4ZM9.43797 5.83092C9.43797 4.26745 10.7054 3 12.2689 3C13.8324 3 15.0998 4.26745 15.0998 5.83092C15.0998 7.3944 13.8324 8.66184 12.2689 8.66184C10.7054 8.66184 9.43797 7.3944 9.43797 5.83092ZM8.06017 10.7066C8.38162 9.98537 9.09732 9.5208 9.88693 9.5208H14.6508C15.4405 9.5208 16.1562 9.98537 16.4776 10.7066L17.3633 12.6936C17.8919 13.8796 17.1678 15.247 15.8898 15.4764L12.6222 16.0629C12.3885 16.1048 12.1492 16.1048 11.9156 16.0629L8.64795 15.4764C7.36995 15.247 6.64592 13.8796 7.17452 12.6936L8.06017 10.7066Z"
            fill="white"
        />
    </svg>
);
