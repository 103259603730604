import { PaginatedData } from '../typings/paginatedData';
import { Invoice, InvoiceStatus } from '../typings/invoice';
import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';

import { pooling, PoolingState } from './base';

export function getInvoice(id: string): Promise<Invoice> {
    return baseAxiosInstance.get<Invoice>(`${apiConfig.purchases}${id}/`).then(({ data }) => data);
}

export function getInvoices(
    limit?: number,
    offset?: number,
    status?: InvoiceStatus[],
): Promise<PaginatedData<Invoice>> {
    return baseAxiosInstance
        .get<PaginatedData<Invoice>>(apiConfig.purchases, { params: { limit, offset, status } })
        .then(({ data }) => data);
}

export function invoicePooling(frame: (invoice: Invoice | null) => void | false | Promise<void | false>): PoolingState {
    const frameHandler = (response: PaginatedData<Invoice>) => frame(response?.results?.[0] ?? null);
    const waitingInvoiceStatuses: InvoiceStatus[] = [InvoiceStatus.Created, InvoiceStatus.Executed, InvoiceStatus.Paid];

    return pooling<PaginatedData<Invoice>>(
        apiConfig.purchases,
        { params: { invoice_status: waitingInvoiceStatuses } },
        frameHandler,
    );
}
