import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from '@salutejs/plasma-b2c';
import { GrayMediumButton, MediumButtonPlaceholder } from 'components/Button/AdaptiveButton';
import { trackMinutesPricesClick } from 'analytics/billing';
import { useTranslation } from 'next-i18next';
import { buttonPrimary } from '@salutejs/plasma-tokens-b2c';

import { Product, ProductSuggestion } from '../../typings/product';
import { useToast } from '../../hooks/useToast';

const StyledSpinner = styled(Spinner)`
    position: absolute;
    margin-top: -3px;
`;

const StyledButton = styled(GrayMediumButton)<{ $isSuggested: boolean }>`
    ${({ $isSuggested }) =>
        $isSuggested &&
        css`
            background-color: ${buttonPrimary};
        `}
`;

const BusinessButton = styled(StyledButton)<{ $isSuggested: boolean }>`
    opacity: 0.6;
    cursor: not-allowed;
`;

export interface TariffCardButtonProps {
    price?: string;
    isCurrent?: boolean;
    options: Product[];
    currentOption: string | null;
    isPersonal: boolean;
    isDefault: boolean;
    isLoading: boolean;
    analyticsFrom: string;
    showOptions: boolean;
    suggestion?: ProductSuggestion;
    onSelect(): void;
    onShowOptions(value: boolean): void;
    onFreeRateClick(): void;
    openModal?: () => void;
    isCurrentSubscriptionStopped: boolean;
}

type ButtonComponentCond = () => boolean;

interface ButtonProcessor<Props = Record<string, unknown>> {
    cond: ButtonComponentCond;
    component: React.FC;
    props: Props;
}

export const TariffCardButton: React.FC<TariffCardButtonProps> = ({
    price,
    isCurrent,
    options,
    isPersonal,
    isDefault,
    isLoading,
    analyticsFrom,
    showOptions,
    suggestion,
    onSelect,
    onShowOptions,
    onFreeRateClick,
    openModal,
    isCurrentSubscriptionStopped,
}) => {
    const { t } = useTranslation('payment');
    const { showToast } = useToast();
    const handlePricesClick = useCallback(() => {
        onShowOptions(true);
        trackMinutesPricesClick(analyticsFrom);
    }, [analyticsFrom, onShowOptions]);

    const handleBusinessClick = useCallback(() => {
        // openModal && openModal();
        showToast.error(t('Временно недоступно'));
    }, [showToast, t]);

    const buttons: ButtonProcessor[] = useMemo(
        () => [
            {
                cond: () => isPersonal,
                component: BusinessButton,
                props: {
                    onClick: handleBusinessClick,
                    children: t('Написать нам'),
                    'data-e2e': 'writeToUsButton',
                },
                href: '/tariffs',
            },
            {
                cond: () => Boolean(isDefault && isCurrent),
                component: MediumButtonPlaceholder,
                props: {},
            },
            {
                cond: () => isDefault && !isCurrentSubscriptionStopped,
                component: StyledButton,
                props: {
                    onClick: onFreeRateClick,
                    children: t('Перейти'),
                    'data-e2e': 'free',
                    $isSuggested: suggestion,
                },
            },
            {
                cond: () => options.length === 0 && price === '0',
                component: MediumButtonPlaceholder,
                props: {},
            },
            {
                cond: () => options.length < 2 || showOptions,
                component: StyledButton,
                props: {
                    onClick: onSelect,
                    children: (
                        <>
                            {isLoading && <StyledSpinner size={24} color="white" />}
                            {t('Купить')}
                        </>
                    ),
                    $isSuggested: suggestion,
                    'data-e2e': 'buy',
                },
            },
            {
                cond: () => !showOptions,
                component: GrayMediumButton,
                props: {
                    onClick: handlePricesClick,
                    children: t('Цены'),
                    'data-e2e': 'prices',
                },
            },
        ],
        [
            handleBusinessClick,
            t,
            onFreeRateClick,
            suggestion,
            onSelect,
            isLoading,
            handlePricesClick,
            isPersonal,
            isDefault,
            isCurrent,
            isCurrentSubscriptionStopped,
            options.length,
            price,
            showOptions,
        ],
    );

    const Button = useMemo(() => buttons.find((item) => item.cond()), [buttons]);

    return Button ? <Button.component {...Button.props} /> : null;
};
