import { PushApi, WebPushDeviceRequest } from '@sberdevices/vc-contracts';

import { baseAxiosInstance } from '../utils/interceptor';
import { basePath, configuration } from '../configs/base';

const pushApi = new PushApi(configuration, basePath, baseAxiosInstance);

export const getWebpushSubscriptions = (limit = 100, offset = 0) => {
    return pushApi.pushWebpushList().then((response) => response.data.results ?? []);
};

export const createSubscription = (data: WebPushDeviceRequest) => {
    return pushApi.pushWebpushCreate(data);
};

export const removeSubscription = (registrationId: string) => {
    return pushApi.pushWebpushDestroy(registrationId);
};

export const removeSubscriptions = (registrationIds: string[]) => {
    return Promise.all(registrationIds.map((id) => removeSubscription(id)));
};
