import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery, spacing } from '@salutejs/plasma-b2c';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';

const StyledModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    margin-bottom: ${spacing['8x']};

    ${mobileMediaQueries(css`
        & > * {
            margin-bottom: ${spacing['6x']};

            &:last-child {
                margin-bottom: ${spacing['0x']};
            }
        }
    `)}

    ${mediaQuery('XL')(css`
        position: relative;
        flex-direction: row;
        align-items: flex-start;
        text-align: inherit;
        margin-top: ${spacing['10x']};
        margin-bottom: ${spacing['0x']};

        & > * {
            margin-bottom: ${spacing['0x']};
            margin-right: ${spacing['8x']};

            &:last-child {
                margin-right: ${spacing['0x']};
            }
        }
    `)}

    ${mediaQuery('XXL')(css`
        position: relative;
        flex-direction: row;
        align-items: flex-start;
        text-align: inherit;
        margin-top: ${spacing['10x']};
        margin-bottom: ${spacing['0x']};

        & > * {
            margin-bottom: ${spacing['0x']};
            margin-right: ${spacing['8x']};

            &:last-child {
                margin-right: ${spacing['0x']};
            }
        }
    `)}
`;

interface ModalFooterProps {
    className?: string;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, className }) => {
    return <StyledModalFooter className={className}>{children}</StyledModalFooter>;
};
