export const apiConfig = {
    slidingToken: '/token_sliding/',
    slidingTokenRefresh: '/token_sliding/refresh/',
    userProfile: '/users/current/',
    userUsePayments: '/users/current/use_payments/',
    approveTerms: '/users/approve_terms/',
    background: '/backgrounds/',
    ttsVariants: '/tts/',
    videos: '/video/',
    voices: '/voices/',
    characters: '/characters/',
    avatars: '/avatars/',
    avatarUnity: '/avatarunity/',
    files: '/uploaded_files/',
    mountVideo: '/mountvideo/',
    scene: '/documents/',
    scenePDF: '/documents/pdf/',
    sceneShare: '/documents/{id}/share/',
    startSceneGeneration: '/documents/{id}/start/',
    documentEstimate: '/documents/{id}/time_estimate/',
    // TODO: Перейти на /documents/send как на беке добавят, сейчас забыли
    startExhibitionSceneGeneration: '/document/send/',
    speaker: '/speaker/',
    media: '/media/',
    arvrTasks: '/atl/',
    arvrAudioTask: '/atl/audio/',
    arvrTTSTask: '/atl/tts/',
    signUp: '/sign_up/',
    activate: '/activate/',
    resetPassword: '/reset_password/',
    reactivate: '/reactivate/',
    getEmailByUid: '/get_email_by_uid/',
    sberIdState: '/sber_id_state/',
    sberIdAuth: '/sber_id_auth/',
    sberIdAuth2: '/sber_id_auth2/',
    sberIdRedirect: '/sber_id_url_redirect/',
    faceChangeSpeakerFaces: '/animage/face_images/',
    deepFake: '/faceswap/face_images/',
    backgroundAudio: '/background_audio/',
    gestures: '/gestures/',
    publicVideo: '/public/document/',
    subscriptions: '/shop/subscriptions/',
    subscriptionsCurrent: '/shop/subscriptions/current/',
    subscriptionWithPromocode: '/shop/subscriptions/{id}/{promocode}/',
    purchases: '/shop/pay/sberpay/',
    switchSubscription: '/shop/subscriptions/switch/',
    cancelCurrentSubscription: '/shop/subscriptions/cancel/',
    shopPaySberpayRetrieve: '​/shop/pay/sberpay/{id}/',
    shopPaySberpayPayProductCreate: '/shop/pay/sberpay/pay_product/',
    shopPaySberpayPaySubscriptionCreate: '/shop/pay/sberpay/pay_subscription/',
    feedbackFormSend: '/forms/buisiness_product/',
    socialAuthProviders: '/ext_auth/providers/',
    socialAuth: '/ext_auth/',
    unsubscribe: '/unsubscribe/',
    userFeatures: '/users/current/features/',
    transition: '/transitions/',
};
