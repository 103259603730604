import React, { useCallback, useRef, useState, ReactElement, useMemo, useEffect, RefObject } from 'react';
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';
import { black } from '@salutejs/plasma-tokens-b2c';
import { canUseWebM } from 'utils/canUseWebM';
import { PLAYER_UPDATE_INTERVAL_MS } from 'const/global';

import { ProgressState } from '../TTSPlayerControl/TTSPlayerControl';

import { ControlsBar } from './parts/ControlsBar';
import { useCalculateVideoSize } from './useCalculateVideoSize';

interface StyledViewProps {
    fullscreen?: boolean;
    hideCursor: boolean;
    width?: string;
    height?: string;
}

export const StyledView = styled.div<StyledViewProps>`
    position: relative;
    overflow: hidden;

    ${({ width, height }) => {
        if (width && height) {
            return css`
                width: ${width};
                height: ${height};
                margin: auto;
            `;
        }

        return css`
            padding-top: 56%;
        `;
    }}

    ${({ fullscreen }) =>
        fullscreen &&
        css`
            background: ${black};
            position: fixed;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        `}

    ${({ hideCursor }) =>
        hideCursor &&
        css`
            cursor: none;
        `}
`;

export const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ poster }) => (poster ? `url(${poster})` : '')};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`;

interface StyledControlsOverlay {
    hide: boolean;
}

const StyledControlsOverlay = styled.div<StyledControlsOverlay>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: all 0.2s linear;

    ${({ hide }) =>
        hide &&
        css`
            &&& {
                cursor: none;
                opacity: 0;
                pointer-events: none;
            }
        `}

    &:hover {
        opacity: 1;
    }

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.2s linear;
        /* stylelint-disable */
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.00691776) 6.67%,
            rgba(0, 0, 0, 0.028408) 13.33%,
            rgba(0, 0, 0, 0.0653279) 20%,
            rgba(0, 0, 0, 0.117929) 26.67%,
            rgba(0, 0, 0, 0.18542) 33.33%,
            rgba(0, 0, 0, 0.265507) 40%,
            rgba(0, 0, 0, 0.354153) 46.67%,
            rgba(0, 0, 0, 0.445847) 53.33%,
            rgba(0, 0, 0, 0.534493) 60%,
            rgba(0, 0, 0, 0.61458) 66.67%,
            rgba(0, 0, 0, 0.682071) 73.33%,
            rgba(0, 0, 0, 0.734672) 80%,
            rgba(0, 0, 0, 0.771592) 86.67%,
            rgba(0, 0, 0, 0.793082) 93.33%,
            rgba(0, 0, 0, 0.8) 100%
        );
        /* stylelint-enable */
        opacity: 0;
    }

    &:hover::before {
        opacity: 0.9;
    }
`;

interface VideoPlayerProps {
    url: string;
    poster?: string;
    playing: boolean;
    muted?: boolean;
    onExpand?: () => void;
    onCollapse?: () => void;
    showFullscreen: boolean;
    loop?: boolean;
    withControls?: boolean;
    nativeControls?: boolean;
    fallback?: ReactElement;
    onPause?(): void;
    isPortrait?: boolean;
    containerRef?: RefObject<HTMLElement>;
    onReady?(): void;
    hidePlayer?: boolean;
    playsinline?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
    playing,
    onPause,
    onExpand,
    onCollapse,
    children,
    url,
    poster,
    showFullscreen,
    loop,
    muted,
    withControls = true,
    nativeControls = false,
    fallback,
    isPortrait = false,
    containerRef,
    onReady,
    hidePlayer = false,
    playsinline = false,
    ...props
}) => {
    const playerRef = useRef<ReactPlayer>(null);

    const { width, height } = useCalculateVideoSize(containerRef, isPortrait);

    const [progress, setProgress] = useState<ProgressState>({
        played: 0,
        playedSeconds: 0,
        loaded: 0,
        loadedSeconds: 0,
    });
    const [duration, setDuration] = useState<number>(0);
    const [hideCursor, setHideCursor] = useState<boolean>(false);
    const [hideCursorTimeout, setHideCursorTimeout] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const isVideoSupported = useMemo(() => {
        return /\.webm$/.test(url) ? canUseWebM() : true;
    }, [url]);

    const progressClickListener = useCallback((percent: number) => playerRef.current?.seekTo(percent / 100), []);

    const viewMouseMoveListener = useCallback(() => {
        clearTimeout(hideCursorTimeout);
        setHideCursor(false);
        setHideCursorTimeout(
            setTimeout(() => {
                setHideCursor(true);
            }, 1500),
        );
    }, [hideCursorTimeout]);

    const viewMouseLeaveListener = useCallback(() => {
        clearTimeout(hideCursorTimeout);
        setHideCursor(playing);
    }, [hideCursorTimeout, playing]);

    const handleExpand = useCallback(() => {
        const { documentElement } = document;

        if (documentElement.webkitRequestFullScreen) {
            documentElement.webkitRequestFullScreen();
        } else {
            documentElement.requestFullscreen();
        }
    }, []);

    const handleCollapse = useCallback(() => {
        if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else {
            document.exitFullscreen();
        }
    }, []);

    useEffect(() => {
        const fullscreenChangeListener = (): void => {
            const isFullscreenCurrent = !!(document.fullscreen || document.webkitIsFullScreen);
            setIsFullscreen(isFullscreenCurrent);

            if (isFullscreenCurrent) {
                if (onExpand) {
                    onExpand();
                }
            } else if (onCollapse) {
                onCollapse();
            }
        };

        const { documentElement } = document;

        if (documentElement.webkitRequestFullScreen) {
            document.addEventListener('webkitfullscreenchange', fullscreenChangeListener);
        } else {
            document.addEventListener('fullscreenchange', fullscreenChangeListener);
        }

        return () => {
            document.removeEventListener('webkitfullscreenchange', fullscreenChangeListener);
            document.removeEventListener('fullscreenchange', fullscreenChangeListener);
        };
    }, [onExpand, onCollapse]);

    useEffect(
        () => () => {
            if (document.fullscreen || document.webkitIsFullScreen) {
                handleCollapse();
            }
        },
        [handleCollapse],
    );

    if (!isVideoSupported && fallback) {
        return fallback;
    }

    return (
        <StyledView
            data-e2e="videoPlayer"
            hideCursor={hideCursor}
            onMouseMove={viewMouseMoveListener}
            onMouseLeave={viewMouseLeaveListener}
            fullscreen={isFullscreen}
            width={width}
            height={height}
            {...props}
        >
            {!hidePlayer && (
                <StyledReactPlayer
                    onReady={onReady}
                    ref={playerRef}
                    url={url}
                    poster={poster}
                    controls={nativeControls}
                    playing={playing}
                    progressInterval={PLAYER_UPDATE_INTERVAL_MS}
                    width="100%"
                    height="100%"
                    loop={loop}
                    muted={muted}
                    onDuration={setDuration}
                    onProgress={setProgress}
                    onPause={onPause}
                    playsinline={playsinline}
                />
            )}

            {withControls && (
                <StyledControlsOverlay hide={hideCursor} data-e2e="videoPlayerControlsOverlay">
                    {children}

                    <ControlsBar
                        fullscreenMode={isFullscreen}
                        progress={progress}
                        duration={duration}
                        onProgressClick={progressClickListener}
                        onExpand={handleExpand}
                        onCollapse={handleCollapse}
                        showFullscreen={showFullscreen}
                    />
                </StyledControlsOverlay>
            )}
        </StyledView>
    );
};
