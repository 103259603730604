import React from 'react';
import styled, { css } from 'styled-components';
import { H2, spacing } from '@salutejs/plasma-b2c';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';

const StyledModalHeader = styled(H2)`
    position: relative;
    hyphens: none;
    margin-bottom: ${spacing['6x']};

    ${mobileMediaQueries(css`
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
    `)}
`;

export const ModalHeader: React.FC = ({ children, ...props }) => {
    return <StyledModalHeader {...props}>{children}</StyledModalHeader>;
};
