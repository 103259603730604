import { BrowserEnum, WebPushDeviceRequest } from '@sberdevices/vc-contracts';
import { detect } from 'detect-browser';

import { WORKFLOW_NAME } from '../const/environment';

const YANDEX_BROWSER = 'YANDEXBROWSER';

// В опере api поддерживается, но работает криво.
// Пуши то приходят, то нет, воспроизводится на любых сайтах с пушами, а не только у нас.
const SUPPORTED_BROWSERS = [
    YANDEX_BROWSER,
    ...Object.values(BrowserEnum).filter((browserName) => browserName !== BrowserEnum.Opera),
];

export const APPLICATION_KEY =
    process.env.WORKFLOW_NAME === WORKFLOW_NAME.Release
        ? 'BFNPKaB6142hA-bnDBE180XZS5wvfEGTyiFvTfbrdnfk3f76gnrlh0bhXnjth2SjSfPB44ToHwgkgV4GEudk0GA'
        : 'BLtEirhBijcOqgF1xNVFwNGyO8A59iyMzHD9PQbNG-ty1IgjtCbZQzglQwA68blU8W_Sh89QEFSrg8g_wpnvk0Q';

export const checkNotificationSupport = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!('serviceWorker' in navigator)) {
            reject(new Error('Service Worker are not supported by this browser'));

            return;
        }

        if (!('PushManager' in window)) {
            reject(new Error('Push notifications are not supported by this browser'));

            return;
        }

        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            reject(new Error('Notifications are not supported by this browser'));

            return;
        }

        const browser = detect();

        if (
            !browser ||
            browser.type !== 'browser' ||
            !SUPPORTED_BROWSERS.includes(browser?.name.toUpperCase() as BrowserEnum)
        ) {
            reject(new Error('Notifications doesnt work in this browsers due to api restrictions'));
        }

        resolve();
    });
};

export const notificationPermission = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (Notification.permission === 'denied') {
            return reject(new Error('Push messages are blocked'));
        }

        if (Notification.permission === 'granted') {
            return resolve();
        }

        if (Notification.permission === 'default') {
            return Notification.requestPermission().then((result) => {
                if (result !== 'granted') {
                    reject(new Error('Bad permission result'));
                } else {
                    resolve();
                }
            });
        }
    });
};

export function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

export function formatSubscribtionData(subscription: PushSubscription): WebPushDeviceRequest {
    const key = subscription.getKey('p256dh') as ArrayBuffer;
    const token = subscription.getKey('auth') as ArrayBuffer;
    const endpointParts = subscription.endpoint.split('/');
    const registration_id = endpointParts[endpointParts.length - 1];

    const data = {
        p256dh: btoa(String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(key)))),
        auth: btoa(String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(token)))),
        registration_id,
    };

    return data;
}

export const getBrowserName = (name?: string) => {
    if (!name) {
        return BrowserEnum.Chrome;
    }

    const browserName = name.toUpperCase() as BrowserEnum;

    return !Object.values(BrowserEnum).includes(browserName) ? BrowserEnum.Chrome : browserName;
};
