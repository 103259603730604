import React, { SVGProps } from 'react';

export const Union: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M12 6.69627C11.6339 6.3302 11.6339 5.73668 12 5.37061C12.8799 4.49291 14.0721 4 15.315 4C16.5579 4 17.7501 4.49291 18.63 5.37061C19.5073 6.25068 20 7.44261 20 8.68522C20 9.92783 19.5073 11.1198 18.63 11.9998L16.6403 13.9883C15.7561 14.8733 14.5785 15.3618 13.3258 15.3618C12.0731 15.3618 10.8955 14.8733 10.0113 13.9883C9.64515 13.6222 9.64515 13.0287 10.0113 12.6627C10.3774 12.2966 10.971 12.2966 11.3371 12.6627C12.3994 13.7249 14.2522 13.7249 15.3145 12.6627L17.3042 10.6742C17.8307 10.1461 18.1263 9.43086 18.1263 8.68522C18.1263 7.93958 17.8307 7.22435 17.3042 6.69627C16.7758 6.17031 16.0606 5.87503 15.315 5.87503C14.5694 5.87503 13.8542 6.17031 13.3258 6.69627C12.9597 7.06234 12.3661 7.06234 12 6.69627Z"
            fill="currentColor"
        />
        <path
            d="M12.6629 11.3373C11.6006 10.2751 9.74782 10.2751 8.68548 11.3373L6.69582 13.3258C6.16934 13.8539 5.87371 14.5691 5.87371 15.3147C5.87371 16.0604 6.16934 16.7756 6.69582 17.3037C7.2242 17.8296 7.93943 18.1249 8.68501 18.1249C9.43059 18.1249 10.1458 17.8296 10.6742 17.3037C11.0403 16.9376 11.6339 16.9376 12 17.3037C12.3661 17.6697 12.3661 18.2633 12 18.6293C11.565 19.065 11.0481 19.4104 10.4791 19.6456C9.91013 19.8808 9.30025 20.0013 8.68454 20C8.069 20.0011 7.4593 19.8806 6.89049 19.6454C6.32167 19.4101 5.80495 19.0648 5.37 18.6293C4.49265 17.7493 4 16.5573 4 15.3147C4 14.0721 4.49265 12.8802 5.37 12.0001L7.35966 10.0116C8.24385 9.12662 9.42152 8.63817 10.6742 8.63817C11.9269 8.63817 13.1045 9.12662 13.9887 10.0116C14.3548 10.3777 14.3548 10.9712 13.9887 11.3373C13.6226 11.7034 13.029 11.7034 12.6629 11.3373Z"
            fill="currentColor"
        />
    </svg>
);
