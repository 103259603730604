import { Currency } from './currency';

// Дока по статусам SberPay https://developers.sber.ru/docs/ru/smartservices/smartpay/payment-management/payment-statuses
export enum InvoiceStatus {
    Confirmed = 'confirmed',
    Created = 'created',
    Executed = 'executed',
    Paid = 'paid',
    Cancelled = 'cancelled',
    Reversed = 'reversed',
    Refunded = 'refunded',
    WaitRefund = 'wait_refund',
    RefundInProgress = 'refundinprogress',
}

export enum InvoicePayType {
    Product = 'PRODUCT',
    Subscription = 'SUBSCRIPTION',
}

export interface Invoice {
    // uid
    id: string;
    title: string;
    create_date: string;
    invoice_id: string;
    // amount приходит в копейках - правило платежной системы
    amount: number;
    currency: Currency;
    status: InvoiceStatus;
    pay_url: string;
    pay_type: InvoicePayType;
    checkUrl?: string;
}
