import React, { SVGProps } from 'react';

export const FacebookSharing: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M13.2886 20.16V12.2784H15.8349L16.2169 9.20584H13.2886V7.24447C13.2886 6.35518 13.5254 5.74914 14.7547 5.74914L16.32 5.74848V3.0003C16.0493 2.96377 15.1201 2.88 14.0386 2.88C11.7802 2.88 10.2341 4.31162 10.2341 6.94017V9.20584H7.67999V12.2784H10.2341V20.16H13.2886Z"
            fill="currentColor"
        />
    </svg>
);
