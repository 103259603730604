import React, { SVGProps } from 'react';

export const Eye: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 36 36" {...props}>
        <path
            d="M18 8C23.1136 8 27.7945 11.1905 30.8656 16.4993C31.0448 16.809 31.0448 17.191 30.8656 17.5007C27.7945 22.8095 23.1136 26 18 26C12.8868 26 8.20689 22.81 5.13448 17.5009C4.95517 17.191 4.95517 16.809 5.13448 16.4991C8.20689 11.19 12.8868 8 18 8ZM28.6283 16.6715C25.9089 12.4461 22.0759 10 18 10C13.82 10 9.89587 12.5728 7.16481 17C9.89587 21.4272 13.82 24 18 24C22.0759 24 25.9089 21.5539 28.6283 17.3285L28.8353 17L28.6283 16.6715ZM18 12C20.7613 12 23 14.2387 23 17C23 19.7613 20.7613 22 18 22C15.2387 22 13 19.7613 13 17C13 14.2387 15.2387 12 18 12ZM18 14C16.3433 14 15 15.3433 15 17C15 18.6567 16.3433 20 18 20C19.6567 20 21 18.6567 21 17C21 15.3433 19.6567 14 18 14Z"
            fill="currentColor"
        />
    </svg>
);
