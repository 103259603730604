import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery, spacing } from '@salutejs/plasma-b2c';
import { mobileMediaQueries } from 'utils/mobileMediaQueries';

type ModalBodyFullScreenProps = {
    fullScreen: boolean;
};

const StyledModalBody = styled.div<ModalBodyFullScreenProps>`
    position: relative;
    max-width: 100%;

    ${mediaQuery('XL')(css`
        margin-bottom: ${spacing['6x']};
    `)}
    ${mediaQuery('XXL')(css`
        margin-bottom: ${spacing['6x']};
    `)}

    ${mobileMediaQueries(css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
    `)}

    ${({ fullScreen }) => {
        if (fullScreen) {
            return css`
                width: 100%;
                height: 100%;
            `;
        }

        return css`
            ${mediaQuery('XL')(css`
                margin-bottom: ${spacing['6x']};
            `)}
            ${mediaQuery('XXL')(css`
                margin-bottom: ${spacing['6x']};
            `)}
        `;
    }}

    ${({ fullScreen }) => {
        if (fullScreen) {
            return mobileMediaQueries(css`
                width: 100%;
            `);
        }
    }};
`;

const ScrollContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
`;

interface ModalBodyProps {
    withScroll?: boolean;
    fullScreen?: boolean;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ withScroll = true, fullScreen = false, children, ...props }) => {
    return (
        <StyledModalBody fullScreen={fullScreen} {...props}>
            {withScroll ? <ScrollContainer>{children}</ScrollContainer> : children}
        </StyledModalBody>
    );
};
