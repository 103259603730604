import styled from 'styled-components';
import { Button, ButtonProps } from '@salutejs/plasma-b2c';
import { bodyMBold } from '@salutejs/plasma-typo';

export const ModalBasicButton = styled(Button)<ButtonProps>`
    position: relative;
    border-radius: 12px;
    text-decoration: none;
    display: inline-block;
    padding: 14px 24px;
    ${bodyMBold}
` as typeof Button;
