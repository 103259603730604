import React, { SVGProps } from 'react';

export const Music: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 36 37" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.58172 0 0 3.58172 0 8V29C0 33.4183 3.58172 37 8 37H28C32.4183 37 36 33.4183 36 29V8C36 3.58172 32.4183 0 28 0H8ZM13.8958 26.6C13.677 28.58 11.9983 30.12 9.96 30.12C7.77295 30.12 6 28.347 6 26.16C6 23.9729 7.77295 22.2 9.96 22.2C10.1087 22.2 10.2555 22.2082 10.4 22.2242V12.52V9H13.92H28L28 9.88L28 12.52L28 23.5132L28 23.52L28 23.5268V23.96H27.9758C27.757 25.94 26.0783 27.48 24.04 27.48C21.853 27.48 20.08 25.707 20.08 23.52C20.08 21.333 21.853 19.56 24.04 19.56C24.1887 19.56 24.3355 19.5682 24.48 19.5842V12.52H13.92V26.1532L13.92 26.16L13.92 26.1668V26.6H13.8958Z"
            fill="white"
            fillOpacity="0.28"
        />
    </svg>
);
