import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'next-i18next';

export const StyledErrorMessage = styled.div`
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
`;

interface ErrorBoundaryProps extends WithTranslation {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): { hasError: boolean } {
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);

        return { hasError: true };
    }

    render(): ReactNode {
        const { children } = this.props;
        const { hasError } = this.state;
        const { t } = this.props;

        return hasError ? (
            <StyledErrorMessage>{t('Произошла ошибка, обратитесь к администратору')}</StyledErrorMessage>
        ) : (
            children
        );
    }
}

export default withTranslation('error-boundary')(ErrorBoundary);
