import { useEffect } from 'react';

type Listener = EventListenerOrEventListenerObject | ((event: CustomEvent) => void);

export const useBodyEvent = (
    eventName: keyof HTMLElementEventMap | string,
    callback: Listener,
    options?: boolean | AddEventListenerOptions,
): void => {
    useEffect(() => {
        document.body.addEventListener(eventName, callback as EventListener, options);

        return () => document.body.removeEventListener(eventName, callback as EventListener);
    }, [eventName, callback, options]);
};
