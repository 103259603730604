export function formatTicsToTime(tics: number): string {
    const secondTics = 1000;
    const minuteTics = secondTics * 60;
    const hourTics = minuteTics * 24;
    const leftHours = Math.floor(tics / hourTics);
    const leftMinutes = Math.floor((tics % hourTics) / minuteTics);
    const leftSeconds = Math.ceil(((tics % hourTics) % minuteTics) / 1000);
    const hours = leftHours ? `${leftHours.toString().padStart(2, '0')}:` : '';
    const minutes = `${leftMinutes.toString().padStart(2, '0')}:`;

    return `${hours}${minutes}${leftSeconds.toString().padStart(2, '0')}`;
}

export function getFormattedPredictionTime(predictionTime: number): string {
    return new Date(predictionTime).toISOString().substr(14, 5);
}

export function formatSeconds(seconds: number): string {
    return new Date(seconds * 1000).toISOString().substr(11, 8).replace(/^00:/, '');
}
