export function isIOS(): boolean {
    return (
        typeof window !== 'undefined' &&
        Boolean(
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform,
            ) ||
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
        )
    );
}

export function isSafari(): boolean {
    return typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
