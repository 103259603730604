import React, { SVGProps } from 'react';

export const PositionUpToChest: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            d="M7.99917 3C6.8946 3 5.99917 3.89543 5.99917 5C5.99917 6.10457 6.8946 7 7.99917 7C9.10374 7 9.99917 6.10457 9.99917 5C9.99917 3.89543 9.10374 3 7.99917 3ZM6.35326 8C5.53545 8 4.80003 8.4979 4.4963 9.25722L3.89215 10.7676C3.42774 11.9286 4.1307 13.2263 5.35687 13.4715L7.60696 13.9216C7.86588 13.9733 8.1325 13.9733 8.39142 13.9216L10.6415 13.4715C11.8677 13.2263 12.5706 11.9286 12.1062 10.7676L11.5021 9.25722C11.1983 8.4979 10.4629 8 9.64512 8H6.35326Z"
            fill="currentColor"
        />
    </svg>
);
