import router from 'next/router';
import { useEffect } from 'react';
import { UserProfile } from '@sberdevices/vc-contracts';
import { queryParamsStorage } from 'utils/lsStorages';

import { getQueryParams } from './useQuery';

const BETA_TEST_KEY = 'betaTest';
const BETA_TEST_MAIL_KEY = 'betaTestMail';

export function useBetaTestQueryParam(user: UserProfile | null | undefined, callback: (idtag: string) => void): void {
    useEffect(() => {
        const localStorageValue = queryParamsStorage.get(BETA_TEST_KEY);
        const localStorageMailValue = queryParamsStorage.get<string>(BETA_TEST_MAIL_KEY);
        const { betaTest, sbemail, ...query } = getQueryParams();

        if (betaTest || localStorageValue) {
            if (user) {
                try {
                    callback(sbemail || localStorageMailValue);
                    queryParamsStorage.delete(BETA_TEST_KEY);
                    queryParamsStorage.delete(BETA_TEST_MAIL_KEY);
                    router.push({ pathname: window.location.pathname, query });
                } catch (e) {
                    console.error(e);
                }
            } else {
                queryParamsStorage.set(BETA_TEST_KEY, betaTest || localStorageValue);
                queryParamsStorage.set(BETA_TEST_MAIL_KEY, sbemail || localStorageMailValue);
            }
        }
    }, [user, callback]);
}
