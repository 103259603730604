import React, { SVGProps } from 'react';

export const RoubleCircleFill: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 18 18" {...props}>
        <path
            d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.54952 11.8059V10.653H9.64288C10.9893 10.653 11.9663 10.3777 12.5737 9.8272C13.1913 9.27668 13.5 8.40935 13.5 7.22521C13.5 6.05146 13.1811 5.22049 12.5433 4.73229C11.9157 4.2441 11.0045 4 9.80992 4C9.48596 4 9.18225 4.00519 8.89879 4.01558C8.61532 4.02597 8.34198 4.03635 8.07877 4.04674C7.82567 4.05713 7.57258 4.07271 7.31949 4.09348C7.07652 4.11426 6.82848 4.12984 6.57539 4.14023V9.12606H5V10.653H6.57539V11.8059H5V13.3017H6.57539V15H8.54952V13.3017H11.4955V11.8059H8.54952ZM9.06583 5.82295C9.24806 5.81256 9.44041 5.80736 9.64288 5.80736C10.0073 5.80736 10.306 5.84372 10.5388 5.91643C10.7717 5.98914 10.9539 6.09301 11.0855 6.22804C11.2272 6.36308 11.3234 6.53447 11.374 6.74221C11.4247 6.94995 11.45 7.18366 11.45 7.44334C11.45 8.00425 11.2981 8.42493 10.9944 8.70538C10.6907 8.98584 10.1744 9.12606 9.44547 9.12606H8.54952V5.86969C8.7115 5.84891 8.8836 5.83333 9.06583 5.82295Z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
);
