import { SignUpRequest } from '@sberdevices/vc-contracts';

export enum TokenType {
    sliding = 'sliding',
}

export interface DecodedToken {
    exp: number;
    jti: string;
    refresh_exp: number;
    token_type: TokenType;
    user_id: number;
}

export enum AuthResetPassReason {
    registration = 'registration',
    resetPassword = 'resetPassword',
}

export interface SberIdAuthState {
    id: string;
    /**
     * Ссылка для авторизации
     */
    state: string;
    /**
     * Время истекания токена timestamp
     */
    exp: number;
    /**
     * Время истекания токена ISO
     */
    epxIso: string;
}

export type SignUpCreateData = SignUpRequest;

export enum SocialAuthProvider {
    Vk = 'vk-oauth2',
    Facebook = 'facebook',
    Google = 'google-oauth2',
    Apple = 'apple',
}

export interface SocialAuthProviderData {
    provider: SocialAuthProvider;
    client_id: string;
    redirect_uri: string;
    response_type: string;
}
