import React from 'react';

import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../const/global';

interface RecaptchaV2Props {
    recaptchaId: string;
}

export const RecaptchaV2: React.FC<RecaptchaV2Props> = ({ recaptchaId }) => (
    <div id={recaptchaId} className={recaptchaId} data-sitekey={GOOGLE_RECAPTCHA_SITE_KEY} data-size="invisible" />
);
