import { useToast as usePlasmaToast } from '@salutejs/plasma-b2c';
import React, { ReactNode, useMemo } from 'react';
import { success, critical, accent } from '@salutejs/plasma-tokens-b2c';
import { Spinner } from '@salutejs/plasma-core';
import { Icon } from 'components/Icon/Icon';

const size = 16;

type Type = 'success' | 'error' | 'pending';

const TypeIcons: { [key in Type]: ReactNode } = {
    success: <Icon data-e2e="toastSuccessIcon" icon="done" width={size} height={size} color={success} />,
    error: <Icon data-e2e="toastErrorIcon" icon="cross" width={size} height={size} color={critical} />,
    pending: <Spinner data-e2e="toastSpinner" size={size} color={accent} />,
};

const DEFAULT_POSITION = 'bottom';
const DEFAULT_TIMEOUT = 3000;
const DEFAULT_FADE = false;

export const useToast = () => {
    const { showToast: showPlasmaToast, hideToast } = usePlasmaToast();

    const showToast = useMemo(() => {
        return {
            success: (text: string) =>
                showPlasmaToast(text, DEFAULT_POSITION, DEFAULT_TIMEOUT, DEFAULT_FADE, TypeIcons.success),
            error: (text: string) =>
                showPlasmaToast(text, DEFAULT_POSITION, DEFAULT_TIMEOUT, DEFAULT_FADE, TypeIcons.error),
            pending: (text: string) =>
                showPlasmaToast(text, DEFAULT_POSITION, DEFAULT_TIMEOUT, DEFAULT_FADE, TypeIcons.pending),
        };
    }, [showPlasmaToast]);

    return {
        showToast,
        hideToast,
    };
};
