import React, { SVGProps } from 'react';

export const TemplateOnlyContent: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 68 52" {...props}>
        <path
            d="M22.8987 25.5689L27.4283 20.9883C27.8197 20.5925 28.459 20.5925 28.8504 20.9883L39.2391 31.4939C39.8156 32.0769 40.7572 32.0769 41.3337 31.4939C41.9012 30.9201 41.9012 29.9964 41.3337 29.4226L38.2315 26.2854C37.8462 25.8958 37.8462 25.2687 38.2315 24.8791L39.1491 23.9512C39.5405 23.5554 40.1798 23.5554 40.5712 23.9512L45.1008 28.5318C45.6774 29.1148 46.619 29.1148 47.1955 28.5318C47.763 27.958 47.763 27.0343 47.1955 26.4605L41.4138 20.6137C40.5557 19.746 39.1646 19.746 38.3066 20.6137L36.1758 22.7684C35.7844 23.1642 35.1451 23.1642 34.7537 22.7684L29.693 17.6508C28.835 16.7831 27.4438 16.7831 26.5858 17.6508L20.804 23.4976C20.2366 24.0715 20.2366 24.9951 20.804 25.5689C21.3806 26.152 22.3222 26.152 22.8987 25.5689Z"
            fill="currentColor"
        />
    </svg>
);
