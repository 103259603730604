// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');

module.exports = {
    i18n: {
        defaultLocale: 'ru',
        defaultNS: 'meta',
        locales: ['ru', 'en'],
        fallbackLng: ['ru', 'en'],
    },
    localePath: path.resolve('./src/i18n'),
};
