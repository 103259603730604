import { NextRouter } from 'next/router';
import cookies from 'js-cookie';
import { LOCALE_COOKIE } from 'const/cookie';
import { UserProfile, TranslationsLanguagesEnum } from '@sberdevices/vc-contracts';

import { i18n } from '../../next-i18next.config';
import { Locale } from '../typings/locale';

const russianLocales = ['ru', 'uk', 'be', 'kz', 'az', 'hy', 'ky', 'mo', 'tg', 'tk', 'uz'];

function getNavigatorLang(): Locale | undefined {
    if (typeof window === 'undefined') {
        return undefined;
    }

    return new RegExp(`^(${russianLocales.join('|')})`).test(window.navigator.language) ? Locale.Ru : Locale.En;
}

export function getCurrentLocale(router: NextRouter, user?: UserProfile | null): TranslationsLanguagesEnum {
    return (user?.language ??
        cookies.get(LOCALE_COOKIE) ??
        router.locale ??
        getNavigatorLang() ??
        i18n.defaultLocale) as TranslationsLanguagesEnum;
}
