import { RefObject, useCallback, useEffect, useState } from 'react';
import { useWindowResize } from 'hooks/useWindowResize';

import { FULL_HD_ASPECT, FULL_HD_ASPECT_VERTICAL } from '../../const/tokens';

export const useCalculateVideoSize: (
    containerRef?: React.RefObject<HTMLElement>,
    isPortrait?: boolean,
) => { width?: string; height?: string } = (containerRef?: RefObject<HTMLElement>, isPortrait?: boolean) => {
    const [width, setWidth] = useState<string>();
    const [height, setHeight] = useState<string>();

    const calculateVideoSize = useCallback((): void => {
        const videoRatio = isPortrait ? FULL_HD_ASPECT_VERTICAL : FULL_HD_ASPECT;

        if (!containerRef?.current) {
            return;
        }

        const parentWidth = containerRef.current.clientWidth;
        const parentHeight = containerRef.current.clientHeight;
        const parentRatio = parentWidth / parentHeight;

        if (parentRatio > videoRatio) {
            setHeight('100%');
            setWidth(`${parentHeight * videoRatio}px`);
        } else {
            setWidth('100%');
            setHeight(`${parentWidth / videoRatio}px`);
        }
    }, [containerRef, isPortrait]);

    useWindowResize(calculateVideoSize);

    useEffect(() => {
        calculateVideoSize();
    }, [calculateVideoSize]);

    return {
        width,
        height,
    };
};
