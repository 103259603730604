import React, { SVGProps } from 'react';

export const Grid: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 14" {...props}>
        <rect width="7" height="6" rx="2" fill="currentColor" />
        <rect x="9" width="7" height="6" rx="2" fill="currentColor" />
        <rect y="8" width="7" height="6" rx="2" fill="currentColor" />
        <rect x="9" y="8" width="7" height="6" rx="2" fill="currentColor" />
    </svg>
);
