import React, { SVGProps } from 'react';

export const Tariffs: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 18 18" {...props}>
        <g clipPath="url(#clip0_23332_22026)">
            <path
                d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
                fill="currentColor"
            />
            <path
                d="M8.54952 9.65298V10.8059H11.4955V12.3017H8.54952V14H6.57539V12.3017H5V10.8059H6.57539V9.65298H5V8.12606H6.57539V3.14023C6.82848 3.12984 7.07652 3.11426 7.31949 3.09348C7.57258 3.07271 7.82567 3.05713 8.07877 3.04674C8.34198 3.03635 8.61532 3.02597 8.89879 3.01558C9.18225 3.00519 9.48596 3 9.80992 3C11.0045 3 11.9157 3.2441 12.5433 3.73229C13.1811 4.22049 13.5 5.05146 13.5 6.22521C13.5 7.40935 13.1913 8.27668 12.5737 8.8272C11.9663 9.37771 10.9893 9.65298 9.64288 9.65298H8.54952ZM9.64288 4.80737C9.44041 4.80737 9.24806 4.81256 9.06583 4.82295C8.8836 4.83333 8.7115 4.84891 8.54952 4.86969V8.12606H9.44547C10.1744 8.12606 10.6907 7.98584 10.9944 7.70538C11.2981 7.42493 11.45 7.00425 11.45 6.44334C11.45 6.18366 11.4247 5.94995 11.374 5.74221C11.3234 5.53447 11.2272 5.36308 11.0855 5.22804C10.9539 5.09301 10.7717 4.98914 10.5388 4.91643C10.306 4.84372 10.0073 4.80737 9.64288 4.80737Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_23332_22026">
                <rect width="18" height="18" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);
