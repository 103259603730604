import { GOOGLE_RECAPTCHA_SITE_KEY } from '../const/global';

interface GoogleRecaptcha {
    ready: (callback: () => void) => void;
    execute: (id: number) => void;
    reset: (id: number) => void;
    render: (element: string, options: any) => number;
}

export type RecaptchaRequestData<T extends Record<string, any>> = T & { captcha: string };

declare const grecaptcha: GoogleRecaptcha;

export function recaptchaInit(elementId: string, callback: (token: string) => void): Promise<number> {
    return new Promise((res) => {
        grecaptcha.ready(() => {
            const id = grecaptcha.render(elementId, {
                callback,
                sitekey: GOOGLE_RECAPTCHA_SITE_KEY,
                size: 'invisible',
            });
            res(id);
        });
    });
}

export function recaptchaExecute(widgetId: number): void {
    grecaptcha.execute(widgetId);
}

export function recaptchaReset(widgetId: number): void {
    grecaptcha.reset(widgetId);
}
