import styled, { css, keyframes } from 'styled-components';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { button2, buttonPrimary, white } from '@salutejs/plasma-tokens-b2c';
import { CarouselItem, mediaQuery } from '@salutejs/plasma-b2c';
import { TariffCard } from 'components/TariffCard/TariffCard';
import { ApiDataContext } from 'store/ApiDataContext/ApiDataContext';
import { switchSubscription } from 'api/subscription';
import { getSubscriptionWithPromocode, MappedSubscription } from 'api/tariffs';
import {
    trackMinutesBuyClick,
    trackSubscriptionBuyClick,
    trackTariffCompareClick,
    trackTariffView,
} from 'analytics/billing';
import { ProductSuggestion } from 'typings/product';
import { spacing } from '@salutejs/plasma-core';
import { useModal } from 'hooks/useModal';
import { FeedbackModal } from 'components/FeedbackModal/FeedbackModal';
import { useRouter } from 'next/router';
import { ServerError, ServerErrorResponse } from 'typings/serverError';
import { AxiosError } from 'axios';
import { SubscriptionType } from 'typings/subscription';
import { useToast } from 'hooks/useToast';
import { paySubscription } from 'api/pay';

import { InvoiceContext, InvoiceContextStatus } from '../../store/InvoiceContext';
import { LayoutContext } from '../../store/LayoutContext';
import { mobileMediaQueries } from '../../utils/mobileMediaQueries';
import { RATES_DESCRIPTION_EN, RATES_DESCRIPTION_RU } from '../../const/externalResources';
import { useLanguage } from '../../hooks/useLocale';
import { SubscriptionSwitchRequest } from '../../api/subscriptionSwitchRequest';
import { FeatureValue, productFeatures } from '../../const/productFeatures';
import { routesConfig } from '../../configs/routes';

import { TariffBenefits } from './TariffBenefits';
import { TariffList } from './TariffList';

const compareModeNamesAnimation = keyframes`
    0% {
        opacity: 0;
        width: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        width: 340px;
        transform: translateX(0%);
    }
`;

const TariffsContainer = styled.div`
    max-width: 100%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
`;

const ProductNamesContainer = styled.div<{ isCompareMode: boolean }>`
    flex-direction: column;
    width: 340px;
    display: ${({ isCompareMode }) => (isCompareMode ? 'flex' : 'none')};

    animation-name: ${({ isCompareMode }) => (isCompareMode ? compareModeNamesAnimation : '')};
    animation-iteration-count: 1;
    animation-duration: 0.3s;
`;

const ProductName = styled.div`
    color: ${white};
    height: 66px;
    width: 340px;
`;

const StyledTariffItem = styled(CarouselItem)`
    display: flex;
    flex-direction: column;
    margin-top: ${spacing['8x']};
    margin-right: ${spacing['8x']};

    ${mediaQuery('S')(css`
        &:first-child {
            margin-top: ${spacing['0x']};
        }
        margin-right: ${spacing['0x']};
    `)}
    ${mediaQuery('M')(css`
        &:first-child {
            margin-top: ${spacing['0x']};
        }
        margin-right: ${spacing['0x']};
    `)}
    ${mediaQuery('L')(css`
        &:first-child {
            margin-top: ${spacing['0x']};
        }
        margin-right: ${spacing['0x']};
    `)}

    &:last-child {
        margin-right: ${spacing['0x']};
    }
`;

const StyledRatesDescLink = styled.div`
    overflow-wrap: break-word;
    hyphens: auto;
    ${button2};
    margin-top: 80px;
    color: ${buttonPrimary};
    cursor: pointer;

    ${mediaQuery('S')(css`
        margin-top: ${spacing['12x']};
        text-align: center;
    `)}
    ${mediaQuery('M')(css`
        margin-top: ${spacing['12x']};
        text-align: center;
    `)}
    ${mediaQuery('L')(css`
        margin-top: ${spacing['12x']};
        text-align: center;
    `)}
`;

const StyledModalCompareButton = styled(StyledRatesDescLink)`
    ${mobileMediaQueries(css`
        display: none;
    `)}
`;

const StyledTarrifs = styled.div<{ isCompareMode: boolean }>`
    display: flex;
    flex-flow: column;
    width: 100%;

    ${({ isCompareMode }) =>
        css`
            overflow-x: ${isCompareMode ? 'auto' : 'visible'};
            ${mediaQuery('XL')(css`
                margin-right: ${isCompareMode ? `calc(-2 * ${spacing['16x']})` : spacing['0x']};
            `)}
            ${mediaQuery('XXL')(css`
                margin-right: ${isCompareMode ? `calc(-2 * ${spacing['16x']})` : spacing['0x']};
            `)}
        `}
`;

const TariffsFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: calc(2 * ${spacing['16x']});
`;

interface TariffsProps {
    isCompareMode?: boolean;
    isModalMode?: boolean;
    analyticsFrom: 'TariffsModal' | 'AccountMenu';
    suggestion?: ProductSuggestion;
}

export const Tariffs: React.FC<TariffsProps> = ({
    isCompareMode = false,
    isModalMode = false,
    analyticsFrom,
    suggestion,
    ...props
}) => {
    const { t } = useTranslation('payment');
    const { t: translateError } = useTranslation('serverErrorMessages');
    const lang = useLanguage();
    const router = useRouter();
    const isLandingPage = router.pathname === routesConfig.landing;
    const { status: invoiceStatus } = useContext(InvoiceContext);
    const { showToast } = useToast();
    const [loadingId, setLoadingId] = useState<string | null>(null);
    const {
        data: { tariffs, currentSubscription },
        fetchers: { fetchTariffs, fetchCurrentSubscription },
    } = useContext(ApiDataContext);
    const feedbackModal = useModal();
    const { tariffsModal } = useContext(LayoutContext);

    const [tariffsWithPromocode, setTariffsWithPromocode] = useState<MappedSubscription[]>(tariffs.data || []);

    useEffect(() => {
        setTariffsWithPromocode(tariffs.data || []);
    }, [tariffs.data]);

    const handleSubscriptionChange = useCallback(
        async (data: SubscriptionSwitchRequest) => {
            const promoId = tariffsWithPromocode?.find((tariff) => tariff.id === data.subscription_id)?.parent;
            setLoadingId(promoId || data.subscription_id);

            try {
                const subscription = await switchSubscription({
                    ...data,
                    subscription_id: promoId ?? data.subscription_id,
                });

                if (subscription.is_need_pay) {
                    try {
                        const invoice = await paySubscription(subscription.id);

                        if (data.product_variant_id) {
                            trackMinutesBuyClick(analyticsFrom, subscription.product_variant?.title, invoice.id);
                        } else if (data.multipliable_product) {
                            trackMinutesBuyClick(analyticsFrom, data.multipliable_product.multiplier, invoice.id);
                        } else {
                            trackSubscriptionBuyClick(analyticsFrom, invoice.id);
                        }
                        window.location.href = invoice.pay_url;
                    } catch (e) {
                        const errorType = (e as AxiosError<ServerErrorResponse>)?.response?.data?.error_type;

                        if (errorType === ServerError.UserAlreadyHaveThisSubscription) {
                            showToast.error(translateError(errorType));
                        } else {
                            showToast.error(t('Ошибка перехода в платёжный сервис'));
                        }
                    }
                } else {
                    await fetchCurrentSubscription();
                }
            } catch (e) {
                if ((e as AxiosError)?.response?.status === 429) {
                    showToast.error(t('Слишком много попыток оплаты, попробуйте позже'));
                } else {
                    showToast.error(translateError('UNKNOWN_ERROR'));
                }
            } finally {
                setLoadingId(null);
            }
        },
        [tariffsWithPromocode, showToast, translateError, t, fetchCurrentSubscription, analyticsFrom],
    );

    const handlePromocode = useCallback(async (id: string, promocode: string): Promise<void> => {
        try {
            const tariffWithPromocode = await getSubscriptionWithPromocode(id, promocode);
            setTariffsWithPromocode((currentTariffs) =>
                currentTariffs?.map((tariff) => {
                    if (tariff.id === tariffWithPromocode?.parent) {
                        return {
                            ...tariff,
                            parent: tariffWithPromocode.id,
                            promoPrice: tariffWithPromocode.price,
                            promoOptions: tariffWithPromocode.options,
                            oldPricePerMinute: tariff.pricePerMinute,
                            pricePerMinute: tariffWithPromocode.pricePerMinute,
                        };
                    }

                    return tariff;
                }),
            );
        } catch (e) {
            setTariffsWithPromocode((currentTariffs) =>
                currentTariffs?.map((tariff) => {
                    if (tariff.id === id) {
                        return {
                            ...tariff,
                            parent: null,
                            promoPrice: null,
                            promoOptions: null,
                        };
                    }

                    return tariff;
                }),
            );

            throw e;
        }
    }, []);

    const isCurrent = (tariff: MappedSubscription): boolean => {
        const { will_be_prolongated, subscription, subscription_type } = currentSubscription.data || {};

        if (!will_be_prolongated && subscription_type === SubscriptionType.Subscription) {
            return tariff.subscription_type === SubscriptionType.Free;
        }

        return subscription?.id === tariff.id || subscription?.parent === tariff.id;
    };

    const isCurrentSubscriptionStopped =
        currentSubscription.data?.subscription_type === SubscriptionType.Subscription &&
        currentSubscription.data?.will_be_prolongated === false;

    const handleCompareClick = (): void => {
        tariffsModal.open({ isCompare: true });
        trackTariffCompareClick(analyticsFrom);
    };

    useEffect(() => {
        if (!tariffs.status && !tariffs.data?.length) {
            fetchTariffs();
        }
    }, [tariffs, fetchTariffs]);

    useEffect(() => {
        if (isLandingPage) {
            return;
        }

        fetchCurrentSubscription();
    }, [fetchCurrentSubscription, isLandingPage]);

    useEffect(() => {
        trackTariffView(analyticsFrom);
    }, [analyticsFrom]);

    const getOldPrice = useCallback((tariff: MappedSubscription) => {
        if (tariff.oldPricePerMinute) {
            return `${tariff.oldPricePerMinute.price}`;
        }

        if (tariff.promoPrice) {
            return Number(tariff.price) > 0 ? tariff.price : tariff.options[0]?.price;
        }

        return tariff.oldPrice;
    }, []);

    return tariffs.data && tariffs.data?.length ? (
        <TariffsContainer {...props}>
            <ProductNamesContainer isCompareMode={isCompareMode}>
                {productFeatures.map((feature, i) => (
                    <ProductName key={i} data-e2e="productName">
                        {t(feature.title)}
                    </ProductName>
                ))}
            </ProductNamesContainer>

            <StyledTarrifs isCompareMode={isCompareMode}>
                <TariffList isCompareMode={isCompareMode} isModalMode={isModalMode}>
                    {tariffsWithPromocode?.map((subscription) => {
                        let featureValues: FeatureValue[] | undefined;

                        if (isCompareMode) {
                            featureValues = productFeatures.map(
                                (feature) => feature.values[subscription.subscription_type],
                            );
                        }

                        return (
                            <StyledTariffItem key={`${subscription.id}-${router.locale}`}>
                                <TariffCard
                                    id={subscription.id}
                                    onSelect={handleSubscriptionChange}
                                    onPromoCode={handlePromocode}
                                    title={subscription.title}
                                    description={subscription.description}
                                    price={subscription.promoPrice ? subscription.promoPrice : subscription.price}
                                    usdPrice={subscription.usdPrice}
                                    currency={subscription.currency}
                                    oldPrice={getOldPrice(subscription)}
                                    video={subscription.video}
                                    videoFallback={subscription.videoFallback}
                                    options={subscription.promoOptions || subscription.options}
                                    label={subscription.label}
                                    suggestion={suggestion?.tariffId === subscription.id ? suggestion : undefined}
                                    isPersonal={subscription.isPersonal}
                                    isShortInfo={isCompareMode}
                                    isDefault={subscription.is_default}
                                    isCurrent={isCurrent(subscription)}
                                    isDisabled={invoiceStatus === InvoiceContextStatus.pooling}
                                    isLoading={loadingId === subscription.id}
                                    analyticsFrom={analyticsFrom}
                                    onOpenFeedbackModal={feedbackModal.openModal}
                                    type={subscription.subscription_type}
                                    isCurrentSubscriptionStopped={isCurrentSubscriptionStopped}
                                    pricePerMinute={subscription.pricePerMinute}
                                    tradeProductTemplates={subscription.product_templates}
                                    isLandingPage={isLandingPage}
                                />

                                {isCompareMode && featureValues && <TariffBenefits benefits={featureValues} />}
                            </StyledTariffItem>
                        );
                    })}
                </TariffList>
                <TariffsFooter>
                    {!isCompareMode && (
                        <StyledRatesDescLink
                            as="a"
                            href={lang === 'ru' ? RATES_DESCRIPTION_RU : RATES_DESCRIPTION_EN}
                            target="_blank"
                        >
                            {t('Подробнее о тарифах')}
                        </StyledRatesDescLink>
                    )}
                    {!isCompareMode && isModalMode && (
                        <StyledModalCompareButton onClick={handleCompareClick} data-e2e="ratesModalCompareButton">
                            {t('В чем разница между тарифами?')}
                        </StyledModalCompareButton>
                    )}
                </TariffsFooter>
            </StyledTarrifs>
            {feedbackModal.isModalOpen && <FeedbackModal closeModal={feedbackModal.closeModal} />}
        </TariffsContainer>
    ) : null;
};
