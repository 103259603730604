import React, { SVGProps } from 'react';

export const AspectAlbum: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <rect x="3.5" y="6.5" width="17" height="11" stroke="currentColor" fill="transparent" />
        <circle cx="20.5" cy="6.5" r="1.5" fill="currentColor" />
        <circle cx="3.5" cy="6.5" r="1.5" fill="currentColor" />
        <circle cx="3.5" cy="17.5" r="1.5" fill="currentColor" />
        <circle cx="20.5" cy="17.5" r="1.5" fill="currentColor" />
    </svg>
);
