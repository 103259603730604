import React, { SVGProps } from 'react';

export const Gmail: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g clipPath="url(#clip0_19454_14990)">
            <path
                d="M21.9999 12.2247C21.9999 11.5663 21.9403 10.9413 21.8382 10.333H12.217V14.0913H17.7258C17.4789 15.3247 16.7551 16.3663 15.6823 17.0747V19.5747H18.9689C20.8931 17.833 21.9999 15.2663 21.9999 12.2247Z"
                fill="#4285F4"
            />
            <path
                d="M12.2172 21.9999C14.9758 21.9999 17.2831 21.0999 18.969 19.5749L15.6825 17.0749C14.7629 17.6749 13.5965 18.0415 12.2172 18.0415C9.5522 18.0415 7.29592 16.2832 6.48707 13.9082H3.09839V16.4832C4.7757 19.7499 8.22397 21.9999 12.2172 21.9999Z"
                fill="#34A853"
            />
            <path
                d="M6.48702 13.9083C6.27416 13.3083 6.16347 12.6666 6.16347 11.9999C6.16347 11.3333 6.28267 10.6916 6.48702 10.0916V7.5166H3.09834C2.40017 8.8666 2 10.3833 2 11.9999C2 13.6166 2.40017 15.1333 3.09834 16.4833L6.48702 13.9083Z"
                fill="#FBBC05"
            />
            <path
                d="M12.2172 5.95833C13.7242 5.95833 15.0694 6.46667 16.1337 7.45834L19.0456 4.60833C17.2831 2.99167 14.9758 2 12.2172 2C8.22397 2 4.7757 4.25 3.09839 7.51667L6.48707 10.0917C7.29592 7.71667 9.5522 5.95833 12.2172 5.95833Z"
                fill="#EA4335"
            />
        </g>
        <defs>
            <clipPath id="clip0_19454_14990">
                <rect width="20" height="20" fill="white" transform="translate(2 2)" />
            </clipPath>
        </defs>
    </svg>
);
