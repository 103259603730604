export enum LocalStorageKeys {
    SberIdPath = 'sberIdPath',
    SberIdAuthState = 'sberIdAuthState',
    SocialAuthState = 'socialAuthState',
    Token = 'token',
}

export enum SceneKeys {
    CachedScene = 'cached-scene',
    CachedPdfScene = 'cached-pdf-scene',
    DeepFakePanelHidden = 'deep-fake-panel-hidden',
}
