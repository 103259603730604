import React from 'react';
import { appWithTranslation, UserConfig, useTranslation, i18n as globalI18n } from 'next-i18next';
import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';
import { b2c } from '@salutejs/plasma-tokens-b2c/typo';
import { dark } from '@salutejs/plasma-tokens-b2c/themes';
import { standard } from '@salutejs/plasma-typo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ToastProvider } from '@salutejs/plasma-b2c';
import * as Sentry from '@sentry/react';

import { LayoutContextProvider } from '../store/LayoutContext';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { GlobalStyles } from '../components/GlobalStyles/GlobalStyles';
import { Root } from '../components/Root/Root';
import { appConfig } from '../configs/appConfig';

const TypoTheme = createGlobalStyle(b2c);
const ColorTheme = createGlobalStyle(dark);
const B2CTypoTheme = createGlobalStyle(standard);

const MyApp: React.FC<AppProps> = (props) => {
    const site = appConfig.prodHost;
    const canonicalUrl = site + useRouter().asPath;
    const { t } = useTranslation('meta');
    // eslint-disable-next-line no-underscore-dangle
    const supportedLngs = props.pageProps._nextI18Next?.userConfig.supportedLngs;
    // eslint-disable-next-line no-underscore-dangle
    const i18nStore: UserConfig = props.pageProps._nextI18Next?.initialI18nStore ?? {};

    globalI18n?.on('missingKey', (languages: string[], ns, key, fallbackValue) => {
        const emptyLangs = [
            ...Object.keys(i18nStore).filter((item) => !(i18nStore as any)[item][ns]?.[key]),
            ...languages,
        ];
        const failedLangs = supportedLngs?.length
            ? emptyLangs.filter((item) => supportedLngs.includes(item))
            : emptyLangs;

        if (!failedLangs.length) {
            return;
        }

        Sentry.withScope((scope) => {
            scope.setTags({ languages: failedLangs.join(', '), ns, key, fallbackValue });
            Sentry.captureException(new Error('Empty localization value'), scope);
        });
    });

    return (
        <React.StrictMode>
            <Head>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <title>Visper</title>
                <meta name="description" content={t('metaDescription')} />
                <meta name="og:description" content={t('metaDescription')} />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <link rel="canonical" href={canonicalUrl} />
            </Head>
            <GlobalStyles />
            <TypoTheme />
            <B2CTypoTheme />
            <ColorTheme />
            <ErrorBoundary>
                <LayoutContextProvider>
                    <ToastProvider>
                        <Root {...props} />
                    </ToastProvider>
                </LayoutContextProvider>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

export default appWithTranslation(MyApp);
