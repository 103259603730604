import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { TOKEN_COOKIE } from 'const/cookie';
import { WORKFLOW_NAME } from 'const/environment';
import { DecodedToken } from 'typings/auth';

const env = process.env.WORKFLOW_NAME as WORKFLOW_NAME;

export function setToken(token: string): void {
    const jwt = jwtDecode<DecodedToken>(token);

    Cookies.set(TOKEN_COOKIE, token, {
        expires: new Date(jwt.exp * 1000),
        sameSite: env !== WORKFLOW_NAME.Local ? 'None' : undefined,
        path: '/',
        secure: env !== WORKFLOW_NAME.Local,
    });
}

export function resetToken(): void {
    Cookies.remove(TOKEN_COOKIE);
}

export function getToken(): string | undefined {
    return Cookies.get(TOKEN_COOKIE);
}

export function removeToken(): void {
    Cookies.remove(TOKEN_COOKIE);
}
