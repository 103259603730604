import React, { SVGProps } from 'react';

export const Blender: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <path
            d="M16.3836 17.1726C16.4423 16.1142 16.9554 15.1817 17.7295 14.5207C18.4886 13.8714 19.5104 13.4746 20.6253 13.4746C21.7391 13.4746 22.7608 13.8714 23.5205 14.5207C24.294 15.1817 24.8072 16.1142 24.8664 17.1714C24.9252 18.2589 24.4924 19.2692 23.7333 20.0181C22.9592 20.7798 21.8582 21.2578 20.6253 21.2578C19.3924 21.2578 18.2892 20.7798 17.5156 20.0181C16.7559 19.2692 16.3243 18.2589 16.3836 17.1726V17.1726Z"
            fill="currentColor"
        />
        <path
            d="M9.62344 19.2995C9.63064 19.7137 9.76142 20.5185 9.95758 21.1471C10.3698 22.4775 11.0691 23.7083 12.0421 24.793C13.0406 25.9079 14.2702 26.8034 15.6904 27.4392C17.1832 28.107 18.8006 28.4472 20.4807 28.4444C22.158 28.4422 23.7754 28.0952 25.2682 27.4224C26.6884 26.7805 27.9168 25.881 28.9137 24.7655C29.8861 23.6764 30.5843 22.4433 30.9977 21.1129C31.2055 20.4407 31.3368 19.7584 31.3894 19.0739C31.441 18.3995 31.4194 17.7239 31.3246 17.0489C31.1395 15.7336 30.689 14.4995 29.9953 13.3745C29.3608 12.3407 28.543 11.4357 27.5705 10.6739L27.5727 10.6722L17.7594 3.0603C17.7506 3.05359 17.7434 3.04631 17.734 3.04015C17.0901 2.54089 16.0073 2.54258 15.2992 3.04295C14.5833 3.54892 14.5013 4.38568 15.1385 4.91348L15.1357 4.91628L19.2289 8.2784L6.75038 8.29184H6.73376C5.70256 8.29296 4.71126 8.97635 4.51511 9.83997C4.31341 10.7198 5.01381 11.4497 6.08601 11.4536L6.08434 11.4575L12.4067 11.4452L1.12506 20.1933C1.11066 20.204 1.09514 20.2152 1.08184 20.2258C0.0173962 21.0491 -0.326706 22.4181 0.343767 23.2846C1.02421 24.1655 2.47099 24.1672 3.54652 23.2896L9.70268 18.1986C9.70268 18.1986 9.61291 18.8859 9.62012 19.2984L9.62344 19.2995ZM25.4488 21.601C24.1799 22.9068 22.4034 23.6472 20.4812 23.6512C18.5562 23.6545 16.7798 22.9208 15.5109 21.6172C14.8908 20.9819 14.4353 20.251 14.1544 19.4724C13.879 18.7073 13.7721 17.8952 13.843 17.0758C13.91 16.2749 14.1461 15.5109 14.5229 14.8196C14.893 14.1402 15.4028 13.5262 16.0323 13.0068C17.2657 11.9915 18.8361 11.4418 20.4785 11.4396C22.1225 11.4374 23.6917 11.982 24.9263 12.9939C25.5547 13.5111 26.0639 14.1228 26.434 14.8012C26.8125 15.4918 27.0469 16.253 27.1167 17.0562C27.1865 17.8745 27.0796 18.6855 26.8042 19.4512C26.5227 20.2319 26.0689 20.9629 25.4488 21.601V21.601Z"
            fill="currentColor"
        />
    </svg>
);
