import React, { SVGProps } from 'react';

export const SberLogo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" {...props}>
        <path
            d="M16 0h.003c.247 0 .494.005.74.016L16.8.02a15.927 15.927 0 019.247 3.53l-3.11 2.294a12.242 12.242 0 00-6.322-2.133v.002c-.108-.006-.218-.01-.327-.014a12.245 12.245 0 00-.292-.003v-.001a12.26 12.26 0 00-8.246 3.185v.001a12.268 12.268 0 00-.445.424 12.274 12.274 0 00-3.593 8.083c-.01.197-.014.395-.015.595V16a12.26 12.26 0 003.185 8.25l.053.059c.12.131.244.26.37.386v.001a12.28 12.28 0 008.082 3.593v-.003a4.559 4.559 0 00.339.015l.277.001a12.265 12.265 0 008.25-3.185 12.685 12.685 0 00.444-.423 12.272 12.272 0 003.61-8.697v-.33l3.443-2.537A16.1 16.1 0 0132 15.998a15.956 15.956 0 01-5.264 11.865l-.002-.002a15.944 15.944 0 01-10.733 4.137V32a15.957 15.957 0 01-11.4-4.773A15.958 15.958 0 010 15.996a15.957 15.957 0 014.686-11.31A15.956 15.956 0 0116 0zm14.578 9.399a16.047 16.047 0 00-1.918-3.181l-12.657 9.331-6.09-3.818v4.594l6.09 3.818L30.578 9.399z"
            fill="currentColor"
        />
    </svg>
);
