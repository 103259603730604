import React, { SVGProps } from 'react';

export const VerticalOrientation: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 15 21" {...props}>
        <path d="M4.37037 19.8206H1L1 16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 19.8206H1L1 16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 19.8206H1L1 16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 1L1 1L1 4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 1L1 1L1 4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M4.37037 1L1 1L1 4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 19.8206H13.7412V16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 19.8206H13.7412V16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 19.8206H13.7412V16.4103" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 1L13.7412 1V4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 1L13.7412 1V4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M10.3708 1L13.7412 1V4.41026" stroke="currentColor" strokeLinecap="round" />
    </svg>
);
