import { useState, useCallback, useMemo } from 'react';

export interface UseModalResult {
    isModalOpen: boolean;
    closeModal: () => void;
    openModal: () => void;
}

export const useModal = (initValue = false): UseModalResult => {
    const [isModalOpen, setIsOpen] = useState(initValue);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    return useMemo(
        () => ({
            isModalOpen,
            closeModal,
            openModal,
        }),
        [closeModal, isModalOpen, openModal],
    );
};
