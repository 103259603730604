import { useCallback, useState } from 'react';

export enum Status {
    Pending = 'pending',
    Ready = 'ready',
    Error = 'error',
}

export interface Entity<T = unknown> {
    error?: string;
    status?: Status;
    data?: T;
}

export type Fetcher<T> = (...args: any[]) => Promise<T | undefined>;

export function useFetcher<T = unknown>(fetcher: Fetcher<T>): { data: Entity<T>; fetchData: Fetcher<T> } {
    const [data, setData] = useState<Entity<T>>({});

    const fetchData: Fetcher<T> = useCallback(
        async (...args: any[]) => {
            setData({
                status: Status.Pending,
            });

            try {
                const newData = await fetcher(...args);

                setData({
                    status: Status.Ready,
                    data: newData,
                });

                return newData;
            } catch (e) {
                setData({
                    status: Status.Error,
                    error: e as string,
                });
            }
        },
        [fetcher],
    );

    return {
        data,
        fetchData,
    };
}
