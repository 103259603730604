import React, { MouseEventHandler, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';

interface StyledProgressBarProps {
    interactive: boolean;
}

const StyledContainer = styled.div<StyledProgressBarProps>`
    height: 10px;
    padding: 4px 0;
    box-sizing: border-box;
    position: relative;
    cursor: ${(props) => (props.interactive ? 'pointer' : 'default')};
`;

const StyledMainLine = styled.div`
    background: rgba(255, 255, 255, 0.12);
    border-radius: 1px;
    height: 2px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
`;

const StyledPreloadLine = styled.div`
    position: absolute;
    top: 4px;
    left: 0;
    height: 2px;
    border-radius: 1px;
    background: rgba(255, 255, 255, 0.2);
`;

interface StyledProgressLineProps {
    animate: boolean;
}

const StyledProgressLine = styled.div<StyledProgressLineProps>`
    position: absolute;
    top: 4px;
    left: 0;
    height: 2px;
    background: linear-gradient(121.01deg, #016af5 18.77%, #00f2ff 81.23%);
    border-radius: 1px;

    ${(props) =>
        props.animate &&
        css`
            transition: width 0.3s linear;
        `}

    &::after {
        content: ' ';
        display: block;
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgba(0, 103, 214, 0) 0%, #2990ff 100%);
        filter: blur(8px);
    }
`;

interface ProgressBarProps {
    progress: number;
    preload?: number;
    interactive?: boolean;
    animateProgress?: boolean;
    className?: string;
    onClick?(progress: number): void;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    progress,
    preload = 0,
    interactive = false,
    animateProgress = true,
    className,
    onClick,
}) => {
    const container = useRef<HTMLDivElement>(null);
    const clickListener: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (!interactive || !container.current || !onClick) {
                return;
            }

            const rect = container.current.getBoundingClientRect();
            const position = event.clientX - rect.left;

            onClick((position / rect.width) * 100);
        },
        [interactive, onClick],
    );

    return (
        <StyledContainer
            ref={container}
            interactive={interactive}
            className={className}
            onClick={clickListener}
            data-e2e="videoPlayerProgressBar"
        >
            <StyledMainLine />
            <StyledPreloadLine style={{ width: `${preload}%` }} />
            <StyledProgressLine
                style={{ width: `${progress}%`, transition: animateProgress ? 'width 0.3s linear' : 'none' }}
                animate={false}
            />
        </StyledContainer>
    );
};
