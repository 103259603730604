import React, { useState, useCallback, useContext, useEffect, useMemo, ReactElement } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { white, surfaceLiquid02, buttonSecondary, critical } from '@salutejs/plasma-tokens-b2c';
import { mediaQuery, H3, Body1, Footnote1 } from '@salutejs/plasma-b2c';
import { spacing } from '@salutejs/plasma-core';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { trackFreeButtonClick } from 'analytics/billing';
import { useTranslation } from 'next-i18next';
import { IconsSetKey } from 'components/Icon/Icon';
import { useRouter } from 'next/router';

import { Product, ProductSuggestion } from '../../typings/product';
import { Currency } from '../../typings/currency';
import { useModal } from '../../hooks/useModal';
import { FreeRateAlertModal } from '../FreeRateAlertModal/FreeRateAlertModal';
import { InvoiceContext } from '../../store/InvoiceContext';
import { SubscriptionType } from '../../typings/subscription';
import { MultipliableProductTemplate, SubscriptionSwitchRequest } from '../../api/subscriptionSwitchRequest';
import { PriceRule, TradeProductTemplate } from '../../typings/tradeProductTemplate';
import { routesConfig } from '../../configs/routes';

import { Badge } from './Badge';
import { TariffCardButton } from './TariffCardButton';
import { TariffCardPriceBlock } from './TariffCardPriceBlock';
import { TariffCardOptions } from './TariffCardOptions';
import { Promocode } from './Promocode';
import { TradeProductTemplateInput } from './TradeProductTemplateInput';

const CARD_HEIGHT_FULL = '522px';
const CARD_HEIGHT_SHORT = '224px';

const shortInfoAnimation = keyframes`
    0% {
        min-height: ${CARD_HEIGHT_FULL};
    }

    2% {
        min-height: ${CARD_HEIGHT_FULL};
    }

    100% {
        min-height: ${CARD_HEIGHT_SHORT};
    }
`;

const CardContainer = styled.div<{ isCurrent?: boolean; isShortInfo: boolean }>`
    position: relative;
    width: 256px;
    border-radius: 20px;
    padding: ${spacing['12x']};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background 0.3s ease-in;
    margin-bottom: ${({ isShortInfo }) => (isShortInfo ? '40px' : '0')};

    ${mediaQuery('S')(css`
        background: ${surfaceLiquid02};
        width: 100%;
    `)}
    ${mediaQuery('M')(css`
        background: ${surfaceLiquid02};
        width: 100%;
    `)}
    ${mediaQuery('L')(css`
        background: ${surfaceLiquid02};
        width: 100%;
    `)}

    ${({ isCurrent }) => css`
        ${mediaQuery('XL')(css`
            &:hover {
                background: ${isCurrent ? 'none' : surfaceLiquid02};
            }
        `)}
        ${mediaQuery('XXL')(css`
            &:hover {
                background: ${isCurrent ? 'none' : surfaceLiquid02};
            }
        `)}
    `}
`;

const StyledBadge = styled(Badge)<{ isShortInfo: boolean }>`
    ${({ isShortInfo }) =>
        isShortInfo &&
        css`
            position: absolute;
            right: -1rem;
            transform: rotate(10deg);
            top: 0;
        `}
    width: max-content;
    margin-bottom: ${spacing['16x']};

    ${mediaQuery('S')(css`
        margin-bottom: ${spacing['12x']};
    `)}
    ${mediaQuery('M')(css`
        margin-bottom: ${spacing['12x']};
    `)}
    ${mediaQuery('L')(css`
        margin-bottom: ${spacing['12x']};
    `)}
`;

const StyledTitle = styled(H3)`
    margin-bottom: ${spacing['8x']};

    ${mediaQuery('S')(css`
        margin-left: ${spacing['8x']};
    `)}
    ${mediaQuery('M')(css`
        margin-left: ${spacing['8x']};
    `)}
    ${mediaQuery('L')(css`
        margin-left: ${spacing['8x']};
    `)}
`;

const StyledDescription = styled(Body1)`
    font-size: 14px;
    margin-bottom: ${spacing['10x']};
    color: ${white};
    hyphens: none;

    ${mediaQuery('S')(css`
        margin-bottom: ${spacing['4x']};
    `)}
    ${mediaQuery('M')(css`
        margin-bottom: ${spacing['4x']};
    `)}
    ${mediaQuery('L')(css`
        margin-bottom: ${spacing['4x']};
    `)}
`;

const StyledError = styled(Footnote1)`
    color: ${critical};
    margin-bottom: ${spacing['12x']};
    hyphens: none;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
    height: 100px;
    width: 100px;
    align-self: flex-start;
    margin-top: -12px;
    margin-bottom: 6px;
    padding-top: 0 !important;

    ${mediaQuery('S')(css`
        height: 70px;
        width: 70px;
    `)}
`;

const FallbackImage = styled.img`
    height: 100px;
    width: 100px;
    align-self: flex-start;
    margin-top: -12px;
    margin-bottom: 6px;
    object-fit: contain;

    ${mediaQuery('S')(css`
        height: 70px;
        width: 70px;
    `)}
`;

const CardBody = styled.div<{ isShortInfo: boolean }>`
    min-height: ${({ isShortInfo }) => (isShortInfo ? CARD_HEIGHT_SHORT : CARD_HEIGHT_FULL)};
    animation-name: ${({ isShortInfo }) => (isShortInfo ? shortInfoAnimation : '')};
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mediaQuery('S')(css`
        min-height: 0;
    `)}
    ${mediaQuery('M')(css`
        min-height: 0;
    `)}
    ${mediaQuery('L')(css`
        min-height: 0;
    `)}
`;

const CardFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const StyledHeader = styled.div`
    display: flex;
    flex-flow: column;
    margin-bottom: ${spacing['6x']};

    ${mediaQuery('S')(css`
        flex-flow: row;
        align-items: center;
    `)}
    ${mediaQuery('M')(css`
        flex-flow: row;
        align-items: center;
    `)}
    ${mediaQuery('L')(css`
        flex-flow: row;
        align-items: center;
    `)}
`;

interface TariffCardLabel {
    title: ReactElement | string;
    color: string;
    icon: IconsSetKey | null;
}

export interface TariffCardProps {
    id: string;
    title: string;
    description: string;
    label: TariffCardLabel | null;
    oldPrice: string | null;
    price?: string;
    usdPrice?: string;
    currency: Currency;
    options: Product[];
    isCurrent?: boolean;
    isShortInfo?: boolean;
    isPersonal: boolean;
    video?: string | null;
    videoFallback?: string | null;
    suggestion?: ProductSuggestion;
    isDefault: boolean;
    onSelect: (data: SubscriptionSwitchRequest) => void;
    onPromoCode?: (id: string, promoCode: string) => Promise<void>;
    isDisabled: boolean;
    isLoading: boolean;
    analyticsFrom: string;
    onOpenFeedbackModal?: () => void | undefined;
    type: SubscriptionType;
    isCurrentSubscriptionStopped: boolean;
    pricePerMinute: { price: number; usdPrice: number } | null;
    tradeProductTemplates: TradeProductTemplate[];
    isLandingPage: boolean;
}

export const TariffCard: React.FC<TariffCardProps> = ({
    id,
    title,
    description,
    price,
    usdPrice,
    oldPrice,
    isCurrent,
    options,
    label,
    video,
    isShortInfo = false,
    isPersonal,
    isDefault,
    isLoading,
    onSelect,
    onPromoCode,
    isDisabled,
    videoFallback,
    analyticsFrom,
    suggestion,
    currency,
    onOpenFeedbackModal,
    type,
    isCurrentSubscriptionStopped,
    pricePerMinute,
    tradeProductTemplates,
    isLandingPage = false,
}) => {
    const { t } = useTranslation('payment');
    const {
        modal: { openModal: openInvoiceStatusPopup },
    } = useContext(InvoiceContext);
    const router = useRouter();
    const [error, setError] = useState('');
    const [option, setOption] = useState<string | null>(null);
    const [isOptionsShown, setIsOptionsShown] = useState(false);
    const [templatePriceRule, setTemplatePriceRule] = useState<PriceRule | null>(null);
    const [productTemplateSwitchData, setProductTemplateSwitchData] = useState<MultipliableProductTemplate | null>(
        null,
    );
    const showFreeRateAlertModal = useModal();
    const openFreeRateAlertModal = showFreeRateAlertModal.openModal;
    const handleLandingPageRateClick = useCallback(() => {
        router.push(routesConfig.registration);
    }, [router]);
    const currentBadge: TariffCardLabel = useMemo(
        () => ({
            title: t('Ваш текущий'),
            color: buttonSecondary,
            icon: null,
        }),
        [t],
    );
    const badge = isCurrent ? currentBadge : label;

    useEffect(() => {
        if (suggestion?.optionId) {
            setIsOptionsShown(true);
            setOption(suggestion.optionId);
        }
    }, [suggestion]);

    const handleSelect = useCallback(() => {
        if (isLandingPage) {
            return handleLandingPageRateClick();
        }

        if (options.length > 1 && !option && !productTemplateSwitchData) {
            setError(t('Необходимо выбрать вариант'));
        } else {
            setError('');
            onSelect({
                subscription_id: id,
                product_variant_id: option,
                multipliable_product: option ? null : productTemplateSwitchData,
            });
        }
    }, [isLandingPage, options.length, option, productTemplateSwitchData, handleLandingPageRateClick, t, onSelect, id]);

    const handleOptionChange = useCallback((value: string) => {
        setOption(value);
        setTemplatePriceRule(null);
        setProductTemplateSwitchData(null);
        setError('');
    }, []);

    const handleFeedbackModalOpen = useCallback(() => {
        if (!onOpenFeedbackModal) {
            return;
        }

        onOpenFeedbackModal();
    }, [onOpenFeedbackModal]);

    const handleFreeRateClick = useCallback(() => {
        if (isLandingPage) {
            return handleLandingPageRateClick();
        }

        if (isDisabled) {
            openInvoiceStatusPopup();
        } else {
            openFreeRateAlertModal();
            trackFreeButtonClick(analyticsFrom);
        }
    }, [
        analyticsFrom,
        handleLandingPageRateClick,
        isDisabled,
        isLandingPage,
        openFreeRateAlertModal,
        openInvoiceStatusPopup,
    ]);

    const handlePromoCodeChange = useCallback((promoCode: string) => onPromoCode?.(id, promoCode), [id, onPromoCode]);

    const productTemplateSwitchHandle = useCallback(
        (data: MultipliableProductTemplate | null) => {
            const template = tradeProductTemplates.find((item) => item.id === data?.product_template_id);
            const rule = template?.price_rules
                .slice()
                .reverse()
                .find((item) => item.count <= Number(data?.multiplier));

            setTemplatePriceRule(rule ?? null);
            setProductTemplateSwitchData(data);
            setOption(null);
        },
        [tradeProductTemplates],
    );

    return (
        <>
            <CardContainer isCurrent={isCurrent} isShortInfo={isShortInfo} data-e2e="rateCard" data-e2e-type={type}>
                <CardBody isShortInfo={isShortInfo}>
                    <StyledHeader>
                        {!isShortInfo && video && (
                            <StyledVideoPlayer
                                fallback={videoFallback ? <FallbackImage src={videoFallback} /> : undefined}
                                url={video}
                                playing
                                loop
                                showFullscreen={false}
                                withControls={false}
                            />
                        )}
                        <StyledTitle data-e2e="rateTitle">{title}</StyledTitle>
                    </StyledHeader>

                    {!isShortInfo && <StyledDescription data-e2e="rateDescription">{description}</StyledDescription>}

                    {badge && (
                        <StyledBadge
                            data-e2e="rateBadge"
                            isShortInfo={isShortInfo}
                            color={badge.color}
                            text={badge.title}
                            icon={badge.icon}
                        />
                    )}

                    <TariffCardPriceBlock
                        currency={currency}
                        pricePerMinute={pricePerMinute}
                        price={price}
                        usdPrice={usdPrice}
                        options={options}
                        oldPrice={oldPrice}
                        isPersonal={isPersonal}
                        templatePriceRule={templatePriceRule}
                        templatePriceRuleCount={productTemplateSwitchData?.multiplier ?? null}
                    />

                    {isOptionsShown && (
                        <>
                            <TariffCardOptions
                                options={options}
                                currentOptionId={option}
                                onChange={handleOptionChange}
                            />

                            {tradeProductTemplates.map((template) => (
                                <TradeProductTemplateInput
                                    key={template.id}
                                    template={template}
                                    value={productTemplateSwitchData?.multiplier}
                                    onChange={productTemplateSwitchHandle}
                                />
                            ))}
                        </>
                    )}

                    {error && <StyledError>{error}</StyledError>}

                    <TariffCardButton
                        price={price}
                        isCurrent={isCurrent}
                        options={options}
                        currentOption={option}
                        isPersonal={isPersonal}
                        isDefault={isDefault}
                        isLoading={isLoading}
                        analyticsFrom={analyticsFrom}
                        showOptions={isOptionsShown}
                        onSelect={handleSelect}
                        onShowOptions={setIsOptionsShown}
                        onFreeRateClick={handleFreeRateClick}
                        openModal={handleFeedbackModalOpen}
                        isCurrentSubscriptionStopped={isCurrentSubscriptionStopped}
                    />
                </CardBody>

                {(price !== '0' || options.length > 0) && !isLandingPage && (
                    <CardFooter>
                        <Promocode onApply={handlePromoCodeChange} />
                    </CardFooter>
                )}
            </CardContainer>

            {isDefault && !isLandingPage && (
                <FreeRateAlertModal
                    open={showFreeRateAlertModal.isModalOpen}
                    onClose={showFreeRateAlertModal.closeModal}
                />
            )}
        </>
    );
};
