import React, { SVGProps } from 'react';

export const Mountains: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 66 36" {...props}>
        <path
            d="M5.3431 21.6901L19.6527 7.38046L47.2957 35.0234L52.0098 30.3094L41.0371 19.3367L46.3259 14.0479L60.6356 28.3576L65.3496 23.6435L49.8615 8.15538C47.9088 6.20276 44.743 6.20277 42.7904 8.15539L36.3231 14.6227L23.1883 1.4879C21.2356 -0.464723 18.0698 -0.464711 16.1172 1.4879L0.629059 16.9761L5.3431 21.6901Z"
            fill="currentColor"
        />
    </svg>
);
