import React, { SVGProps } from 'react';

export const PositionStanding: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 16" {...props}>
        <path
            d="M6.00146 3C6.00146 1.89543 6.8969 1 8.00146 1C9.10603 1 10.0015 1.89543 10.0015 3C10.0015 4.10457 9.10603 5 8.00146 5C6.8969 5 6.00146 4.10457 6.00146 3ZM4.67969 7.60777C4.86665 6.67292 5.68748 6 6.64085 6H8.00124H9.36163C10.315 6 11.1358 6.67292 11.3228 7.60777L11.762 9.80388C11.8858 10.4227 11.4125 11 10.7814 11H10.0012V14C10.0012 14.5523 9.55352 15 9.00124 15H7.00124C6.44895 15 6.00124 14.5523 6.00124 14V11H5.22104C4.59 11 4.1167 10.4227 4.24046 9.80388L4.67969 7.60777Z"
            fill="currentColor"
        />
    </svg>
);
