import React, { SVGProps } from 'react';

export const SceneBackgroundIcon: React.FC<SVGProps<SVGSVGElement>> = () => (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895432 20 2 20H21.3333C22.4379 20 23.3333 19.1046 23.3333 18V2C23.3333 0.895431 22.4379 0 21.3333 0H2ZM11.4407 1.56445C11.6622 1.3635 11.9974 1.38856 12.1894 1.62042L21.3898 12.7315C21.5818 12.9634 21.5578 13.3143 21.3363 13.5152C21.1148 13.7162 20.7795 13.6911 20.5875 13.4592L11.3872 2.34813C11.1952 2.11626 11.2191 1.7654 11.4407 1.56445ZM16.3981 2.3013C16.6219 2.10311 16.9568 2.13233 17.1461 2.36655L21.0386 7.18137C21.2279 7.41559 21.2 7.76613 20.9763 7.96433C20.7525 8.16252 20.4176 8.13331 20.2282 7.89908L16.3357 3.08426C16.1464 2.85004 16.1743 2.4995 16.3981 2.3013ZM2.24426 11.9309C2.46804 11.7327 2.80296 11.7619 2.99232 11.9961L6.88478 16.8109C7.07413 17.0452 7.04623 17.3957 6.82244 17.5939C6.59866 17.7921 6.26374 17.7629 6.07438 17.5286L2.18192 12.7138C1.99256 12.4796 2.02047 12.1291 2.24426 11.9309ZM2.98305 6.79932C2.78781 6.57045 2.45227 6.55057 2.2336 6.75492C2.01493 6.95927 1.99593 7.31047 2.19117 7.53934L11.0377 17.9097C11.2329 18.1386 11.5685 18.1585 11.7871 17.9541C12.0058 17.7498 12.0248 17.3986 11.8296 17.1697L2.98305 6.79932ZM7.58387 1.98292C7.38808 1.75457 7.05249 1.73558 6.83432 1.94051C6.61614 2.14545 6.598 2.49669 6.7938 2.72504L19.1789 17.1695C19.3747 17.3978 19.7103 17.4168 19.9285 17.2119C20.1466 17.007 20.1648 16.6557 19.969 16.4274L7.58387 1.98292ZM2.94183 2.31246C3.15972 2.1072 3.49534 2.12569 3.69144 2.35375L16.4304 17.1686C16.6265 17.3966 16.6089 17.7479 16.391 17.9532C16.1731 18.1584 15.8375 18.1399 15.6413 17.9119L2.90238 3.09705C2.70627 2.86899 2.72393 2.51771 2.94183 2.31246Z"
            fill="white"
        />
    </svg>
);
