import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';
import { button1 } from '@salutejs/plasma-tokens-b2c';

const StyledButton = styled.button`
    position: relative;
    display: block;
    width: auto;
    height: 48px;
    background: #0f6fd7;
    border-radius: 16px;
    padding: 0 64px;
    color: #fff;
    text-align: center;
    font-family: 'SB Sans Text', sans-serif;
    font-size: ${button1.fontSize};
    font-style: ${button1.fontStyle};
    line-height: ${button1.lineHeight};
    font-weight: ${button1.fontWeight};
    letter-spacing: ${button1.letterSpacing};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 22px 0;
    box-sizing: border-box;
    transition: all 0.15s linear;

    &:hover {
        filter: brightness(120%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
`;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const LocalButton = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => (
    <StyledButton ref={ref} {...props}>
        {children}
    </StyledButton>
));
