import React, { SVGProps } from 'react';

export const TtsVoice: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            clipRule="evenodd"
            d="M10 4a1 1 0 00-1 1v14a1 1 0 102 0V5a1 1 0 00-1-1zm4 4a1 1 0 00-1 1v6a1 1 0 102 0V9a1 1 0 00-1-1zm3 2a1 1 0 112 0v4a1 1 0 11-2 0v-4zM6 8a1 1 0 00-1 1v6a1 1 0 102 0V9a1 1 0 00-1-1z"
            fill="currentColor"
        />
    </svg>
);
