import React from 'react';
import { createPortal } from 'react-dom';
import { canUseDOM } from 'utils/canUseDom';

export const PORTAL_ROOT_ID = 'portal-root';

interface PortalProps {
    children: React.ReactNode;
}

// https://reactjs.org/docs/portals.html
export class Portal extends React.Component<PortalProps> {
    el: HTMLDivElement | null;

    portalRoot: HTMLElement | null = null;

    constructor(props: PortalProps) {
        super(props);
        this.el = canUseDOM() ? document.createElement('div') : null;
    }

    componentDidMount(): void {
        this.portalRoot = document.getElementById(PORTAL_ROOT_ID);
        this.portalRoot && this.el && this.portalRoot.appendChild(this.el);
    }

    componentWillUnmount(): void {
        this.portalRoot && this.el && this.portalRoot.removeChild(this.el);
    }

    render(): JSX.Element | null {
        return canUseDOM() && this.el ? createPortal(this.props.children, this.el) : null;
    }
}
