import { TokenRefreshSliding } from '@sberdevices/vc-contracts';

import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';
import { SocialAuthProvider, SocialAuthProviderData } from '../typings/auth';

export function getSocialAuthProviders(): Promise<SocialAuthProviderData[]> {
    return baseAxiosInstance.get<SocialAuthProviderData[]>(apiConfig.socialAuthProviders).then(({ data }) => data);
}

type SocialAuthResponse = TokenRefreshSliding & { user_created: boolean };

export function socialAuth(provider: SocialAuthProvider, code: string): Promise<SocialAuthResponse> {
    return baseAxiosInstance
        .post<SocialAuthResponse>(`${apiConfig.socialAuth}${provider}/`, { code })
        .then(({ data }) => data);
}
