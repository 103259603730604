import React, { useCallback, useEffect, useState } from 'react';

import { Modal } from '../Modal/Modal';
import { SubscriptionCurrent } from '../../typings/subscription';
import { getCurrentSubscription } from '../../api/subscription';

import { AlertBlock } from './AlertBlock';
import { FeedbackBlock } from './FeedbackBlock';
import { SuccessBlock } from './SuccessBlock';

interface PaymentSuccessModalProps {
    open: boolean;
    onClose(): void;
}

enum Step {
    Alert,
    Feedback,
    Success,
}

export const FreeRateAlertModal: React.FC<PaymentSuccessModalProps> = ({ open, onClose }) => {
    const [step, setStep] = useState<Step>(Step.Alert);
    const [currentRate, setCurrentRate] = useState<SubscriptionCurrent>();

    const onAlertConfirm = useCallback(() => setStep(Step.Feedback), []);

    const onRateSwitchSuccess = useCallback(() => setStep(Step.Success), []);

    useEffect(() => {
        if (!open) {
            return;
        }

        getCurrentSubscription().then(setCurrentRate);
    }, [open]);

    return (
        <Modal open={open} width="527px" onClose={onClose}>
            {step === Step.Alert && <AlertBlock onConfirm={onAlertConfirm} onCancel={onClose} />}
            {step === Step.Feedback && <FeedbackBlock onSuccess={onRateSwitchSuccess} />}
            {step === Step.Success && <SuccessBlock currentRate={currentRate} />}
        </Modal>
    );
};
