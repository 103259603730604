import { Locale as DateFNSLocale } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { LanguagesEnum, TranslationsLanguagesEnum } from '@sberdevices/vc-contracts';

import { Locale } from '../typings/locale';

import { useBodyEvent } from './useBodyEvent';

export const useLocale = (): DateFNSLocale => {
    const router = useRouter();

    return router.locale !== 'ru' ? enUS : ru;
};

export const useLanguage = (): 'en' | 'ru' => {
    return useLocale().code?.split('-')[0] as 'en' | 'ru';
};

export const localeUpdateEventName = 'localeUpdateEvent';

export const useLocaleUpdateEvent = (callback: (locale: Locale) => void): void => {
    const listener = useCallback((event: CustomEvent<Locale>) => callback(event.detail), [callback]);
    useBodyEvent(localeUpdateEventName, listener);
};

export const fireLocaleUpdateEvent = (locale: TranslationsLanguagesEnum): void => {
    document.body.dispatchEvent(new CustomEvent(localeUpdateEventName, { detail: locale }));
};
