import React, { useMemo } from 'react';
import { format, getDate, getMonth, setMonth } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useLocale } from 'hooks/useLocale';

import { SubscriptionCurrent } from '../../typings/subscription';
import { ModalHeader } from '../Modal/ModalHeader';

interface SuccessBlockProps {
    currentRate?: SubscriptionCurrent;
}

export const SuccessBlock: React.FC<SuccessBlockProps> = ({ currentRate }) => {
    const { t } = useTranslation('payment');
    const { t: translateDate } = useTranslation('dates');
    const locale = useLocale();
    const paymentTime = useMemo(() => {
        if (!currentRate?.expiration_date) {
            return '';
        }

        const paymentDate = new Date(currentRate.expiration_date);
        const now = new Date();

        return format(
            setMonth(paymentDate, getMonth(now) + (getDate(paymentDate) > getDate(now) ? 0 : 1)),
            translateDate('d MMMM'),
            {
                locale,
            },
        );
    }, [currentRate, locale, translateDate]);

    return (
        <ModalHeader>
            {t('Вы перешли на бесплатный тариф. Докупить минуты всегда можно в личном кабинете.')}
            {paymentTime && t('Оплаченные минуты будут доступны до {{date}}', { date: paymentTime })}
        </ModalHeader>
    );
};
