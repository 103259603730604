import React from 'react';
import NextLink from 'next/link';
import { LinkProps } from 'next/dist/client/link';

type HeaderProps = LinkProps &
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Link: React.FC<HeaderProps> = ({
    href,
    as,
    passHref,
    locale,
    prefetch,
    replace,
    scroll,
    shallow,
    children,
    ...props
}) => (
    <NextLink
        href={href}
        as={as}
        passHref={passHref}
        locale={locale}
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
    >
        <a {...props}>{children}</a>
    </NextLink>
);
