import React, { SVGProps } from 'react';

export const Alert: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 426.667 426.667" {...props}>
        <path
            d="M213.227 0C95.36 0 0 95.467 0 213.333s95.36 213.333 213.227 213.333 213.44-95.467 213.44-213.333S331.093 0 213.227 0zm.106 384c-94.293 0-170.667-76.373-170.667-170.667S119.04 42.667 213.333 42.667 384 119.04 384 213.333 307.627 384 213.333 384z"
            fill="currentColor"
        />
        <path d="M192 277.333h42.667V320H192zM192 106.667h42.667v128H192z" fill="currentColor" />
    </svg>
);
