import React, { SVGProps } from 'react';

export const InfoCircle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 16 16" fill="transparent" {...props}>
        <path
            d="M8.863 6.151L8.854 10.883L9.457 11V11.378H7V11L7.603 10.883L7.612 6.772L7 6.655V6.295L8.773 6.025L8.863 6.151ZM7.468 4.666C7.468 4.474 7.531 4.315 7.657 4.189C7.789 4.063 7.969 4 8.197 4C8.431 4 8.611 4.063 8.737 4.189C8.869 4.315 8.935 4.474 8.935 4.666C8.935 4.864 8.869 5.026 8.737 5.152C8.611 5.278 8.431 5.341 8.197 5.341C7.969 5.341 7.789 5.278 7.657 5.152C7.531 5.026 7.468 4.864 7.468 4.666Z"
            fill="currentColor"
        />
        <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    </svg>
);
