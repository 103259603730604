import React, { SVGProps } from 'react';

export const HorizontalOrientation: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 21 15" {...props}>
        <path d="M1 4.37037V1H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 4.37037V1H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 4.37037V1H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 4.37037V1H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 4.37037V1H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 4.37037V1H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 10.6296V14H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 10.6296V14H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M1 10.6296V14H4.41026" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 10.6296V14H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 10.6296V14H16.5897" stroke="currentColor" strokeLinecap="round" />
        <path d="M20 10.6296V14H16.5897" stroke="currentColor" strokeLinecap="round" />
    </svg>
);
