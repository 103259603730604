import React, { SVGProps } from 'react';

export const RightArrow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27405 6.31225C9.65388 5.91132 10.2868 5.89421 10.6877 6.27404L15.6877 11.0109C15.8805 11.1935 15.9927 11.4452 15.9997 11.7105C16.0066 11.9759 15.9078 12.2331 15.725 12.4256L10.725 17.6887C10.3446 18.0892 9.71166 18.1054 9.31125 17.725C8.91085 17.3446 8.89462 16.7117 9.275 16.3112L13.5853 11.7741L9.31226 7.72595C8.91133 7.34612 8.89422 6.71319 9.27405 6.31225Z"
            fill="currentColor"
        />
    </svg>
);
