export interface FeedbackFormData {
    name: string | null;
    email: string | null;
    phone: string | null;
    company_name: string | null;
    position: string | null;
    comment: string | null;
    how_know_about_us: string | null;
}

export const emailValidation = (email: string | null): boolean => {
    const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return !email ? false : !!email.match(regex);
};

export const phoneValidation = (phone: string | null): boolean => {
    const regex = /^\+?\d{11,18}$/;

    return !phone || !!phone.match(regex);
};

export const howKnowAboutUsValidation = (description: string | null): boolean => !!description;

export const submitValidation = (email: string | null, phone: string | null, description: string | null): boolean => {
    return emailValidation(email) && phoneValidation(phone) && howKnowAboutUsValidation(description);
};
