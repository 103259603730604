import React, { SVGProps } from 'react';

export const Male: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 12 14" {...props}>
        <path
            d="M3.62451 2.5C3.62451 1.11929 4.71627 0 6.06302 0C7.40977 0 8.50152 1.11929 8.50152 2.5C8.50152 3.88071 7.40977 5 6.06302 5C4.71627 5 3.62451 3.88071 3.62451 2.5Z"
            fill="currentColor"
        />
        <path
            d="M3.73266 13.2853C4.10332 13.7383 4.65052 14 5.22727 14H6.62032C7.18176 14 7.71598 13.752 8.08637 13.3194L11.5114 9.31944C12.6182 8.02696 11.7229 6 10.0454 6H6.19411H1.95455C0.297631 6 -0.604902 7.98386 0.45993 9.28532L3.73266 13.2853Z"
            fill="currentColor"
        />
    </svg>
);
