import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useModal } from 'hooks/useModal';
import { gridBreakpoints } from '@salutejs/plasma-b2c';

import { useWindowResize } from '../hooks/useWindowResize';

export enum Device {
    Desktop,
    Mobile,
}

export interface LayoutContextModel {
    tariffsModal: {
        options: TariffModalOptions;
        isOpen: boolean;
        open: (options?: TariffModalOptions) => void;
        close: () => void;
    };
    device: Device | null;
    setDevice: (device: Device) => void;
}

interface TariffModalOptions {
    isCompare?: boolean;
    suggestion?: { tariffId: string; optionId?: string };
}

export const initialContext: LayoutContextModel = {
    tariffsModal: {
        options: { isCompare: false },
        isOpen: false,
        open: () => {},
        close: () => {},
    },
    device: null,
    setDevice: () => {},
};

export const LayoutContext = React.createContext<LayoutContextModel>(initialContext);

export const LayoutContextProvider: React.FC = ({ children }) => {
    const { isModalOpen: isTariffsModalOpen, closeModal: closeTariffsModal, openModal: openTariffsModal } = useModal();
    const [tariffsModalOptions, setTariffsModalsOptions] = useState<TariffModalOptions>({ isCompare: false });
    const [device, setDevice] = useState<Device | null>(null);
    const router = useRouter();

    const handleOpenTariffsModal = useCallback(
        (options?: TariffModalOptions) => {
            setTariffsModalsOptions(options || initialContext.tariffsModal.options);
            openTariffsModal();
        },
        [openTariffsModal],
    );

    const resizeHandle = useCallback(() => {
        const currentDevice: Device = window.innerWidth >= gridBreakpoints.XL ? Device.Desktop : Device.Mobile;

        if (device === currentDevice) {
            return;
        }

        setDevice(currentDevice);
    }, [device, setDevice]);

    useEffect(() => {
        resizeHandle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useWindowResize(resizeHandle);

    const layoutContext: LayoutContextModel = useMemo(
        () => ({
            tariffsModal: {
                options: tariffsModalOptions,
                isOpen: isTariffsModalOpen,
                close: closeTariffsModal,
                open: handleOpenTariffsModal,
            },
            device,
            setDevice,
        }),
        [tariffsModalOptions, isTariffsModalOpen, closeTariffsModal, handleOpenTariffsModal, device, setDevice],
    );

    useEffect(() => {
        closeTariffsModal();
    }, [router, closeTariffsModal]);

    return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};
