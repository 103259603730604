import { baseAxiosInstance } from '../utils/interceptor';
import { apiConfig } from '../configs/api';
import { Subscription, SubscriptionCurrent } from '../typings/subscription';
import { PaginatedData } from '../typings/paginatedData';

import { SubscriptionSwitchRequest } from './subscriptionSwitchRequest';

export function getSubscriptionsRaw(): Promise<Subscription[] | undefined> {
    return baseAxiosInstance.get<PaginatedData<Subscription>>(apiConfig.subscriptions).then(({ data }) => data.results);
}

export function getCurrentSubscription(): Promise<SubscriptionCurrent> {
    return baseAxiosInstance.get<SubscriptionCurrent>(apiConfig.subscriptionsCurrent).then(({ data }) => data);
}

export function switchSubscription(data: SubscriptionSwitchRequest): Promise<SubscriptionCurrent> {
    return baseAxiosInstance.post<SubscriptionCurrent>(apiConfig.switchSubscription, data).then(({ data }) => data);
}

export async function switchToFreeRate(): Promise<SubscriptionCurrent> {
    const subscriptions = await getSubscriptionsRaw();
    const defaultSubscription = (subscriptions ?? []).find((item) => item.is_default);

    if (!defaultSubscription) {
        throw new Error('Default subscription not found');
    }

    return switchSubscription({
        subscription_id: defaultSubscription.id,
        product_variant_id: null,
        multipliable_product: null,
    });
}

export function cancelCurrentSubscription(): Promise<SubscriptionCurrent> {
    return baseAxiosInstance.patch<SubscriptionCurrent>(apiConfig.cancelCurrentSubscription).then(({ data }) => data);
}
