export const getQueryParams = (): Record<string, string> => {
    const searchParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
    const res: Record<string, string> = {};

    searchParams.forEach((value, key) => {
        res[key] = value;
    });

    return res;
};

export const useQuery = getQueryParams;
