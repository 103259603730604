import React, { SVGProps } from 'react';

export const Apple: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g clipPath="url(#clip0_19454_14971)">
            <path
                d="M17.3397 20.2C16.3098 21.252 15.1735 21.088 14.0903 20.592C12.9388 20.086 11.886 20.054 10.6699 20.592C9.1554 21.28 8.3516 21.08 7.43949 20.2C2.28984 14.62 3.04994 6.12 8.90267 5.8C10.3221 5.88 11.316 6.626 12.1521 6.688C13.3948 6.422 14.5844 5.66001 15.9145 5.76001C17.5126 5.896 18.7079 6.56 19.506 7.754C16.2186 9.83399 16.9977 14.394 20.0172 15.674C19.4129 17.344 18.6376 18.994 17.3378 20.214L17.3397 20.2ZM12.0381 5.74001C11.8841 3.26001 13.7939 1.22002 15.9906 1.02002C16.2927 3.88001 13.5202 6.02 12.0381 5.74001Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_19454_14971">
                <rect width="16" height="20" fill="white" transform="translate(4 1)" />
            </clipPath>
        </defs>
    </svg>
);
