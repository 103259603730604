import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { buttonGray, buttonPrimaryBrand } from 'const/tokens';
import { fontWeights } from '@salutejs/plasma-tokens-b2c';
import { MediumButtonCss } from 'components/Button/AdaptiveButton';
import { Caption } from '@salutejs/plasma-b2c';
import { useTranslation } from 'react-i18next';

const PromocodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PromocodeButton = styled.button`
    color: ${buttonPrimaryBrand};
    outline: none;
    border: none;
    background: transparent;
    font-size: 16px;
    font-weight: ${fontWeights.Semibold};
    line-height: 22px;
    cursor: pointer;
    margin-top: 24px;
`;

const PromocodeInput = styled.input<{ isError: boolean }>`
    ${MediumButtonCss};
    outline: none;
    cursor: text;
    background-color: ${({ isError }) => (isError ? 'rgba(255, 77, 94, 0.12)' : buttonGray)};
    width: 100%;
    margin-top: 8px;
    border: ${({ isError }) => (isError ? '1px solid rgba(255, 77, 94, 1)' : '1px solid transparent')};

    &:hover {
        filter: none;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ErrorLabel = styled(Caption)`
    margin-top: 3px;
    color: rgba(255, 77, 94, 1);
`;

interface PromocodeProps {
    onApply(value: string): Promise<void> | undefined;
}

export const Promocode: React.FC<PromocodeProps> = ({ onApply }) => {
    const { t } = useTranslation('tariffCard');
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>('');
    const [isPromoCodeEntered, setIsPromoCodeEntered] = useState(false);
    const [error, setError] = useState<string>();

    useLayoutEffect(() => inputRef.current?.focus(), [isPromoCodeEntered]);

    const handleClick = useCallback(async () => {
        if (isPromoCodeEntered) {
            try {
                await onApply(value);
            } catch {
                setError(t('promocodeNotFound'));
            }
        } else {
            setIsPromoCodeEntered(true);
        }
    }, [isPromoCodeEntered, onApply, value, t]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setError(undefined);
        setValue(e.target.value.toUpperCase());
    }, []);

    return (
        <PromocodeContainer>
            {isPromoCodeEntered && (
                <InputContainer>
                    <PromocodeInput
                        ref={inputRef}
                        placeholder={t('promocode')}
                        value={value}
                        onChange={handleChange}
                        isError={!!error}
                        data-e2e="ratesPromoCodeInput"
                    />
                    {!!error && <ErrorLabel data-e2e="ratesPromoCodeError">{error}</ErrorLabel>}
                </InputContainer>
            )}
            <PromocodeButton onClick={handleClick} data-e2e="ratesPromoCodeButton" data-e2e-open={isPromoCodeEntered}>
                {isPromoCodeEntered ? t('apply') : t('hasPromocode')}
            </PromocodeButton>
        </PromocodeContainer>
    );
};
