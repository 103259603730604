import React, { SVGProps } from 'react';

export const BillingHistory: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 18 18" {...props}>
        <g clipPath="url(#clip0_23332_22021)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.10734C0 5.96957 0 4.90069 0.429162 4.08417C0.806663 3.36594 1.40902 2.782 2.14991 2.41604C2.99219 2 4.09479 2 6.3 2H11.7C13.9052 2 15.0078 2 15.8501 2.41604C16.591 2.782 17.1933 3.36594 17.5708 4.08417C18 4.90069 18 5.96957 18 8.10734V10.0704C18 12.2082 18 13.2771 17.5708 14.0936C17.1933 14.8118 16.591 15.3958 15.8501 15.7617C15.0078 16.1778 13.9052 16.1778 11.7 16.1778H11.7L4.5 16.1643C3.4183 16.1355 2.7237 16.0451 2.14991 15.7617C1.40902 15.3958 0.806663 14.8118 0.429162 14.0936C0 13.2771 0 12.2082 0 10.0704V8.10734ZM15 5H3V7H15V5ZM3 8H13V10H3V8ZM9 11H3V13H9V11Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8.10734C0 5.96957 0 4.90069 0.429162 4.08417C0.806663 3.36594 1.40902 2.782 2.14991 2.41604C2.99219 2 4.09479 2 6.3 2H11.7C13.9052 2 15.0078 2 15.8501 2.41604C16.591 2.782 17.1933 3.36594 17.5708 4.08417C18 4.90069 18 5.96957 18 8.10734V10.0704C18 12.2082 18 13.2771 17.5708 14.0936C17.1933 14.8118 16.591 15.3958 15.8501 15.7617C15.0078 16.1778 13.9052 16.1778 11.7 16.1778H11.7L4.5 16.1643C3.4183 16.1355 2.7237 16.0451 2.14991 15.7617C1.40902 15.3958 0.806663 14.8118 0.429162 14.0936C0 13.2771 0 12.2082 0 10.0704V8.10734ZM15 5H3V7H15V5ZM3 8H13V10H3V8ZM9 11H3V13H9V11Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_23332_22021">
                <rect width="18" height="18" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
);
